// import node module libraries
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Dropdown, ListGroup } from "react-bootstrap";

// simple bar scrolling used for notification item scrolling
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// import custom components
import DotBadge from "components/elements/bootstrap/DotBadge";
// import DarkLightMode from "layouts/DarkLightMode";
import GKTippy from "components/elements/tooltips/GKTippy";
import { Avatar } from "components/elements/bootstrap/Avatar";
import { NavbarLinks } from "routes/marketing/NavbarDefault";
import useAuth from "hooks/useAuth";
import RoleBasedGuard from "guards/RoleGuard";
import { PATH_DASHBOARD } from "routes/Paths";

// import media files
// import AvatarDefault from "assets/images/avatar/default_avatar.png";

// import data files
// import NotificationList from 'data/Notification';

const QuickMenu = () => {
  const navigate = useNavigate();
  const _user = localStorage.getItem("user");
  const user = _user ? JSON.parse(_user) : {};

  const { isAuthenticated } = useAuth();

  // const navLinks = getNavLinks(user);

  const notifications = [];

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const Notifications = () => {
    return (
      <SimpleBar style={{ maxHeight: "300px" }}>
        <ListGroup variant="flush">
          {notifications.map(function (item, index) {
            return (
              <ListGroup.Item
                className={index === 0 ? "bg-light" : ""}
                key={index}
              >
                <Row>
                  <Col>
                    <Link className="text-body" to="#">
                      <div className="d-flex">
                        {/* <Image
													src={item.image}
													alt=""
													className="avatar-md rounded-circle"
												/> */}
                        <Avatar
                          size="md"
                          className="rounded-circle"
                          type={item?.image ? "image" : "initial"}
                          src={item?.image}
                          //   status={item?.status.toLowerCase()}
                          alt={item?.name}
                          name={item?.name}
                        />
                        <div className="ms-3">
                          <h5 className="fw-bold mb-1">{item.sender}</h5>
                          <p className="mb-3">{item.message}</p>
                          <span className="fs-6 text-muted">
                            <span>
                              <span className="fe fe-thumbs-up text-success me-1"></span>
                              {item.date}
                            </span>
                            <span className="ms-1">{item.time}</span>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col xs="auto" className="text-center me-2">
                    <GKTippy content="Mark as unread">
                      <Link to="#">
                        <DotBadge bg="secondary"></DotBadge>
                      </Link>
                    </GKTippy>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </SimpleBar>
    );
  };

  const handleLogout = () => {
    localStorage.setItem("isAuthenticated", false);
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  const handleProfile = () => {
    if (user?.role?.name === "instructor") {
      navigate(PATH_DASHBOARD.instructor.profile)
    } else if(user?.role?.name === "user") {
      navigate(PATH_DASHBOARD.student.profile)
    }
  }

  return (
    <Fragment>
      {/* <DarkLightMode /> */}
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-2 d-flex nav-top-wrap"
      >
        <Dropdown as="li">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted"
            id="dropdownNotification"
          >
            <i className="fe fe-bell"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-3 py-0"
            aria-labelledby="dropdownNotification"
            align="end"
          >
            <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
              <span className="h4 mb-0">Notifications</span>
              <Link to="# " className="text-muted">
                <span className="align-middle">
                  <i className="fe fe-settings me-1"></i>
                </span>
              </Link>
            </div>
            <Notifications />
            <div className="border-top px-3 pt-3 pb-3">
              <Link
                to="/authentication/notifications"
                className="text-link fw-semi-bold"
              >
                See all Notifications
              </Link>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as="li" className="ms-1">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              {/* <Image
                alt="avatar"
                src={AvatarDefault}
                className="rounded-circle"
              /> */}
              <Avatar
                size="md"
                className="rounded-circle"
                type={user?.image ? "image" : "initial"}
                src={user?.image}
                // status={user?.status.toLowerCase()}
                alt={user?.name}
                name={`${user?.firstName} ${user?.lastName}`}
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownUser"
            align="end"
          >
            <Dropdown.Item className="mt-3">
              <div className="d-flex">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <Avatar
                    size="md"
                    className="rounded-circle"
                    type={user?.image ? "image" : "initial"}
                    src={user?.image}
                    // status={user?.status.toLowerCase()}
                    alt={user?.name}
                    name={`${user?.firstName} ${user?.lastName}`}
                  />
                </div>
                <div className="ms-3 lh-1">
                  <h5 className="mb-1">{`${user?.firstName} ${user?.lastName}`}</h5>
                  <p className="mb-0 text-muted">{user?.email}</p>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />

            {user?.role?.name !== "administrator" &&
              <Dropdown.Item onClick={handleProfile} eventKey="2">
                <i className="fe fe-user me-2"></i> Profile
              </Dropdown.Item>
            }

            {isAuthenticated &&
              <>
                {NavbarLinks.map((item, index) => {
                  return (
                    <RoleBasedGuard key={index} roles={item.roles}>
                      <Dropdown.Item onClick={() => navigate(item.link)}>
                        <i className="fe fe-home me-2"></i> {item.menuitem}
                      </Dropdown.Item>
                    </RoleBasedGuard>

                  )
                })}
              </>
            }

            {/* <Dropdown.Item>
              <i className="fe fe-settings me-2"></i> Settings
            </Dropdown.Item>
            <Dropdown.Divider /> */}
            <Dropdown.Item className="mb-3" onClick={handleLogout}>
              <i className="fe fe-power me-2"></i> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    </Fragment>
  );
};

export default QuickMenu;
