// import node module libraries
import { Fragment } from "react";
import PropTypes from "prop-types";

import { Col, Row, ListGroup } from "react-bootstrap";
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/Paths";
import { isAdmin } from "helper/utils";

// ----------------------------------------------------------------------

DescriptionTab.propTypes = {
  course: PropTypes.object,
};

// ----------------------------------------------------------------------

export default function DescriptionTab({ course, isCoursePaid, user }) {
  const requirements = course?.requirements || [];

  const {isAuthenticated}= useAuth();

  const halfIndex = Math.ceil(requirements.length / 2);
  const firstCols = requirements.slice(0, halfIndex);
  const secondCols = requirements.slice(halfIndex);

  const canStart = isAdmin(user?.role?.name);
  const meetDetails = course?.virtualLink2 ? JSON.parse(course?.virtualLink2): null;

  console.log("course", meetDetails)

  return (
    <Fragment>
      <div className="mb-4">
        <h3 className="mb-2">Course Descriptions</h3>
        <p>{course?.description}</p>
      </div>
      <h4 className="mb-3">Course Requirements</h4>
      <Row className="mb-3">
        <Col lg={6} md={6} sm={12}>
          <ListGroup bsPrefix="list-unstyled" variant="flush">
            {firstCols.map((item, index) => (
              <ListGroup.Item key={index} bsPrefix=" " className="d-flex align-items-center mb-2">
                <i className="far fa-check-circle text-success me-2 mt-2"></i>
                <span>{item}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <ListGroup bsPrefix="list-unstyled" variant="flush">
            {secondCols.map((item, index) => (
              <ListGroup.Item key={index} bsPrefix=" " className="d-flex align-items-center mb-2">
                <i className="far fa-check-circle text-success me-2 mt-2"></i>
                <span>{item}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {((isAuthenticated && isCoursePaid && course?.virtualLink) || (isAuthenticated && canStart)  === "True") &&
        <div>
          <h4>Virtual Link</h4>
          {/* <Link to={course?.virtualLink} target="_blank">{course?.virtualLink}</Link> */}
          <Link 
            to={canStart ? meetDetails?.start_url : PATH_DASHBOARD.meeting.join(course?.guid)} 
            className="btn btn-primary" 
            target="_blank"
          >
            {canStart ? "Start Meeting" : "Join Meeting"}
          </Link>
        </div>
      }
      {/* <p>
				Maecenas viverra condimentum nulla molestie condimentum. Nunc ex libero,
				feugiat quis lectus vel, ornare euismod ligula. Aenean sit amet arcu
				nulla.
			</p>
			<p>
				Duis facilisis ex a urna blandit ultricies. Nullam sagittis ligula non
				eros semper, nec mattis odio ullamcorper. Phasellus feugiat sit amet leo
				eget consectetur.
			</p> */}
    </Fragment>
  );
}
