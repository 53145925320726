// import node module libraries
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Col, Row, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { PATH_ADMIN } from "routes/Paths";
import { ADMIN_CREATE_USER } from "helper/graphql/mutations";

// ----------------------------------------------------------------------

const InstructorForm = () => {
    const navigate = useNavigate();

  const [formData, setFormData] = useState({
    country: null,
    dateOfBirth: null,
    email: null,
    firstName: null,
    gender: null,
    isAdmin: false,
    isConsentGiven: true,
    isHealthcareWorker: false,
    isOrgAdmin: false,
    isPatient: false,
    isPrivacyPolicyGiven: true,
    isFirstTimeLogin: false,
    lastName: null,
    password: null,
    phoneNumber: null,
    state: null,
    roleName: "instructor",
  });

  const [onCreateInstructor, { loading: isLoading }] =
    useMutation(ADMIN_CREATE_USER);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    onCreateInstructor({
        variables: formData,
        onCompleted: (data) => {
            navigate(PATH_ADMIN.admin.instructors)
        },
        onError: (e) => console.log(e)
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            required
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            required
          />
        </Col>
        <Col lg={12} md={12} className="mb-3">
          {/* email */}
          <Form.Label>Email </Form.Label>
          <Form.Control
            type="email"
            id="email"
            name="email"
            placeholder="Email address here"
            onChange={handleChange}
            required
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control type="date" id="dob" name="dateOfBirth" placeholder="" onChange={handleChange} required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="text" id="phoneNumber" name="phoneNumber" placeholder="" onChange={handleChange} required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Gender</Form.Label>
          <Form.Select id="gender" name="gender" onChange={handleChange} required>
            <option>Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Select>
        </Col>

        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control type="text" id="country" name="country" onChange={handleChange} placeholder="Kenya" required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" id="state" name="state" onChange={handleChange} placeholder="Nairobi" required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          {/* Password */}
          <Form.Label>Password </Form.Label>
          <Form.Control
            type="password"
            id="pass"
            name="password"
            placeholder="**************"
            onChange={handleChange}
            required
          />
        </Col>

        <Col lg={12} md={12} className="mb-0 d-grid gap-2">
          {/* Button */}
          <Button variant="dark-primary" type="submit" disabled={isLoading}>
            {isLoading ? "Loading…" : "Add Instructor"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InstructorForm;
