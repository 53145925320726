// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container, Tab } from "react-bootstrap";

import { useQuery } from "@apollo/client";

// import custom components
import FormSelect from "components/elements/form-select/FormSelect";
import GridListViewButton from "components/elements/miscellaneous/GridListViewButton";
import PageHeading from "components/marketing/common/page-headings/PageHeading";

// import sub components
import FilterOptions from "./FilterOptions";
import CourseGridView from "./CourseGridView";
import CourseListView from "./CourseListView";
import { GET_COURSES } from "helper/graphql/queries";
import { getTotalRating } from "helper/utils";

// ----------------------------------------------------------------------

const CourseFilterPage = () => {
  const [courses, setCourses] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const [filters, setFilters] = useState({
    difficultyLevel: [],
    tags: [],
    ratings: []
  })

  const sortByOptions = [
    { value: "newest", label: "Newest" },
    { value: "free", label: "Free" },
    { value: "most-popular", label: "Most Popular" },
    { value: "highest-rated", label: "Highest Rated" },
  ];

  useQuery(GET_COURSES, {
    variables: { isActive: true },
    onCompleted: (data) => {
      setCourses(data?.allCourses?.edges?.map((item) => item?.node));
    },
  });

  useEffect(() => {
    if (courses?.length > 0 && !isUpdated) {
      let _coursesUpdated = courses.map((course) => {
        const _reviews = course?.coursereviewsSet?.edges?.map((item) => item.node);
        const totalRating = _reviews?.length !== 0 ? getTotalRating(_reviews) : 0;
        return {
          ...course,
          rating: totalRating
        }
      });

      console.log('courses-edited', _coursesUpdated);
      setCourses(_coursesUpdated);
      setIsUpdated(true);
    }
  }, [courses, isUpdated])

  const handleFilterChange = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  }

  console.log("filters", filters)
  // console.log("courses", courses)

  const filteredData = courses.filter(item => {
    if (filters.difficultyLevel.length > 0 && !filters.difficultyLevel.includes(item.dificultyLevel)) {
      return false;
    }
    if (filters.tags.length > 0 && !filters.tags.some(filter => item?.tags?.includes(filter))) {
      return false;
    }
    if (filters.ratings.length > 0 && !filters.ratings.includes(parseFloat(item?.rating))) {
      return false;
    }
    // Add more filter conditions as needed
    return true;
  });


  return (
    <Fragment>
      {/* Page header */}
      <PageHeading pagetitle="Browse Courses" />

      {/* Content */}
      <section className="py-6">
        <Container>
          <Tab.Container defaultActiveKey="grid">
            <Row>
              <Col lg={12} md={12} sm={12} className="mb-4">
                <Row className="d-lg-flex justify-content-between align-items-center">
                  <Col md={6} lg={8} xl={9}>
                    {/* <h4 className="mb-3 mb-lg-0">
                      Displaying 9 out of 68 courses
                    </h4> */}
                  </Col>
                  <Col md={6} lg={4} xl={3} className="d-inline-flex">
                    <div className="me-2">
                      <GridListViewButton keyGrid="grid" keyList="list" />
                    </div>
                    <FormSelect options={sortByOptions} placeholder="Sort by" />
                  </Col>
                </Row>
              </Col>
              <Col xl={3} lg={3} md={4} sm={12} className="mb-4 mb-lg-0">
                <FilterOptions onFilterChange={handleFilterChange} />
              </Col>
              {/* Tab content */}
              <Col xl={9} lg={9} md={8} sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="grid" className="pb-4 px-0">
                    <CourseGridView courses={filteredData} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="list" className="pb-4 px-0 react-code">
                    <CourseListView courses={filteredData} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </Fragment>
  );
};

export default CourseFilterPage;
