// import node module libraries
import React, { Fragment} from 'react';
import { Outlet } from 'react-router-dom';

// import layouts
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import Footer from 'layouts/marketing/footers/Footer';
import useAuth from 'hooks/useAuth';

const DefaultLayout = (props) => {
	const {isAuthenticated} = useAuth();
	// const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';


	return (
		<Fragment>
			<NavbarDefault login={isAuthenticated} />
			<main>
				{props.children}
				<Outlet />
			</main>
			<Footer />
		</Fragment>
	);
};

export default DefaultLayout;
