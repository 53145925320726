// import node module libraries
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { gql, useMutation, useLazyQuery } from "@apollo/client";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormProvider from "components/general/hook-form/FormProvider";

import { Col, Row, Card, Form, Button, Image, Alert, InputGroup } from "react-bootstrap";

// import media files
import Logo from "assets/images/brand/logo/logo.svg";
import useAuth from "hooks/useAuth";
import { PATH_PAGE } from "routes/Paths";
import { getUserGUID } from "utils/jwt";
// import { PATH_PAGE } from "routes/Paths";

// ----------------------------------------------------------------------

const LOGIN_USER = gql`
  mutation LoginUser($password: String!, $username: String!) {
    loginUser(password: $password, username: $username) {
      token
    }
  }
`;

const GET_USER_DETAILS = gql`
  query AllUsers($guid: UUID) {
    allUsers(guid: $guid) {
      edges {
        node {
          email
          phoneNumber
          firstName
          gender
          guid
          id
          lastName
          role {
            id
            name
          }
          city
          country
          activeOrg {
            id
            guid
            name
            logo
          }
          isFirstTimeLogin
          isHealthcareWorker
          isOrgAdmin
          isAdmin
          isPatient
          isStaff
          isSuperuser
          lastLogin
          otherName
          state
        }
      }
    }
  }
`;


// ----------------------------------------------------------------------
const SignIn = () => {
  const navigate = useNavigate();

  const { login } = useAuth();

  const [loginUser, { loading: loginLoad }] = useMutation(LOGIN_USER);
  const [getUserInfo, { loading: userLoad }] = useLazyQuery(GET_USER_DETAILS);

  const [showPass, setShowPass] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    username: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    // reset,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onLogin = (payload) => {
    loginUser({
      variables: payload,
      onCompleted: (data) => {
        const _token = data.loginUser.token;
        login(_token)

        // localStorage.setItem("isAuthenticated", true);
        // localStorage.setItem("accessToken", _token);

        // navigate(PATH_PAGE.root);

        getUserInfo({
          variables: { guid: getUserGUID(_token) },
          onCompleted: (data) => {
            localStorage.setItem("user", JSON.stringify(data?.allUsers?.edges[0]?.node || ''));
            navigate(PATH_PAGE.root);
          },
        });
      },
      onError: (err) => {
        setError("afterSubmit", { ...err, message: err.message });
      },
    });
  };

  const showPassHandler = () => setShowPass(prev => !prev)

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={6} md={10} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Link to="/">
                  <Image src={Logo} className="mb-4" height={40} alt="" />
                </Link>
                <h1 className="mb-1 fw-bold">Sign in</h1>
                <span>
                  Don’t have an account?{" "}
                  <Link to="/authentication/sign-up" className="ms-1">
                    Sign up
                  </Link>
                </span>
              </div>
              {!!errors.afterSubmit && (
                <div className="mb-4">
                  <Alert variant="danger">{errors.afterSubmit.message}</Alert>
                </div>
              )}

              {/* Form */}
              <FormProvider methods={methods} onSubmit={handleSubmit(onLogin)}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Username or email */}
                    <Form.Label>Username or email </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      {...register("username")}
                      placeholder="Email address here"
                      required
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>Password </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        {...register("password")}
                        id="password"
                        placeholder="**************"
                        required
                      />
                        <InputGroup.Text>
                          <i onClick={showPassHandler}  class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </InputGroup.Text>
                    </InputGroup>

                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <div className="d-md-flex justify-content-between align-items-center">
                      <Form.Group
                        className="mb-3 mb-md-0"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check type="checkbox" label="Remember me" />
                      </Form.Group>
                      <Link to="/authentication/forget-password">
                        Forgot your password?
                      </Link>
                    </div>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <Button
                      variant="dark-primary"
                      type="submit"
                      disabled={loginLoad || userLoad}
                    >
                      {loginLoad ? "Loading…" : "Sign In"}
                    </Button>
                  </Col>
                </Row>
              </FormProvider>
              {/* <hr className="my-4" /> */}
              {/* <div className="mt-4 text-center">
								
								<Link
									to="#"
									className="btn-social btn-social-outline btn-facebook"
								>
									<i className="fab fa-facebook"></i>
								</Link>{' '}
								
								<Link
									to="#"
									className="btn-social btn-social-outline btn-twitter"
								>
									<i className="fab fa-twitter"></i>
								</Link>{' '}
								
								<Link
									to="#"
									className="btn-social btn-social-outline btn-linkedin"
								>
									<i className="fab fa-linkedin"></i>
								</Link>{' '}
								
								<Link
									to="#"
									className="btn-social btn-social-outline btn-github"
								>
									<i className="fab fa-github"></i>
								</Link>
							</div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignIn;
