// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';

// import custom components
// import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';
import UsersListItems from './UserListItems';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from 'helper/graphql/queries';
import { Link } from 'react-router-dom';
import { PATH_ADMIN } from 'routes/Paths';

// import sub components
// import StudentsGridCard from './StudentsGridCard';
// import StudentsListItems from './StudentsListItems';

const Users = () => {
	const [allUsers, setUsers] = useState([]);

	useQuery(
		GET_ALL_USERS,
		{
			onCompleted: (data) => {
				const _users = data?.allUsers?.edges?.map(item => item.node);
				setUsers(_users);
			}
		}
	);


	return (
		<Fragment>
			{/* <Tab.Container defaultActiveKey="grid"> */}
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">
								Users <span className="fs-5 text-muted">({allUsers?.length})</span>
							</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">User</Breadcrumb.Item>
								<Breadcrumb.Item active>Students</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div className='d-flex'>
							<Link to={PATH_ADMIN.admin.addUsers} className="btn btn-primary mx-3">
								Add User +
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			<Card className="mb-5 ">
				<Card.Body className="p-0">
					{/* students in list view */}
					<UsersListItems users={allUsers} />
					{/* end of students in list view */}
				</Card.Body>
			</Card>
			{/* </Tab.Container> */}
		</Fragment>
	);
};
export default Users;
