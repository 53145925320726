// ** Import from react dom
import { Route, Routes, Navigate } from "react-router-dom";

// ** Import core SCSS styles
import "assets/scss/theme.scss";

// ** Import Layouts
import DashboardIndex from "layouts/dashboard/DashboardIndex";
import AuthLayout from "layouts/dashboard/AuthLayout";
import HelpCenterLayout from "layouts/marketing/HelpCenterLayout"; // ( added in v1.3.0 )
import DashboardIndexCompact from "layouts/dashboard/DashboardIndexCompact"; // ( added in v1.3.0 )
import DashboardIndexTop from "layouts/dashboard/DashboardIndexTop"; // ( added in v1.3.0 )
import JobListingLayout from "layouts/marketing/JobListingLayout"; // ( added in v2.0.0 )
import MailLayout from "./dashboard/MailLayout"; // ( added in v2.0.0 )
import ChatLayout from "./dashboard/ChatLayout"; // ( added in v2.0.0 )
import TaskKanbanLayout from "./dashboard/TaskKanbanLayout"; // ( added in v2.0.0 )
import HelpCenterTransparentLayout from "layouts/marketing/HelpCenterTransparentLayout"; // ( added in v2.0.0 )
import EcommerceLayout from "./dashboard/EcommerceLayout"; // ( added in v2.2.0 )

// ** Import Dahbaord Menu Pages
import Overview from "components/dashboard/overview/Overview";
import Analytics from "components/dashboard/analytics/Analytics";

// ** Import Courses Pages
import AllCourses from "components/dashboard/courses/all-courses/AllCourses";
import CoursesCategory from "components/dashboard/courses/CoursesCategory";
import CategorySingle from "components/dashboard/courses/CategorySingle";

// ** Import Users Pages
import Instructor from "components/dashboard/user/Instructor";
import Students from "components/dashboard/user/Students";
import Users from "components/dashboard/user/Users";

// ** Import CMS Pages
import CMSDashboard from "components/dashboard/cms/CMSDashboard";
import AllPosts from "components/dashboard/cms/all-posts/AllPosts";
import AddNewPost from "components/dashboard/cms/AddNewPost";
import Category from "components/dashboard/cms/Category";

// ** Import Authentication components
import SignIn from "components/dashboard/authentication/SignIn";
import SignUp from "components/dashboard/authentication/SignUp";
import ForgetPassword from "components/dashboard/authentication/ForgetPassword";
import Notifications from "components/dashboard/authentication/Notifications";

// ** Import Settings components
import General from "components/dashboard/settings/General";
import Google from "components/dashboard/settings/Google";
import Social from "components/dashboard/settings/Social";
import SocialLogin from "components/dashboard/settings/SocialLogin";
import Payment from "components/dashboard/settings/Payment";
import SMTPServer from "components/dashboard/settings/SMTPServer";

// Dashboard Projects ( new v1.2.0 )
import ProjectGrid from "components/dashboard/projects/grid/ProjectGrid";
import ProjectList from "components/dashboard/projects/list/ProjectList";
import CreateProject from "components/dashboard/projects/create-project/CreateProject";
import ProjectBudget from "components/dashboard/projects/single/budget/ProjectBudget";
import ProjectFiles from "components/dashboard/projects/single/files/ProjectFiles";
import ProjectOverview from "components/dashboard/projects/single/overview/ProjectOverview";
import ProjectSummary from "components/dashboard/projects/single/summary/ProjectSummary";
import ProjectTask from "components/dashboard/projects/single/task/ProjectTask";
import ProjectTeam from "components/dashboard/projects/single/team/ProjectTeam";

// Dashboard Apps -> Mail ( new v1.3.0 )
import Mail from "components/dashboard/mail-app/mail/Mail";
import MailDraft from "components/dashboard/mail-app/mail-draft/MailDraft";
import MailDetails from "components/dashboard/mail-app/mail-details/MailDetails";

// Dashboard Apps -> Task Kanban ( v1.4.0 )
import TaskKanban from "components/dashboard/task-kanban/Kanban";
import Chat from "components/dashboard/chat/Chat";

// Dashboard Apps -> Calendar ( v2.1.0 )
import Calendar from "components/dashboard/calendar/Calendar";

// Dashboard - Ecommerce Pages  ( v2.2.0 )
// ** Ecommerce -> Products Pages
import ProductGrid from "components/dashboard/ecommerce/products/ProductGrid";
import ProductGridWithSidebar from "components/dashboard/ecommerce/products/ProductGridWithSidebar";
import ProductsList from "components/dashboard/ecommerce/products/product-list/ProductsList";
import ProductSingle from "components/dashboard/ecommerce/products/ProductSingle";
import ProductSingleV2 from "components/dashboard/ecommerce/products/ProductSingleV2";
import AddProduct from "components/dashboard/ecommerce/products/AddProduct";

// ** Ecommerce -> Cart Pages
import Checkout from "components/dashboard/ecommerce/checkout/Checkout";
import ShoppingCart from "components/dashboard/ecommerce/cart/ShoppingCart";

// ** Ecommerce -> Order Pages
import Orders from "components/dashboard/ecommerce/orders/orders/Orders";
import OrderSingle from "components/dashboard/ecommerce/orders/OrderSingle";
import OrderSummary from "components/dashboard/ecommerce/orders/OrderSummary";
import OrderHistory from "components/dashboard/ecommerce/orders/OrderHistory";

// ** Ecommerce -> Customers Pages
import CustomersList from "components/dashboard/ecommerce/customers/CustomersList";
import CustomerSingle from "components/dashboard/ecommerce/customers/CustomerSingle";
import AddCustomer from "components/dashboard/ecommerce/customers/AddCustomer";

// Dashboard - Tables Pages  ( v2.2.0 )
import BasicTables from "components/dashboard/tables/basic/BasicTables";
import Datatables from "components/dashboard/tables/Datatables";

// ** Boostrap Forms components
import ChecksRadios from "components/elements/bootstrap/forms/ChecksRadios";
import FloatingLabels from "components/elements/bootstrap/forms/FloatingLabels";
import FormControls from "components/elements/bootstrap/forms/FormControls";
import FormText from "components/elements/bootstrap/forms/FormText";
import BSInputGroup from "components/elements/bootstrap/forms/BSInputGroup";
import Layouts from "components/elements/bootstrap/forms/Layouts";
import Range from "components/elements/bootstrap/forms/Range";
import BSSelect from "components/elements/bootstrap/forms/BSSelect";
import Validation from "components/elements/bootstrap/forms/Validation";

// ** Boostrap components
import Accordions from "components/elements/bootstrap/Accordions";
import Alerts from "components/elements/bootstrap/Alerts";
import AvatarStyles from "components/elements/bootstrap/AvatarStyles";
import Badges from "components/elements/bootstrap/Badges";
import Breadcrumbs from "components/elements/bootstrap/Breadcrumbs";
import Buttons from "components/elements/bootstrap/Buttons";
import ButtonGroup from "components/elements/bootstrap/ButtonGroup";
import Cards from "components/elements/bootstrap/Cards";
import Carousels from "components/elements/bootstrap/Carousels";
import CloseButtons from "components/elements/bootstrap/CloseButtons";
import Collapses from "components/elements/bootstrap/Collapses";
import Dropdowns from "components/elements/bootstrap/Dropdowns";
import Listgroups from "components/elements/bootstrap/Listgroups";
import Navbars from "components/elements/bootstrap/Navbars";
import Navs from "components/elements/bootstrap/Navs";
import BSOffcanvas from "components/elements/bootstrap/BSOffcanvas";
import Overlays from "components/elements/bootstrap/Overlays";
import Paginations from "components/elements/bootstrap/Paginations";
import Popovers from "components/elements/bootstrap/Popovers";
import Progress from "components/elements/bootstrap/Progress";
import Spinners from "components/elements/bootstrap/Spinners";
import Modals from "components/elements/bootstrap/Modals";
import Tables from "components/elements/bootstrap/Tables";
import Toasts from "components/elements/bootstrap/Toasts";
import Tooltips from "components/elements/bootstrap/Tooltips";

// ** Import docs and change log pages
import Documentation from "components/dashboard/documentation/Documentation";
import ChangeLog from "components/dashboard/changelog/ChangeLog";

/* ----------------------------------- */
/* IMPORTS FOR MARKETING PAGES - START */
import DefaultLayout from "layouts/marketing/DefaultLayout";
import LayoutFooterLinks from "layouts/marketing/LayoutFooterLinks";
import BlankLayout from "layouts/marketing/BlankLayout";
import NotFound from "layouts/marketing/NotFound";
import AcademyLayout from "layouts/marketing/AcademyLayout"; // added in 2.0.0
import CourseIndex from "components/marketing/pages/courses/course-index/CourseIndex";
import CourseCategory from "components/marketing/pages/courses/course-category/CourseCategory";

/* IMPORTS FOR FRONT SIDE PAGES MENU */
import CourseSingle from "components/marketing/pages/courses/course-single/CourseSingle";
import CourseSingle2 from "components/marketing/pages/courses/course-single2/CourseSingle2";
import CourseResume from "components/marketing/pages/courses/course-resume/CourseResume";
import CourseCheckout from "components/marketing/pages/courses/course-checkout/CourseCheckout";
import CourseFilterPage from "components/marketing/pages/courses/course-filter-page/CourseFilterPage";
import AddNewCourse from "components/marketing/pages/courses/add-new-course/AddNewCourse";
import CoursePath from "components/marketing/pages/courses/course-path/CoursePath";
import CoursePathSingle from "components/marketing/pages/courses/course-path-single/CoursePathSingle";
import About from "components/marketing/pages/about/About";
import Pricing from "components/marketing/pages/pricing/Pricing";
import ComparePlan from "components/marketing/pages/compare-plan/ComparePlan"; // new v1.1.0
import Contact from "components/marketing/pages/contact/Contact"; // new v1.1.0

// IMPORTS FOR HELP CENTER PAGES ( v1.3.0 )
import HelpCenter from "components/marketing/pages/help-center/help-center/HelpCenter";
import HelpCenterFAQ from "components/marketing/pages/help-center/help-center-faq/HelpCenterFAQ";
import HelpCenterGuide from "components/marketing/pages/help-center/help-center-guide/HelpCenterGuide";
import HelpCenterGuideSingle from "components/marketing/pages/help-center/help-center-guide-single/HelpCenterGuideSingle";
import HelpCenterSupport from "components/marketing/pages/help-center/help-center-support/HelpCenterSupport";

/* IMPORTS FOR FRONT BLOG SUBMENU  ROUTERS */
import BlogListing from "components/marketing/blog/BlogListing";
import BlogArticleSingle from "components/marketing/blog/BlogArticleSingle";
import BlogCategory from "components/marketing/blog/BlogCategory";
import BlogSidebar from "components/marketing/blog/BlogSidebar";

/* IMPORTS FOR FRONT CAREER SUBMENU  ROUTERS */
import Career from "components/marketing/pages/career/career/Career"; // new v1.1.0
import CareerList from "components/marketing/pages/career/career-list/CareerList"; // new v1.1.0
import CareerSingle from "components/marketing/pages/career/career-single/CareerSingle"; // new v1.1.0

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import ComingSoon from "components/marketing/pages/specialty/ComingSoon";
import Error404 from "components/marketing/pages/specialty/Error404";
import MaintenanceMode from "components/marketing/pages/specialty/MaintenanceMode";
import TermsAndConditions from "components/marketing/pages/specialty/TermsAndConditions";

// Landing menu item pages
import LandingCourses from "components/marketing/landings/landing-courses/LandingCourses";
import CourseLead from "components/marketing/landings/course-lead/CourseLead";
import RequestAccess from "components/marketing/landings/request-access/RequestAccess";
import LandingSass from "components/marketing/landings/landing-sass/LandingSass"; // new v1.1.0
import HomeAcademy from "components/marketing/landings/home-academy/HomeAcademy"; // new v2.0.0
import LandingJob from "components/marketing/landings/landing-job/LandingJob"; // new v2.0.0
import LandingEducation from "components/marketing/landings/landing-education/LandingEducation"; // new v2.2.0

// Job Listing new pages ( v2.0.0 )
import JobsList from "components/marketing/pages/jobs/listing/JobsList";
import JobSingle from "components/marketing/pages/jobs/listing/JobSingle";
import CompanyList from "components/marketing/pages/jobs/company-list/CompanyList";
import CompanyAbout from "components/marketing/pages/jobs/company/About";
import CompanyReviews from "components/marketing/pages/jobs/company/Reviews";
import CompanyJobs from "components/marketing/pages/jobs/company/Jobs";
import CompanyBenifits from "components/marketing/pages/jobs/company/Benifits";
import CompanyPhotos from "components/marketing/pages/jobs/company/Photos";
import PostAJob from "components/marketing/pages/jobs/post-a-job/PostAJob";
import UploadResume from "components/marketing/pages/jobs/upload-resume/UploadResume";

// Instructor Dashboard Pages
import InstructorDashboard from "components/marketing/instructor/Dashboard";
import InstructorMyCourses from "components/marketing/instructor/MyCourses";
import InstructorReviews from "components/marketing/instructor/Reviews";
import Earnings from "components/marketing/instructor/Earnings";
import InstructorOrders from "components/marketing/instructor/Orders";
import InstructorStudents from "components/marketing/instructor/Students";
import ViewProfile from "components/marketing/instructor/ViewProfile";

// HEALTHWORKER
import Patients from "components/marketing/healthworkers/Patients";

// Instructor New Dashboard Pages for Quiz ( v2.0.0)
import InstructorQuiz from "components/marketing/instructor/Quiz";
import InstructorQuizSingle from "components/marketing/instructor/QuizSingle";
import InstructorQuizResult from "components/marketing/instructor/QuizResult";

// Student Dashboard Pages
import StudentDashboard from "components/marketing/student/Dashboard";
import DeleteProfile from "components/marketing/account-settings/DeleteProfile";
import EditProfile from "components/marketing/account-settings/EditProfile";
import LinkedAccounts from "components/marketing/account-settings/LinkedAccounts";
import AccountNotifications from "components/marketing/account-settings/Notifications";
import StudentPayment from "components/marketing/account-settings/Payment";
import ProfilePrivacy from "components/marketing/account-settings/ProfilePrivacy";
import Security from "components/marketing/account-settings/Security";
import SocialProfiles from "components/marketing/account-settings/SocialProfiles";
import Subscriptions from "components/marketing/account-settings/Subscriptions";

// Student New Dashboard Pages for Quiz ( v2.0.0)
import StudentQuiz from "components/marketing/student/Quiz";
import StudentQuizStart from "components/marketing/student/quiz-start/QuizStart";
import StudentQuizAttempt from "components/marketing/student/QuizAttempt";
import StudentQuizResult from "components/marketing/student/QuizResult";

// Account Settings
import BillingInfo from "components/marketing/account-settings/BillingInfo";
import Payouts from "components/marketing/account-settings/Payouts";
import Invoice from "components/marketing/account-settings/Invoice";
import InvoiceDetails from "components/marketing/account-settings/InvoiceDetails";

// Portfolio Pages ( v2.2.0)
import PortfolioList from "components/marketing/pages/portfolio/PortfolioList";
import PortfolioSingle from "components/marketing/pages/portfolio/PortfolioSingle";
import AuthGuard from "guards/AuthGuard";
import GuestGuard from "guards/GuestGuard";
import AddInstructor from "components/dashboard/user/AddInstructor";
import AddUser from "components/dashboard/user/AddUsers";
import EditCourse from "components/marketing/pages/courses/add-new-course/EditCourse";
import ResetPassword from "components/dashboard/authentication/ResetPassword";
import AddPatient from "components/marketing/healthworkers/AddPatient";
import PatientDataForms from "components/marketing/healthworkers/PatientDataForms";
import AddPatientForm from "components/marketing/healthworkers/AddPatientForm";
import MobileQuiz from "components/marketing/quiz-mobile/MobileQuiz";
import CourseMeeting from "components/marketing/pages/courses/CourseMeeting";
import CourseProgress from "components/dashboard/courses/course-progress/CourseProgress";

// renamed all marketing routes to academy

const AllRoutes = () => {
  return (
    <Routes>
      {/* Routes with DefaultLayout */}
      <Route element={<DefaultLayout />}>
        <Route path="/" element={<CourseIndex />} />
        <Route path="/academy/course-category/" element={<CourseCategory />} />
        <Route
          path="/academy/courses/course-checkout/:guid/"
          element={
            <AuthGuard>
              <CourseCheckout />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/courses/course-filter-page/"
          element={<CourseFilterPage />}
        />
        <Route
          path="/academy/instructor/add-new-course/"
          element={<AddNewCourse />}
        />
        <Route
          path="/academy/instructor/edit-course/:guid"
          element={<AddNewCourse />}
        />
        <Route
          path="/academy/instructor/edit-course-details/:guid"
          element={<EditCourse />}
        />
        <Route path="/academy/courses/course-path/" element={<CoursePath />} />
        <Route
          path="/academy/courses/course-path-single/"
          element={<CoursePathSingle />}
        />
        <Route
          path="/academy/courses/course-single/"
          element={<CourseSingle />}
        />
        <Route
          path="/academy/courses/course-view/:guid"
          element={<CourseSingle2 />}
        />
        <Route
          path="/academy/courses/admin-review/:guid"
          element={<CourseSingle2 />}
        />
        <Route path="/academy/pages/pricing/" element={<Pricing />} />
        <Route
          path="/academy/blog/article-single/:id"
          element={<BlogArticleSingle />}
        />
        <Route path="/academy/blog/category/" element={<BlogCategory />} />
        <Route path="/academy/blog/listing/" element={<BlogListing />} />
        <Route
          path="/academy/instructor/dashboard/"
          element={
            <AuthGuard>
              <InstructorDashboard />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-my-courses/"
          element={
            <AuthGuard>
              <InstructorMyCourses />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-reviews/"
          element={
            <AuthGuard>
              <InstructorReviews />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-earnings/"
          element={
            <AuthGuard>
              <Earnings />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-orders/"
          element={
            <AuthGuard>
              <InstructorOrders />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-students/"
          element={
            <AuthGuard>
              <InstructorStudents />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-payouts/"
          element={
            <AuthGuard>
              <Payouts />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-edit-profile/"
          element={
            <AuthGuard>
              <EditProfile />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-profile/"
          element={
            <AuthGuard>
              <ViewProfile />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-security/"
          element={
            <AuthGuard>
              <Security />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-social-profiles/"
          element={<SocialProfiles />}
        />
        <Route
          path="/academy/instructor/instructor-notifications/"
          element={
            <AuthGuard>
              <AccountNotifications />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-profile-privacy/"
          element={
            <AuthGuard>
              <ProfilePrivacy />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/instructor/instructor-delete-profile/"
          element={
            <AuthGuard>
              <DeleteProfile />
            </AuthGuard>
          }
        />
        <Route path="/academy/instructor/quiz/" element={<InstructorQuiz />} />
        <Route
          path="/academy/instructor/quiz/single/"
          element={<InstructorQuizSingle />}
        />
        <Route
          path="/academy/instructor/quiz/result/"
          element={<InstructorQuizResult />}
        />
        {/* healthworker nurses doctors */}
        <Route
          path="/academy/healthworker/patients/"
          element={
            <AuthGuard>
              <Patients />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/healthworker/patients/add"
          element={
            <AuthGuard>
              <AddPatient />
            </AuthGuard>
          }
        />

        <Route
          path="/academy/healthworker/patients/edit/:guid"
          element={
            <AuthGuard>
              <AddPatient />
            </AuthGuard>
          }
        />

        <Route
          path="/academy/healthworker/patients/forms/:guid"
          element={
            <AuthGuard>
              <PatientDataForms />
            </AuthGuard>
          }
        />

        <Route
          path="/academy/healthworker/patients/forms/:guid/add"
          element={
            <AuthGuard>
              <AddPatientForm />
            </AuthGuard>
          }
        />



        <Route
          path="/academy/student/dashboard/"
          element={
            <AuthGuard>
              <StudentDashboard />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-subscriptions/"
          element={
            <AuthGuard>
              <Subscriptions />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-billing-info/"
          element={
            <AuthGuard>
              <BillingInfo />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-payment/"
          element={
            <AuthGuard>
              <StudentPayment />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-invoice/"
          element={
            <AuthGuard>
              <Invoice />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-invoice-details/"
          element={
            <AuthGuard>
              <InvoiceDetails />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-edit-profile/"
          element={
            <AuthGuard>
              <EditProfile />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-security/"
          element={
            <AuthGuard>
              <Security />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-social-profiles/"
          element={<SocialProfiles />}
        />
        <Route
          path="/academy/student/student-notifications/"
          element={
            <AuthGuard>
              <AccountNotifications />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-profile-privacy/"
          element={<ProfilePrivacy />}
        />
        <Route
          path="/academy/student/student-delete-profile/"
          element={
            <AuthGuard>
              <DeleteProfile />
            </AuthGuard>
          }
        />
        <Route
          path="/academy/student/student-linked-accounts/"
          element={<LinkedAccounts />}
        />
        <Route path="/academy/student/quiz/" element={<StudentQuiz />} />
        <Route
          path="/academy/student/quiz/attempt/"
          element={<StudentQuizAttempt />}
        />
        <Route
          path="/academy/student/quiz/start/"
          element={<StudentQuizStart />}
        />
        <Route
          path="/academy/student/quiz/result/"
          element={<StudentQuizResult />}
        />
      </Route>

      {/* Routes with BlankLayout */}
      <Route element={<BlankLayout />}>
        <Route
          path="/academy/quiz/mobile/:course/:guid"
          element={<MobileQuiz />}
        />
        <Route
          path="/academy/courses/course-meeting/:guid/"
          element={<CourseMeeting />}
        />
        <Route
          path="/academy/landings/landing-education/"
          element={<LandingEducation />}
        />
        <Route
          path="/academy/landings/landing-courses/"
          element={<LandingCourses />}
        />
        <Route path="/academy/landings/course-lead/" element={<CourseLead />} />
        <Route
          path="/academy/landings/request-access/"
          element={<RequestAccess />}
        />
        <Route
          path="/academy/landings/landing-sass/"
          element={<LandingSass />}
        />
        <Route path="/academy/pages/about/" element={<About />} />
        <Route
          path="/academy/courses/course-resume/:guid"
          element={<CourseResume />}
        />

        <Route
          path="/academy/course/review/:guid"
          element={<CourseResume />}
        />
        <Route path="/academy/pages/contact/" element={<Contact />} />
        <Route path="/academy/blog/sidebar/" element={<BlogSidebar />} />
        <Route
          path="/academy/specialty/terms-and-conditions/"
          element={<TermsAndConditions />}
        />
      </Route>

      {/* Routes with NotFound */}
      <Route element={<NotFound />}>
        <Route
          path="/academy/specialty/coming-soon/"
          element={<ComingSoon />}
        />
        <Route path="/academy/specialty/404-error/" element={<Error404 />} />
        <Route
          path="/academy/specialty/maintenance-mode/"
          element={<MaintenanceMode />}
        />
      </Route>

      {/* Routes with JobListingLayout */}
      <Route element={<JobListingLayout />}>
        <Route path="/academy/landings/landing-job/" element={<LandingJob />} />
        <Route path="/academy/jobs/listing/job-list/" element={<JobsList />} />
        <Route path="/academy/jobs/listing/:slug" element={<JobSingle />} />
        <Route path="/academy/jobs/company-list/" element={<CompanyList />} />
        <Route path="/academy/jobs/company/about/" element={<CompanyAbout />} />
        <Route
          path="/academy/jobs/company/reviews/"
          element={<CompanyReviews />}
        />
        <Route path="/academy/jobs/company/jobs/" element={<CompanyJobs />} />
        <Route
          path="/academy/jobs/company/benifits/"
          element={<CompanyBenifits />}
        />
        <Route
          path="/academy/jobs/company/photos/"
          element={<CompanyPhotos />}
        />
        <Route path="/academy/jobs/post-a-job/" element={<PostAJob />} />
        <Route path="/academy/jobs/upload-resume/" element={<UploadResume />} />
      </Route>

      {/* Routes with LayoutFooterLinks */}
      <Route element={<LayoutFooterLinks />}>
        <Route path="/academy/portfolio/list/" element={<PortfolioList />} />
        <Route
          path="/academy/portfolio/single/"
          element={<PortfolioSingle />}
        />
        <Route path="/academy/pages/compare-plan/" element={<ComparePlan />} />
        <Route path="/academy/pages/career/" element={<Career />} />
        <Route path="/academy/pages/career-list/" element={<CareerList />} />
        <Route
          path="/academy/pages/career-single/"
          element={<CareerSingle />}
        />
      </Route>

      {/* Routes with AcademyLayout */}
      <Route element={<AcademyLayout />}>
        <Route
          path="/academy/landings/home-academy/"
          element={<HomeAcademy />}
        />
      </Route>

      {/* Routes with HelpCenterTransparentLayout */}
      <Route element={<HelpCenterTransparentLayout />}>
        <Route path="/academy/help-center/" element={<HelpCenter />} />
      </Route>

      {/* Routes with HelpCenterLayout */}
      <Route element={<HelpCenterLayout />}>
        <Route path="/academy/help-center/faq/" element={<HelpCenterFAQ />} />
        <Route
          path="/academy/help-center/guide/"
          element={<HelpCenterGuide />}
        />
        <Route
          path="/academy/help-center/guide-single/:categoryslug/:articleslug"
          element={<HelpCenterGuideSingle />}
        />
        <Route
          path="/academy/help-center/support/"
          element={<HelpCenterSupport />}
        />
      </Route>
      {/* Routes with AuthLayout */}
      <Route element={<AuthLayout />}>
        <Route
          path="/authentication/sign-in"
          element={
            <GuestGuard>
              <SignIn />
            </GuestGuard>
          }
        />
        <Route path="/authentication/sign-up" element={<SignUp />} />
        <Route
          path="/authentication/forget-password"
          element={<ForgetPassword />}
        />
        <Route
          path="/authentication/reset-password"
          element={<ResetPassword />}
        />
      </Route>

      {/* Routes (DASHBOARD ROUTERS) with DashboardIndex */}
      <Route
        element={
          <AuthGuard>
            <DashboardIndex />
          </AuthGuard>
        }
      >
        <Route path="/dashboard/overview" element={<Overview />} />
        <Route path="/dashboard/analytics" element={<Analytics />} />
        <Route path="/courses/all-courses" element={<AllCourses />} />
        <Route path="/courses/courses-category" element={<CoursesCategory />} />
        <Route path="/courses/category-single" element={<CategorySingle />} />
        <Route path="/user/instructor" element={<Instructor />} />
        <Route path="/user/instructor/add-new" element={<AddInstructor />} />
        <Route path="/user/user/add-new" element={<AddUser />} />
        <Route path="/user/user/edit/:guid" element={<AddUser />} />
        <Route path="/user/users" element={<Users />} />
        <Route path="/user/students" element={<Students />} />
        <Route path="/cms/cms-dashboard" element={<CMSDashboard />} />
        <Route path="/cms/all-posts" element={<AllPosts />} />
        <Route path="/cms/add-new-post" element={<AddNewPost />} />
        <Route path="/cms/category" element={<Category />} />
        <Route path="/courses/progress/:guid" element={<CourseProgress />} />

        <Route
          path="/authentication/notifications"
          element={<Notifications />}
        />
        <Route
          path="/dashboard/layouts/layout-vertical"
          element={<Overview />}
        />

        {/* SETTINGS ROUTERS */}
        <Route path="/settings/general" element={<General />} />
        <Route path="/settings/google" element={<Google />} />
        <Route path="/settings/social" element={<Social />} />
        <Route path="/settings/social-login" element={<SocialLogin />} />
        <Route path="/settings/payment" element={<Payment />} />
        <Route path="/settings/smtp-server" element={<SMTPServer />} />

        {/* PROJECTS ROUTERS */}
        <Route path="/dashboard/projects/grid" element={<ProjectGrid />} />
        <Route path="/dashboard/projects/list" element={<ProjectList />} />
        <Route
          path="/dashboard/projects/create-project"
          element={<CreateProject />}
        />
        <Route
          path="/dashboard/projects/single/budget"
          element={<ProjectBudget />}
        />
        <Route
          path="/dashboard/projects/single/files"
          element={<ProjectFiles />}
        />
        <Route
          path="/dashboard/projects/single/overview"
          element={<ProjectOverview />}
        />
        <Route
          path="/dashboard/projects/single/summary"
          element={<ProjectSummary />}
        />
        <Route
          path="/dashboard/projects/single/task"
          element={<ProjectTask />}
        />
        <Route
          path="/dashboard/projects/single/team"
          element={<ProjectTeam />}
        />

        {/* REACT-BOOTSTRAP FORMS COMPOENTS ROUTERS */}
        <Route
          path="/elements/forms/checks-and-radios"
          element={<ChecksRadios />}
        />
        <Route
          path="/elements/forms/floating-labels"
          element={<FloatingLabels />}
        />
        <Route
          path="/elements/forms/form-controls"
          element={<FormControls />}
        />
        <Route path="/elements/forms/form-text" element={<FormText />} />
        <Route path="/elements/forms/input-group" element={<BSInputGroup />} />
        <Route path="/elements/forms/layouts" element={<Layouts />} />
        <Route path="/elements/forms/range" element={<Range />} />
        <Route path="/elements/forms/select" element={<BSSelect />} />
        <Route path="/elements/forms/validation" element={<Validation />} />

        {/* REACT-BOOTSTRAP COMPOENTS ROUTERS */}
        <Route path="/elements/accordions" element={<Accordions />} />
        <Route path="/elements/alerts" element={<Alerts />} />
        <Route path="/elements/avatar" element={<AvatarStyles />} />
        <Route path="/elements/badges" element={<Badges />} />
        <Route path="/elements/breadcrumbs" element={<Breadcrumbs />} />
        <Route path="/elements/buttons" element={<Buttons />} />
        <Route path="/elements/button-group" element={<ButtonGroup />} />
        <Route path="/elements/cards" element={<Cards />} />
        <Route path="/elements/carousels" element={<Carousels />} />
        <Route path="/elements/close-button" element={<CloseButtons />} />
        <Route path="/elements/collapse" element={<Collapses />} />
        <Route path="/elements/dropdowns" element={<Dropdowns />} />
        <Route path="/elements/list-group" element={<Listgroups />} />
        <Route path="/elements/modal" element={<Modals />} />
        <Route path="/elements/navs" element={<Navs />} />
        <Route path="/elements/offcanvas" element={<BSOffcanvas />} />
        <Route path="/elements/overlays" element={<Overlays />} />
        <Route path="/elements/navbar" element={<Navbars />} />
        <Route path="/elements/pagination" element={<Paginations />} />
        <Route path="/elements/popovers" element={<Popovers />} />
        <Route path="/elements/progress" element={<Progress />} />
        <Route path="/elements/spinners" element={<Spinners />} />
        <Route path="/elements/tables" element={<Tables />} />
        <Route path="/elements/toasts" element={<Toasts />} />
        <Route path="/elements/tooltips" element={<Tooltips />} />
        <Route path="/dashboard/documentation" element={<Documentation />} />
        <Route path="/dashboard/changelog" element={<ChangeLog />} />
        <Route path="/dashboard/calendar" element={<Calendar />} />

        {/* ** Dashboard - Ecommerce -> Products Pages ( v2.2.0 ) */}
        <Route
          path="/dashboard/ecommerce/products/product-grid"
          element={<ProductGrid />}
        />
        <Route
          path="/dashboard/ecommerce/products/product-grid-with-sidebar"
          element={<ProductGridWithSidebar />}
        />
        <Route
          path="/dashboard/ecommerce/products"
          element={<ProductsList />}
        />
        <Route
          path="/dashboard/ecommerce/products/product-single"
          element={<ProductSingle />}
        />
        <Route
          path="/dashboard/ecommerce/products/product-single-v2"
          element={<ProductSingleV2 />}
        />
        <Route
          path="/dashboard/ecommerce/products/add-product"
          element={<AddProduct />}
        />

        {/* ** Dashboard - Tables Pages ( v2.2.0 ) */}
        <Route
          path="/dashboard/tables/basic-tables"
          element={<BasicTables />}
        />
        <Route path="/dashboard/tables/datatables" element={<Datatables />} />
      </Route>

      {/* Routes with EcommerceLayout (v2.2.0) */}
      <Route element={<EcommerceLayout />}>
        {/* ** Dashboard - Ecommerce -> Cart Pages ( v2.2.0 )*/}
        <Route
          path="/dashboard/ecommerce/shopping-cart"
          element={<ShoppingCart />}
        />
        <Route path="/dashboard/ecommerce/checkout" element={<Checkout />} />

        {/* ** Dashboard - Ecommerce -> Order Pages ( v2.2.0 ) */}
        <Route path="/dashboard/ecommerce/orders" element={<Orders />} />
        <Route
          path="/dashboard/ecommerce/order-single"
          element={<OrderSingle />}
        />
        <Route
          path="/dashboard/ecommerce/order-summary"
          element={<OrderSummary />}
        />
        <Route
          path="/dashboard/ecommerce/order-history"
          element={<OrderHistory />}
        />

        {/* ** Dashboard - Ecommerce -> Customers Pages ( v2.2.0 ) */}
        <Route
          path="/dashboard/ecommerce/customers"
          element={<CustomersList />}
        />
        <Route
          path="/dashboard/ecommerce/customer/:id"
          element={<CustomerSingle />}
        />
        <Route
          path="/dashboard/ecommerce/add-customer"
          element={<AddCustomer />}
        />
      </Route>

      {/* Routes with MailLayout */}
      <Route element={<MailLayout />}>
        <Route path="/dashboard/mail" element={<Mail />} />
        <Route path="/dashboard/mail-draft" element={<MailDraft />} />
        <Route path="/dashboard/mail-details" element={<MailDetails />} />
      </Route>

      {/* Routes with ChatLayout */}
      <Route element={<ChatLayout />}>
        <Route path="/dashboard/chat" element={<Chat />} />
      </Route>

      {/* Routes with TaskKanbanLayout */}
      <Route element={<TaskKanbanLayout />}>
        <Route path="/dashboard/task-kanban" element={<TaskKanban />} />
      </Route>

      {/* Routes with DashboardIndexTop */}
      <Route element={<DashboardIndexTop />}>
        <Route
          path="/dashboard/layouts/layout-horizontal"
          element={<Overview />}
        />
      </Route>

      {/* Routes with DashboardIndexCompact */}
      <Route element={<DashboardIndexCompact />}>
        <Route
          path="/dashboard/layouts/layout-compact"
          element={<Overview />}
        />
      </Route>

      {/*Redirect*/}
      <Route
        path="*"
        element={<Navigate to="/academy/specialty/404-error/" replace />}
      />
    </Routes>
  );
};

export default AllRoutes;
