// import node module libraries
import { useMutation } from '@apollo/client';
import { CREATE_CHAT_MESSAGE } from 'helper/graphql/mutations';
import { Notify } from 'notiflix';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

// import context file
// import { ChatContext } from 'context/Context';

// import utility file
// import { getDateValue, getTimeValue } from 'helper/utils';

const SendMessage = ({ currUser, chatInfo }) => {
	const [message, setMessage] = useState(null);

	const [onCreateMsg, {loading}] = useMutation(CREATE_CHAT_MESSAGE);
	
	const handleSubmit = () => {
		let newMessage = {
			chatGroupGuid: chatInfo?.guid,
			userGuid: currUser?.guid,
			message: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
		};

		onCreateMsg({
			variables: newMessage,
			onCompleted: () => {
				setMessage('');
				Notify.success('Message sent!');
			},
			onError: () => Notify.failure('Failed to send message!')
		})
	}

	return (
		<div className="bg-white p-2 rounded-3 shadow-sm">
			<div className="position-relative">
				<Form.Control
					as="textarea"
					placeholder="Type a New Message"
					id="Excerpt"
					value={message}
					onChange={({ target }) => setMessage(target.value)}
					className="form-control border-0 form-control-simple no-resize"
					style={{ height: '40px' }}
					disabled={loading}
				/>
			</div>
			<div className="position-absolute end-0 mt-n7 me-4">
				<Button
					variant="none"
					bsPrefix="btn"
					type="submit"
					className="fs-3 text-primary btn-focus-none"
					onClick={handleSubmit}
					disabled={loading}
				>
					<i className="fe fe-send"></i>
				</Button>
			</div>
		</div>
	);
};
export default SendMessage;
