// import node module libraries
// import { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";

const CoursesMedia = (props) => {
  const { previous, createCourse, handleChange, isLoading } = props; 

  const handleSubmit = (e) => {
    e.preventDefault();
    createCourse();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Card */}
      <Card className="mb-3  border-0">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Courses Media</h4>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {/* Course cover image */}
          <Form.Label>Course cover image</Form.Label>
          <Form.Group className="mb-1 input-group">
            <Form.Control
              id="inputfavicon"
              name="introImage"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              multiple={false}
              className="form-control"
              onChange={handleChange}
              // required
            />
            <Form.Label
              htmlFor="inputfavicon"
              className="input-group-text mb-0"
            >
              Upload
            </Form.Label>
            <Form.Text className="text-muted">
              Upload your course image here. It must meet our course image
              quality standards to be accepted. Important guidelines: 750x440
              pixels; .jpg, .jpeg, or .png. no text on the image.
            </Form.Text>
          </Form.Group>

          <Form.Label>Course intro video</Form.Label>
          <Form.Group className="input-group mb-1">
            <Form.Control
              id="introvideo"
              name="introVideo"
              type="file"
              accept="video/*"
              multiple={false}
              className="form-control"
              onChange={handleChange}
              // required
            />
            <Form.Label
              htmlFor="introvideo"
              className="input-group-text mb-0"
            >
              Upload
            </Form.Label>
            {/* <Form.Text className="text-muted">
              Upload your intro video here. It must meet our course image
              quality standards to be accepted. Important guidelines: HD ; .mp4.
            </Form.Text> */}
          </Form.Group>
        </Card.Body>
      </Card>

      {/* Button */}
      <div className="d-flex justify-content-between">
        <Button variant="secondary" onClick={previous} disabled={isLoading} >
          Previous
        </Button>
        <Button variant="primary" type="submit" disabled={isLoading }>
          {isLoading ? 'Loading ...' : 'Next'}
        </Button>
      </div>
    </Form>
  );
};
export default CoursesMedia;
