// import node module libraries
import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Trash, Edit, MoreVertical, Send } from "react-feather";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Card,
  Row,
  Col,
  Dropdown,
  Image,
  Badge,
  Table,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";

// import MDI icons
// import Icon from '@mdi/react';
// import { mdiStar } from '@mdi/js';

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";
import { formatDate, truncateTextByCount } from "helper/utils";

// import data files
// import { MyCoursesData as data_ } from 'data/courses/MyCoursesData';

// import utility file
// import { numberWithCommas } from 'helper/utils';
// ----------------------------------------------------------------------
import { GET_MY_COURSES } from "helper/graphql/queries";
import { PATH_DASHBOARD } from "routes/Paths";
import {
  DELETE_COURSE,
  UPDATE_COURSE_ACTIVATION,
} from "helper/graphql/mutations";
import { Notify } from "notiflix";
import AlertModal from "components/elements/modal-alert/AlertModal";
// ----------------------------------------------------------------------

const MyCourses = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const [filtering, setFiltering] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const [myCourses, setCourses] = useState([]);

  useQuery(GET_MY_COURSES, {
    onCompleted: (data) => {
      const _courses = data?.allCourses?.edges?.map((item) => item.node);
      setCourses(
        _courses?.filter((course) => course.creator.guid === user?.guid)
      );
    },
  });

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ course }) => {
    const [showDelete, setShowDelete] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    const [onUpdateCourseActivation] = useMutation(UPDATE_COURSE_ACTIVATION);
    const [onDeleteCourse] = useMutation(DELETE_COURSE);

    const handleUpdate = (status) => {
      onUpdateCourseActivation({
        variables: { guid: course?.guid, isActive: course?.isActive, status: status,
          title: course?.title,
          description: course?.description, 
          language: course?.language, 
          dificultyLevel: course?.dificultyLevel || '', 
          creatorId: course?.creator?.guid, 
          courseStartDate: course?.courseStartDate, 
          courseEndDate: course?.courseEndDate,          
        },
        awaitRefetchQueries: true,
        refetchQueries: [GET_MY_COURSES],
        onCompleted: () => {
          Notify.success("Course status updated successfully!");
          setShowSubmit(false);
        },
        onError: () => {
          Notify.failure("Failed to update course!");
        },
      });
    };

    const handleDelete = (guid) => {
      onDeleteCourse({
        variables: { guid: guid },
        awaitRefetchQueries: true,
        refetchQueries: [GET_MY_COURSES],
        onCompleted: () => {
          Notify.success("Course deleted successfully!");
          setShowDelete(false);
        },
        onError: () => {
          Notify.failure("Failed to delete course!");
        },
      });
    };

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <MoreVertical size="15px" className="text-secondary" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Header>SETTINGS</Dropdown.Header>
            {course?.status?.toLowerCase() !== 'active' &&
              <>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    // if(course?.status !== "Submitted" && course?.status !== "Active" ){
                    navigate(PATH_DASHBOARD.instructor.editDetails(course?.guid))
                  }
                    // }
                  }
                // disabled={course?.status !== "Submitted" && course?.status !== "Active"}
                >
                  {" "}
                  <Edit size="15px" className="dropdown-item-icon" /> Edit
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="4"
                  onClick={() => {
                    // if(course?.status !== "Submitted" && course?.status !== "Active" ){
                    navigate(PATH_DASHBOARD.instructor.edit(course?.guid))
                  }
                    // }
                  }
                // disabled={course?.status !== "Submitted" && course?.status !== "Active"}
                >
                  {" "}
                  <Edit size="15px" className="dropdown-item-icon" /> Curriculum
                </Dropdown.Item>
              </>
            }

            <Dropdown.Item eventKey="2" onClick={() => setShowDelete(true)}>
              {" "}
              <Trash size="15px" className="dropdown-item-icon" /> Remove
            </Dropdown.Item>
            {(course?.status?.toLowerCase() === 'submitted' || course?.status?.toLowerCase() === 'active') ?
              null
              :
              <Dropdown.Item eventKey="3" onClick={() => setShowSubmit(true)}>
                {" "}
                <Send size="15px" className="dropdown-item-icon" /> Submit
              </Dropdown.Item>
            }
          </Dropdown.Menu>
        </Dropdown>

        <AlertModal
          isOpen={showSubmit}
          onClose={() => setShowSubmit(false)}
          handleYes={() => {
            handleUpdate("Submitted");
          }}
          title="Course Submission"
          message="Are you sure you want to submit this course?"
        />

        <AlertModal
          isOpen={showDelete}
          onClose={() => setShowDelete(false)}
          handleYes={() => {
            handleDelete(course?.guid);
          }}
          title="Course Delete!"
          message="Are you sure you want to delete this course?"
        />
      </>
    );
  };

  const sortby = [
    { value: "Date Created", label: "Date Created" },
    { value: "Newest", label: "Newest" },
    { value: "High Rated", label: "High Rated" },
    // { value: "Law Rated", label: "Law Rated" },
    // { value: "High Earned", label: "High Earned" },
  ];

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "title",
      //   header: "title",
      //   cell: ({row, getValue}) => {
      //     return (
      //       <Link href={}>
      //           {/* {getValue()} */}
      //           test
      //       </Link>
      //     )
      //   }
      // },
      // {
      //   accessorKey: "level",
      //   header: "level",
      // },
      // {
      // 	accessorKey: 'duration',
      // 	header: 'duration'
      // },
      {
        accessorKey: "introImage",
        header: "Courses",
        cell: ({ row }) => {
          return (
            <div className="d-lg-flex align-items-center"
              onClick={() => {
                if (row?.original?.status !== "Submitted" && row?.original?.status !== "Active") {
                  navigate(PATH_DASHBOARD.instructor.edit(row?.original?.guid))
                }
              }
              }
            >
              <div>
                <Link href="#">
                  <Image src={row.original?.introImage[0]} alt="" className="rounded img-4by3-lg" />
                </Link>
              </div>
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h4 className="mb-1 h5">
                  <Link href="#" className="text-wrap">
                    {truncateTextByCount(row?.original?.title, 8)}
                  </Link>
                </h4>
                <ListGroup as="ul" bsPrefix="list-inline" className="fs-6 mb-0">
                  {/* <ListGroup.Item as="li" bsPrefix="list-inline-item">
										<i className="far fa-clock me-1"></i>
										{row?.original?.duration || "1hr"}
									</ListGroup.Item> */}
                  <ListGroup.Item as="li" bsPrefix="list-inline-item">
                    <LevelIcon level={row.original.dificultyLevel} />
                    {row.original.dificultyLevel}
                  </ListGroup.Item>
                </ListGroup>
                {row?.original?.progress && (
                  <ProgressBar
                    variant="success"
                    className="mt-2"
                    now={row?.original?.progress}
                    style={{ height: "3px" }}
                  />
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "courseStartDate",
        header: "Start Date",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },
      {
        accessorKey: "courseEndDate",
        header: "End Date",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },

      // {
      // 	accessorKey: 'students',
      // 	header: 'Students',
      // 	cell: ({ getValue }) => {
      // 		return numberWithCommas(getValue());
      // 	}
      // },

      // {
      // 	accessorKey: 'rating',
      // 	header: 'Rating',
      // 	cell: ({  row }) => {
      // 		return (
      // 			<Fragment>
      // 				<span className="text-warning">
      // 					{4}
      // 					<Icon path={mdiStar} size={0.6} />
      // 				</span>
      // 				({numberWithCommas("4")})
      // 			</Fragment>
      // 		);
      // 	}
      // },
      {
        accessorKey: "createdAt",
        header: "Created At",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ getValue }) => {
          return (
            <Badge
              bg={`${getValue() === "Draft"
                ? "info"
                : getValue() === "Active"
                  ? "success"
                  : getValue() === "Rejected"
                    ? "danger"
                    : "warning"
                } `}
            >
              {getValue()}
            </Badge>
          );
        },
      },

      {
        accessorKey: "action",
        header: "",
        cell: ({ row }) => {
          return <ActionMenu course={row.original} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data = useMemo(() => myCourses, [myCourses]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      globalFilter: filtering,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setFiltering,
    debugTable: false,
    initialState: {
      columnVisibility: {
        title: false, // This will hide the column with the ID 'your-column-name'
        level: false,
        duration: false,
      },
    },
  });

  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Courses</h3>
            <p className="mb-0">
              Manage your courses and its update like live, draft and insight.
            </p>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={9} md={7} sm={12} className="mb-lg-0 mb-2">
              <GlobalFilter
                filtering={filtering}
                setFiltering={setFiltering}
                placeholder="Search Your Courses"
              />
            </Col>
            <Col lg={3} md={5} sm={12}>
              <FormSelect options={sortby} placeholder="Sort by" />
            </Col>
          </Row>
        </Card.Body>
        <Card.Body className="p-0 pb-5">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Table hover responsive className="text-nowrap table-centered">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows?.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* Pagination @ Footer */}
          <Pagination table={table} />
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};

export default MyCourses;
