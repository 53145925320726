// import node module libraries
import React, { Fragment, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image, Dropdown, Form } from "react-bootstrap";
import { XCircle, MoreVertical, CheckCircle, Clock} from "react-feather";

// import custom components
import DotBadge from "components/elements/bootstrap/DotBadge";
import TanstackTable from "components/elements/advance-table/TanstackTable";
import { formatDate, truncateTextByCount } from "helper/utils";
import { Avatar } from "components/elements/bootstrap/Avatar";
import AlertModal from "components/elements/modal-alert/AlertModal";
import { PATH_ADMIN } from "routes/Paths";
import { useMutation } from "@apollo/client";
import { REJECT_COURSE, UPDATE_COURSE_ACTIVATION } from "helper/graphql/mutations";
import { Notify } from "notiflix";


const getStatusColor = (status) => {
  if (status.toLowerCase() === "draft") {
    return 'warning'
  } else if (status.toLowerCase() === "active") {
    return 'success'
  } else if (status.toLowerCase() === "rejected") {
    return 'danger'
  } else {
    return 'info'
  }
}

const CoursesTable = ({ courses_data }) => {
  const navigate = useNavigate();
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));



  const ActionMenu = ({ course }) => {
    const [showReject, setShowReject] = useState(false);
    const [showAccept, setShowAccept] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const [onUpdateCourseActivation, {isLoading: isUpdating}] = useMutation(UPDATE_COURSE_ACTIVATION);
    const [onRejectCourse, {isLoading: isRejecting}] = useMutation(REJECT_COURSE);

    const handleUpdate = (status, isActive) => {
      onUpdateCourseActivation({
        variables: { guid: course?.guid, isActive: isActive, status: status,
          title: course?.title,
          description: course?.description, 
          language: course?.language, 
          dificultyLevel: course?.dificultyLevel || '', 
          creatorId: course?.creator?.guid, 
          courseStartDate: course?.courseStartDate, 
          courseEndDate: course?.courseEndDate,          
        },
        onCompleted: () => {
          Notify.success("Course status updated successfully!");
          setShowAccept(false);
          // setShowReject(false);
        },
        onError: () => {
          Notify.failure("Failed to update course!");
        },
      });
    };

    const handleReject = (status, isActive, rejectReason) => {
      onRejectCourse({
        variables: { 
          guid: course?.guid, isActive: isActive, status: status, rejectReason:rejectReason,
          title: course?.title,
          description: course?.description, 
          language: course?.language, 
          dificultyLevel: course?.dificultyLevel || '', 
          creatorId: course?.creator?.guid, 
          courseStartDate: course?.courseStartDate, 
          courseEndDate: course?.courseEndDate, 

        },
        onCompleted: () => {
          Notify.success("Course status updated successfully!");
          // setShowAccept(false);
          setShowReject(false);
        },
        onError: () => {
          Notify.failure("Failed to update course!");
        },
      });
    };

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <MoreVertical size="15px" className="text-secondary" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {/* <Dropdown.Header>SETTINGS</Dropdown.Header> */}
            <Dropdown.Item eventKey="1" onClick={() => setShowAccept(true)}>
              <CheckCircle size={14} className="me-1 text-primary" /> Accept
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => setShowReject(true)}>
              <XCircle size={14} className="me-1 text-danger" /> Reject
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => navigate(PATH_ADMIN.admin.viewCourseProgress(course?.guid))}>
              <Clock size={14} className="me-1 text-info" /> View Progress
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <AlertModal
          isOpen={showReject}
          onClose={() => setShowReject(false)}
          handleYes={() => {
            handleReject('Rejected', false, rejectReason);
          }}
          title="Course Rejection"
          message="Are you sure you want to reject this course?"
          isLoading={isRejecting}
          component={
            <div>
              <Form.Control
                // type="text"
                as="textarea" 
                rows={3}
                id="reason"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                placeholder="Reason"
                required
              />
            </div>
          }
        />

        <AlertModal
          isOpen={showAccept}
          onClose={() => setShowAccept(false)}
          handleYes={() => {
            handleUpdate('Active', true);
          }}
          title="Accept Course"
          message="Are you sure you want to accept this course?"
          isLoading={isUpdating}
        />
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        header: "Courses",
        accessorKey: "title",
        cell: ({ row, getValue }) => {
          // const title = getValue()?.length > 20 ? getValue().substring(0, 20) + '...' : getValue();

          return (
            <div
              onClick={() =>
                navigate(PATH_ADMIN.admin.reviewCourse(row?.original?.guid))
              }
              className="text-inherit"
            // style={{maxWidth: 200}}
            >
              <div className="d-lg-flex align-items-center gap-1">
                <div>
                  <Image
                    src={row.original?.introImage[0]}
                    alt={'course_img'}
                    className="img-4by3-lg rounded"
                  />
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 text-primary-hover text-wrap">{truncateTextByCount(getValue(), 8)}</h4>
                  <span className="text-inherit">
                    { }
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: "Instructor",
        accessorKey: "creator",
        cell: ({ row, getValue }) => (
          <div className="d-flex align-items-center gap-1">
            {/* <Image
						src={row.}
						alt=""
						className="rounded-circle avatar-xs me-2"
					/> */}
            <Avatar
              size="sm"
              className="rounded-circle me-2 mr-3s"
              type="initial"
              alt={getValue()?.firstName}
              name={`${getValue()?.firstName} ${getValue()?.lastName}`}
            />
            <h5 className="mb-0">{`${getValue()?.firstName} ${getValue()?.lastName}`}</h5>
          </div>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => (
          <Fragment>
            <DotBadge
              bg={getStatusColor(getValue())}
            ></DotBadge>
            {getValue()}
          </Fragment>
        ),
      },
      //   {
      //     header: "Action",
      //     accessorKey: "",
      //     cell: ({ getValue }) =>
      //       getValue() === 1 ? (
      //         <Button href="#" variant="secondary" className="btn-sm">
      //           Change Status
      //         </Button>
      //       ) : (
      //         <Fragment>
      //           <Button
      //             href="#"
      //             variant="outline"
      //             className="btn-outline-secondary btn-sm"
      //           >
      //             Reject
      //           </Button>{" "}
      //           <Button href="#" variant="success" className="btn-sm">
      //             Approved
      //           </Button>
      //         </Fragment>
      //       ),
      //   },
      {
        accessorKey: "courseStartDate",
        header: "Start Date",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },
      {
        accessorKey: "courseEndDate",
        header: "End Date",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        cell: ({ getValue }) => {
          return formatDate(getValue());
        },
      },
      {
        accessorKey: "shortcutmenu",
        header: "",
        cell: ({ row }) => {
          return <ActionMenu course={row.original} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data = useMemo(() => courses_data, [courses_data]);

  return (
    <TanstackTable
      data={data}
      columns={columns}
      filter={true}
      filterPlaceholder="Search Course"
      pagination={true}
    />
  );
};

export default CoursesTable;
