import PropTypes from 'prop-types';
// import { m } from 'framer-motion';
// @mui
import { Container,  } from 'react-bootstrap';
// hooks
import useAuth from '../hooks/useAuth';
// components
// import { MotionContainer, varBounce } from '../components/animate';
// assets
// import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  // Logic here to get current user role
  const { user } = useAuth();

  // const currentRole = 'user';
  const currentRole = user?.role?.name?.toLowerCase(); // admin;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container fluid="md" className={'text-center'}>
          <h3  paragraph>
            Permission Denied
          </h3>

          <p sx={{ color: 'text.secondary' }}>You do not have permission to access this page</p>

          {/* <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}
      </Container>
    ) : null;
  }

  return <>{children}</>;
}