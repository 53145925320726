// import node module libraries
import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Image,
  Card,
  Row,
  Col,
  // ProgressBar,
  ListGroup,
  Badge,
} from "react-bootstrap";

// import custom components
import Ratings from "components/marketing/common/ratings/Ratings";
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";
// import GKTippy from "components/elements/tooltips/GKTippy";

// import utility file
import { numberWithCommas, formatDate, getTotalRating } from "helper/utils";
// import { Avatar } from "components/elements/bootstrap/Avatar";

const CourseCard = ({
  course,
  free,
  viewby,
  showprogressbar,
  extraclass,
  link,
}) => {
  /** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */
  const _reviews = course?.coursereviewsSet?.edges?.map((item) => item.node);

  const totalRating = _reviews?.length !== 0 ? getTotalRating(_reviews) : 0;

  const introImage = course?.introImage ?  course?.introImage[0] : '';

  const GridView = () => {
    return (
      <Card className={`mb-4 card-hover ${extraclass}`}>
        <Link to={link} className="" >
          <Image
            src={
              introImage
            }
            alt=""
            className="card-img-top rounded-top-md"
          />
        </Link>
        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2 ">
            <Link to={link} className="text-inherit">
              {course?.title}
            </Link>
          </h3>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <i className="far fa-calendar me-1"></i>
              {`${formatDate(course?.courseStartDate)} - ${formatDate(course?.courseEndDate)}` }
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <LevelIcon level={course?.dificultyLevel} />
              {course?.dificultyLevel}
            </ListGroup.Item>
          </ListGroup>
          <div
            className={`lh-1 d-flex align-items-center ${
              course?.package?.isFree ||
              course?.price === undefined ||
              course?.price <= 0
                ? "mb-5"
                : ""
            }`}
          >
            <span className="text-warning me-1 mb-1">
              <Ratings rating={totalRating} size="0.92rem" />
            </span>
            <span className="text-warning me-1"> {totalRating}</span>
            <span className="fs-6 text-muted">
              {" "}
              ({numberWithCommas(_reviews?.length)})
            </span>
          </div>
          <div
            className={`lh-1 mt-3 ${
              course?.package?.isFree ||
              course?.price === undefined ||
              course?.price <= 0
                ? // discount === undefined
                  "d-none"
                : ""
            }`}
          >
            <span className="text-dark fw-bold">Kes {numberWithCommas(course?.price)}</span>{" "}
            {/* <del className="fs-6 text-muted">Kes {numberWithCommas(course?.price)}</del> */}
          </div>
        </Card.Body>
        {/* Card Footer */}
        {/* <Card.Footer>
          <Row className="align-items-center g-0">
            <Col xs="auto">
              <Avatar
                size="md"
                className="rounded-circle"
                type={course?.creator?.image ? "image" : "initial"}
                src={course?.creator?.image}
                // status={course?.creator?.status.toLowerCase()}
                alt={course?.creator?.name}
                name={`${course?.creator?.firstName} ${course?.creator?.lastName}`}
              />
            </Col>
            <Col className="col ms-2">
              <span>{course?.creator?.firstName}</span>
            </Col>
            <Col xs="auto">
              <GKTippy content="Add to Bookmarks">
                <Link to="#">
                  <i className="fe fe-bookmark"></i>
                </Link>
              </GKTippy>
            </Col>
          </Row>
          <span className={`${showprogressbar ? "" : "d-none"}`}>
            {" "}
            <ProgressBar
              variant="success"
              now={course?.progress}
              className="mt-3"
              style={{ height: "5px" }}
            />
          </span>
        </Card.Footer> */}
      </Card>
    );
  };

  /** Used in Course Filter Page  */
  const ListView = () => {
    return (
      <Card className="mb-4 card-hover">
        <Row className="g-0">
          <Link
            to={link}
            className="bg-cover img-left-rounded col-12 col-md-12 col-xl-3 col-lg-3 "
            style={{
              background: `url(${introImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
          >
            <Image
              src={
                introImage 
              }
              alt="..."
              className="img-fluid d-lg-none invisible"
            />
          </Link>
          <Col lg={9} md={12} sm={12}>
            {/* <!-- Card body --> */}
            <Card.Body>
              <h3 className="mb-2 text-truncate-line-3">
                <Link to={link} className="text-inherit">
                  {course?.title}
                </Link>
              </h3>
              {/* <!-- List inline --> */}
              <ListGroup as="ul" bsPrefix="list-inline" className="mb-5">
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <i className="far fa-clock me-1"></i>
                  {course?.duration || "2hrs"}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <LevelIcon level={course?.dificultyLevel} />
                  {course?.dificultyLevel}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                  <span className="text-warning">
                    {" "}
                    <Ratings rating={totalRating} />{" "}
                    {totalRating}
                  </span>
                  <span className="fs-6 text-muted">
                    {" "}
                    ({numberWithCommas(_reviews?.length)})
                  </span>
                </ListGroup.Item>
              </ListGroup>
              {/* <!-- Row --> */}
              {/* <Row className="align-items-center g-0">
                <Col xs="auto">
                  <Image
                    src={
                      course?.creator?.image ||
                      "https://xsgames.co/randomusers/avatar.php?g=male"
                    }
                    className="rounded-circle avatar-xs"
                    alt=""
                  />
                </Col>
                <Col className="col ms-2">
                  <span>{course?.creator?.firstName}</span>
                </Col>
                <Col xs="auto">
                  <GKTippy content="Add to Bookmarks">
                    <Link to="#">
                      <i className="fe fe-bookmark"></i>
                    </Link>
                  </GKTippy>
                </Col>
              </Row> */}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  };

  /** Used in Instructor Profile Page  */
  const ListGroupView = () => {
    return (
      <div className="d-lg-flex align-items-center">
        <div>
          <Image
            src={
              course?.introImage[0] 
            }
            alt=""
            className="rounded img-4by3-lg"
          />
        </div>
        <div className="ms-lg-3 mt-2 mt-lg-0">
          <h4 className="text-primary-hover">
            {course?.title}{" "}
            <Badge bg="light-success" className="text-success">
              New
            </Badge>
          </h4>
          <ListGroup
            as="ul"
            bsPrefix="list-inline"
            className="fs-6 mb-0 text-inherit"
          >
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <i className="far fa-clock me-1"></i>
              {course?.duration}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <LevelIcon level={course?.dificultyLevel} />
              {course?.dificultyLevel}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <span className="text-warning">
                {" "}
                <Ratings rating={totalRating} />{" "}
                {totalRating}
              </span>
              <span className="fs-6 text-muted">
                {" "}
                ({numberWithCommas(_reviews?.length)})
              </span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      {viewby === "grid" ? (
        <GridView />
      ) : viewby === "list" ? (
        <ListView />
      ) : (
        <ListGroupView />
      )}
    </Fragment>
  );
};

// Specifies the default values for props
CourseCard.defaultProps = {
  free: false,
  viewby: "grid",
  showprogressbar: false,
  extraclass: "",
  link: "#",
};

// Typechecking With PropTypes
CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
  link: PropTypes.string,
};

export default CourseCard;
