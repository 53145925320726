// import node module libraries
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from 'helper/graphql/mutations';
import { Notify } from 'notiflix';
import { PATH_AUTH } from 'routes/Paths';

const ForgetPassword = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState();

	const [sendPasswordReset, {loading}] = useMutation(FORGOT_PASSWORD);

	const handleForgotPass = (email) => {
		sendPasswordReset({
			variables: {email: email},
			onCompleted: () => {
				Notify.success('Password reset request sent!')
				navigate(PATH_AUTH.resetPassword)
			},
			onError: () => {
				Notify.failure('Failed to reset password!')
			}
		})

	}

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo}  height={60} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Forgot Password</h1>
								<span>Fill the form to reset your password.</span>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											value={email}											
											placeholder="Enter your email"
											required
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
									
									<Col lg={12} md={12} className="mb-3 d-grid gap-2">
										<Button variant="primary" type="submit" onClick={() => handleForgotPass(email)} disabled={loading}>
											Send Reset Link
										</Button>
									</Col>
								</Row>
								<span>
									Return to <Link to="/authentication/sign-in">Sign in</Link>
								</span>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ForgetPassword;
