// import node module libraries
import React, { useRef, useEffect } from 'react';
import SimpleBar from 'simplebar-react';

// import sub custom components
import ChatHeader from './ChatHeader';
import Message from './Message';
import ChatFooter from './ChatFooter';

// import context file
// import { ChatContext } from 'context/Context';

// import hook file
// import useChatOperations from 'hooks/useChatOperations';
import useAuth from 'hooks/useAuth';

const ChatBox = (props) => {
	const { hideChatBox, setHideChatBox, messages, chatInfo } = props;

	const {user} = useAuth();
	// const {
	// 	ChatState: { activeThread }
	// } = useContext(ChatContext);

	// const { getThreadMessages } = useChatOperations();

	// const thread = getThreadMessages(activeThread.messagesId);

	// Auto scroll to bottom when the new chat has been added.
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest'
		});
	};

	useEffect(scrollToBottom);

	return (
		<div
			className={`chat-body w-100 ${
				hideChatBox ? 'chat-body-visible' : ''
			}`}
			style={{height: '50vh'}}
		>
			<ChatHeader hideChatBox={hideChatBox} setHideChatBox={setHideChatBox} currentUser={user} activeChat={chatInfo} />
			<SimpleBar className="" style={{ maxHeight: '40vh', height:'35vh' }}>
				<div className="px-4 py-4 h-100 messages-container">
					{/* {thread === 0
						? null
						: thread.chatMessages.map((item, index) => {
								return <Message chatScript={item} key={index} />;
						  })} */}
					{messages.map((item, index) => (
						<Message chatScript={item} key={index} currentUser={user} />
					))}
				</div>
				<div ref={messagesEndRef} />
			</SimpleBar>
			<ChatFooter currUser={user} chatInfo={chatInfo} />
		</div>
	);
};
export default ChatBox;
