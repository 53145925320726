// import node module libraries
import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { gql, useMutation } from "@apollo/client";

// import { Link } from 'react-router-dom';

import {
  Col,
  Row,
  // Image,
  ProgressBar,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

// import custom components
import Ratings from "components/marketing/common/ratings/Ratings";
// import FormProvider from 'components/general/hook-form/FormProvider';

import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Notify } from "notiflix";
import { Avatar } from "components/elements/bootstrap/Avatar";

// ----------------------------------------------------------------------

ReviewsTab.propTypes = {
  reviews: PropTypes.array,
};

const CREATE_REVIEW = gql`
  mutation CreateCourseReview(
    $courseId: String!
    $description: String!
    $userId: String!
    $rating: String
  ) {
    createCourseReview(
      courseId: $courseId
      description: $description
      userId: $userId
      rating: $rating
    ) {
      courseReviews {
        description
        rating
        user {
          firstName
          guid
          email
          lastName
        }
        id
      }
    }
  }
`;

// ----------------------------------------------------------------------

const getTotalRating = (reviews) => {
  let _total = 0;
  reviews.forEach((item) => {
    if (item.rating) {
      _total += parseFloat(item.rating);
    }
  });

  return (_total / reviews.length).toFixed(1);
};

// ----------------------------------------------------------------------

export default function ReviewsTab({
  reviews,
  course,
  user,
  isAuthenticated,
  updateReviews,
}) {
  const [showAddDialog, setShow] = useState(false);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");

  const totalReview = getTotalRating(reviews);

  const [onSaveReview, { loading: isSavingReview }] =
    useMutation(CREATE_REVIEW);

  const handleReviewAdd = () => {
    const payload = {
      description: description,
      rating: `${rating}`,
      userId: user?.guid,
      courseId: course?.guid,
    };

    onSaveReview({
      variables: payload,
      onCompleted: (data) => {
				Notify.success("Review added successfully!")
        setShow(false);
        updateReviews(data.createCourseReview.courseReviews);
      },
      onError: (err) => {
        Notify.failure("Failed to add review!")
      }
    });
  };

  return (
    <Fragment>
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-4">How students rated this courses</h3>
          {isAuthenticated && (
            <Button size="small" onClick={() => setShow(true)}>
              Add Review
            </Button>
          )}
        </div>
        <Row className="align-items-center">
          <Col xs="auto" className="text-center">
            <h3 className="display-2 fw-bold">{!isNaN(totalReview) ? totalReview : 0}</h3>
            <span className="text-warning">
              <Ratings rating={totalReview} />
            </span>
            <p className="mb-0 fs-6">{`Based on ${reviews?.length} reviews`}</p>
          </Col>
          {/* Progress bar */}
          <Col className="pt-3 order-3 order-md-2">
            <ProgressBar
              variant="warning"
              now={0}
              className="mb-3"
              style={{ height: "6px" }}
            />
            <ProgressBar
              variant="warning"
              now={0}
              className="mb-3"
              style={{ height: "6px" }}
            />
            <ProgressBar
              variant="warning"
              now={0}
              className="mb-3"
              style={{ height: "6px" }}
            />
            <ProgressBar
              variant="warning"
              now={0}
              className="mb-3"
              style={{ height: "6px" }}
            />
            <ProgressBar
              variant="warning"
              now={0}
              className="mb-3"
              style={{ height: "6px" }}
            />
          </Col>
          <Col xs={6} md="auto" className="order-2 order-md-3">
            {/* Rating */}
            <div>
              <span className="text-warning">
                <Ratings rating={5} />
              </span>
              <span className="ms-4">0%</span>
            </div>
            <div>
              <span className="text-warning">
                <Ratings rating={4} />
              </span>
              <span className="ms-4">0%</span>
            </div>
            <div>
              <span className="text-warning">
                <Ratings rating={3} />
              </span>
              <span className="ms-4">0%</span>
            </div>
            <div>
              <span className="text-warning">
                <Ratings rating={2} />
              </span>
              <span className="ms-4">0%</span>
            </div>
            <div>
              <span className="text-warning">
                <Ratings rating={1} />
              </span>
              <span className="ms-4">0%</span>
            </div>
          </Col>
        </Row>
      </div>
      {/* hr */}
      <hr className="my-5" />
      <div className="mb-3">
        <div className="d-lg-flex align-items-center justify-content-between mb-5">
          {/* Reviews */}
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Reviews</h3>
          </div>
          <div>
            {/* Form */}
            <Form className="form-inline">
              <Form.Group
                className="d-flex align-items-center me-2"
                controlId="formBasicEmail"
              >
                <span className="position-absolute ps-3">
                  <i className="fe fe-search"></i>
                </span>
                <Form.Control
                  type="search"
                  placeholder="Search Review"
                  className=" ps-6"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        {/* Rating */}
        {reviews.map((item, index) => (
          <div className="d-flex border-bottom pb-4 mb-4" key={index}>
            {/* <Image
              src={
                item?.image ||
                "https://xsgames.co/randomusers/avatar.php?g=male"
              }
              alt=""
              className="rounded-circle avatar-lg"
            /> */}
            <Avatar
              size="sm"
              className="rounded-circle me-2 mr-3s"
              type="initial"
              src={item?.user?.image}
              alt={item?.user?.firstName}
              name={`${item?.user?.firstName} ${item?.user?.lastName}`}
            />
            <div className=" ms-3">
              <h4 className="mb-1">
                {`${item?.user?.firstName} ${item?.user?.lastName}`}
                <span className="ms-1 fs-6 text-muted">{item.postedon}</span>
              </h4>
              <div className="fs-6 mb-2 text-warning">
                <Ratings rating={item?.rating || 0} />
              </div>
              <div
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
              {/* <div className="d-lg-flex">
								<p className="mb-0">Was this review helpful?</p>
								<Link to="#" className="btn btn-xs btn-primary ms-lg-3">
									Yes
								</Link>
								<Link to="#" className="btn btn-xs btn-outline-secondary ms-1">
									No
								</Link>
							</div> */}
            </div>
          </div>
        ))}
      </div>

      <div>
        <Modal
          show={showAddDialog}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
          //   size="xl"
        >
          <Modal.Header closeButton>
            <div className="">
              <h1 className="mb-1 fw-bold">Add a Review</h1>
            </div>
            {/* <Modal.Title>Survey Title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} md={12} xs={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Review</Form.Label>
                  <Form.Control
                    type="textarea"
                    id="description"
                    placeholder="Review"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={12} md={12} xs={12} className="mb-3">
                <Rating
                  id="rating"
                  items={5}
                  value={rating}
                  onChange={setRating}
                  style={{ maxWidth: 250 }}
                />
              </Col>

              <Col lg={4} md={4} className="mb-0 d-grid gap-2">
                {/* Button */}
                <Button
                  variant="dark-primary"
                  onClick={handleReviewAdd}
                  disabled={isSavingReview}
                >
                  {isSavingReview ? "Loading…" : "Save Review"}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
}
