import { useMutation, useQuery } from "@apollo/client";
import SurveyViewer from "components/elements/survey-viewer/SurveyViewer";
import { CREATE_QUIZ_ANSWER } from "helper/graphql/mutations";
import { GET_MODULE_BY_ID } from "helper/graphql/queries";

import { Notify } from "notiflix";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getUserGUID } from "utils/jwt";


export default function MobileQuiz() {
    const { guid, course } = useParams();

    const userGuid = getUserGUID(localStorage.getItem('accessToken'));

    const [currQUiz, setQuiz] = useState(null);

    useQuery(GET_MODULE_BY_ID, {
        variables: { guid: course },
        onCompleted: (data) => {
            const _module = data?.allCourseModules?.edges[0]?.node;

            const _quizes = _module?.modulecontentquizSet?.edges?.map(
                (item) => item.node
            );

           setQuiz(_quizes?.find(quiz=> quiz?.guid === guid));
        }
    })


    const [onCreateQuizAnswer] = useMutation(CREATE_QUIZ_ANSWER);


    const handleQuizComplete = (quizGUID, data) => {
        const payload = {
            "quizQuestionId": quizGUID,
            "userId": userGuid,
            "isInstructorAnswer": false,
            "answerData": JSON.stringify(data)
        };

        onCreateQuizAnswer({
            variables: payload,
            onCompleted: () => {
                Notify.success('Quiz saved successfully!');
                window.location.reload()
            },
            onError: () => Notify.failure('Failed to save quiz!'),
        })
    }

    return (
        <>
            <main>
                <section className="mt-6 course-container" style={{ background: '' }}>
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <div className="p-2 h-100">
                                    
                                    <div
                                        className="embed-responsive position-relative w-100 d-block overflow-hidden p-0"
                                        style={{ height: "90%" }}
                                    >
                                        {currQUiz?.questionData && (
                                            <div className="h-100" >
                                                <SurveyViewer
                                                    questions={
                                                        currQUiz?.questionData
                                                    }
                                                    onComplete={(data) => handleQuizComplete(currQUiz?.guid, data)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
}