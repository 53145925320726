// import node module libraries
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
// import sub components
import CourseCard from "./CourseCard";
import { PATH_DASHBOARD } from "routes/Paths";

// import data files
// import { AllCoursesData } from "data/slider/AllCoursesData";



// ----------------------------------------------------------------------

const CourseSlider = ({ recommended, popular, trending, category, courses }) => {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      {courses?.length === 0 ?
        <div className="mx-3">
            <p className="">No courses found</p>
        </div>
        :
        <div>
          {courses?.length < 2 ?
            <div className="d-flex">
              {courses.map((item, index) => (
                <div className="item " style={{ maxWidth: 320 }} key={item.id}>
                  <CourseCard key={index} course={item} link={PATH_DASHBOARD.courses.view(item.guid)} extraclass="mx-2" />
                </div>
              ))}
            </div>
            :
            <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
              {courses.map((item, index) => (
                <div className="item px-md-1" key={item.id}>
                  <CourseCard key={index} course={item} link={PATH_DASHBOARD.courses.view(item.guid)} extraclass="mx-2" />
                </div>
              ))}
            </Slider>
          }
        </div>
      }
    </Fragment>
  );
};

// Specifies the default values for props
CourseSlider.defaultProps = {
  recommended: false,
  popular: false,
  trending: false,
  category: null,
  courses: [],
};

// Typechecking With PropTypes
CourseSlider.propTypes = {
  recommended: PropTypes.bool,
  popular: PropTypes.bool,
  trending: PropTypes.bool,
  category: PropTypes.string,
  courses: PropTypes.array,
};

export default CourseSlider;
