// import node module libraries
import React, { useState } from 'react';
import {gql, useQuery} from '@apollo/client';

import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import data files
// import InvoiceData from 'data/marketing/InvoiceData';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';
import moment from 'moment';
import { numberWithCommas, truncateTextByCount } from 'helper/utils';

// ----------------------------------------------------------------------
const GET_USER_SUBS = gql`
query AllPackagePayments($userIdGuid: UUID) {
	allPackagePayments(userId_Guid: $userIdGuid) {
	  edges {
		node {
		  guid
		  id
		  nextPaymentDate
		  course {
			guid
			title
			price
		  }
		  package {
			guid
			name
			description
			createdAt
			isFree
			price
		  }
		}
	  }
	}
  }
`;
// ----------------------------------------------------------------------

const Invoice = () => {
	const user = JSON.parse(localStorage.getItem('user'));

	const [invoices, setInvoices] = useState([]);

	useQuery(
		GET_USER_SUBS,
		{
			variables: {userIdGuid: user?.guid },
			onCompleted: (data) => {
				setInvoices(data?.allPackagePayments?.edges?.map(item => item.node))
			}
		}
	);
	
	return (
		<ProfileLayout>
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Invoices</h3>
						<p className="mb-0">You can find all of your order Invoices here.</p>
					</div>
				</Card.Header>
				<Card.Body className="p-0">
					{/* Table */}
					<div className="table-invoice border-0">
						<Table
							hover
							responsive
							className="mb-0 text-nowrap table-centered "
						>
							<thead className="table-light">
								<tr>
									<th scope="col">Order ID</th>
									<th scope="col">Date</th>
									<th scope="col">Amount</th>
									<th scope="col">Course</th>
									{/* <th scope="col"></th> */}
								</tr>
							</thead>
							<tbody>
								{invoices.map((item, index) => (
									<tr key={index}>
										<td>
											<Link to="/marketing/student/student-invoice-details/">
												#{item?.id?.slice(-6)}
											</Link>
										</td>
										<td>{moment(item?.package?.createdAt).format('DD MMM yyyy')}</td>
										<td>
											{item?.course?.price !== 0 ? 
												`KES ${numberWithCommas(item?.course?.price)}`
												:
												'FREE'
											}
											
										</td>
										<td className='text-wrap' >
											{/* <Badge bg={item.status === 'Due' ? 'danger' : 'success'}>
												
											</Badge> */}
											{truncateTextByCount(item?.course?.title, 14)}
										</td>
										{/* <td>
											<Link
												to={`assets/images/pdf/${item.pdf}`}
												target="_blank"
												className="fe fe-download"
												download
											/>
										</td> */}
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</Card.Body>
			</Card>
		</ProfileLayout>
	);
};

export default Invoice;
