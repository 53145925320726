// import node module libraries
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, InputGroup } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'helper/graphql/mutations';
import { Notify } from 'notiflix';
import { PATH_AUTH } from 'routes/Paths';

const ResetPassword = () => {
	const navigate = useNavigate();

	const [showPass, setShowPass] = useState(false);

	const [token, setToken] = useState('');
	const [password, setPass] = useState('');
	const [email, setEmail] = useState();


	const [updatePass, { loading }] = useMutation(RESET_PASSWORD);

	const handleForgotPass = () => {
		updatePass({
			variables: {
				email: email,
				token: token,
				password: password
			},
			onCompleted: () => {
				Notify.success('Password reset request sent!')
				navigate(PATH_AUTH.login)
			},
			onError: () => {
				Notify.failure('Failed to reset password!')
			}
		})

	};

	const showPassHandler = () => setShowPass(prev => !prev)

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} height={60} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Reset Password</h1>
								<span>Fill the form to reset your password.</span>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										<Form.Label>Reset Token</Form.Label>
										<Form.Control
											type="token"
											id="token"
											value={token}
											placeholder="Enter token"
											required
											onChange={(e) => setToken(e.target.value)}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											value={email}											
											placeholder="Enter your email"
											required
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										<Form.Label>Password </Form.Label>
										<InputGroup>
											<Form.Control
												type={showPass ? "text" : "password"}
												value={password}
												onChange={(e) => setPass(e.target.value)}
												id="password"
												placeholder="**************"
												required
											/>
											<InputGroup.Text>
												<i onClick={showPassHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
											</InputGroup.Text>
										</InputGroup>
									</Col>
									
									<Col lg={12} md={12} className="mb-3 d-grid gap-2">
										<Button variant="primary" type="submit" onClick={() => handleForgotPass()} disabled={loading}>
											Reset Password
										</Button>
									</Col>
								</Row>
								{/* <span>
									Return to <Link to="/authentication/sign-in">Sign in</Link>
								</span> */}
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ResetPassword;
