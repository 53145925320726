import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { PlyrLayout, plyrLayoutIcons } from '@vidstack/react/player/layouts/plyr';

import '@vidstack/react/player/styles/base.css';
import '@vidstack/react/player/styles/plyr/theme.css';

export default function VideoPlayer({title, video}){

    return(
        <MediaPlayer title={title} src={video} playsInline>
            <MediaProvider />
            <PlyrLayout 
                // thumbnails={}
                icons={plyrLayoutIcons}
            />
        </MediaPlayer>
    )
}