import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Col, Row, Form } from "react-bootstrap";
import { useState } from "react";
import { PATH_DASHBOARD } from "routes/Paths";
import { CREATE_PATIENT_FORM } from "helper/graphql/mutations";
import { Notify } from "notiflix";
import useAuth from "hooks/useAuth";
import SurveyViewer from "components/elements/survey-viewer/SurveyViewer";
import { PATIENT_FORMS } from "utils/PatientForms";

const PatientDataForm = ({ currentPatient }) => {
    const navigate = useNavigate();

    const { user } = useAuth();

    const [form, setForm] = useState(null);

    const [formData, setFormData] = useState({
        formType: null,
        formData: null,
        patientId: currentPatient?.guid,
        healthWorkerId: user?.guid,
    });

    const [onCreatePatientForm] =
        useMutation(CREATE_PATIENT_FORM);

    // const handleChange = (e) => {
    //     const { name, value, type } = e.target;

    //     if (type === "file") {
    //         setFormData((prevState) => ({
    //             ...prevState,
    //             [name]: e.target.files[0],
    //         }));
    //     } else {
    //         setFormData((prevState) => ({
    //             ...prevState,
    //             [name]: value,
    //         }));
    //     }

    // };

    const handleSetForm = (val) => {
        setFormData((prevState) => ({
            ...prevState,
            formType: val,
        }));

        const _form = PATIENT_FORMS.find(form => form.name === val)
        setForm(_form.form)
    };

    const handleCompleteForm = (data) => {
        // console.log(data);
        const payload = {
            formType: formData.formType,
            formData: JSON.stringify(data),
            patientId: currentPatient?.guid,
            healthWorkerId: user?.guid,
        }

        onCreatePatientForm({
            variables: payload,
            onCompleted: (data) => {
                Notify.success("Patient form added successfully!")
                navigate(PATH_DASHBOARD.healthworker.patientForms(currentPatient?.guid))
            },
            onError: (e) => {
                Notify.failure("Failed to add patient form!")
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        onCreatePatientForm({
            variables: formData,
            onCompleted: (data) => {
                Notify.success("Patient form added successfully!")
                navigate(PATH_DASHBOARD.healthworker.patientForms(currentPatient?.guid))
            },
            onError: (e) => {
                Notify.failure("Failed to add patient form!")
            }
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>

                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Form Type</Form.Label>
                    <Form.Select
                        id="formType"
                        name="formType"
                        onChange={(e) => handleSetForm(e.target.value)}
                        required
                    >
                        <option>Select Form Type</option>
                        {PATIENT_FORMS.map((form, index) =>
                            <option key={index} value={form.name}>{form.name}</option>
                        )}
                    </Form.Select>
                </Col>


                <Col lg={12} md={12} className="mb-3">
                    <SurveyViewer name="" onComplete={handleCompleteForm} questions={form} />
                </Col>

                {/* <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    <Button variant="dark-primary" type="submit" disabled={isLoading}>
                        {isLoading ? "Loading…" : "Add Patient Form"}
                    </Button>
                </Col> */}
            </Row>
        </Form>
    );
};

export default PatientDataForm;