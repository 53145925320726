// import node module libraries
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormProvider from "components/general/hook-form/FormProvider";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  Alert,
  InputGroup,
} from "react-bootstrap";

// import media files
import Logo from "assets/images/brand/logo/logo.svg";

import { PATH_AUTH } from "routes/Paths";

import { countries } from "utils/UtilData";
import moment from "moment";
import { validatePassword } from "helper/utils";

// ----------------------------------------------------------------------

const REGISTER_USER = gql`
  mutation CreateUser(
    $country: String!
    $dateOfBirth: Date
    $email: String!
    $firstName: String!
    $gender: String!
    $isAdmin: Boolean!
    $isHealthcareWorker: Boolean!
    $isOrgAdmin: Boolean!
    $isPatient: Boolean!
    $lastName: String!
    $password: String!
    $phoneNumber: String!
    $state: String!
    $isFirstTimeLogin: Boolean
    $isPrivacyPolicyGiven: Boolean!
    $isConsentGiven: Boolean!
  ) {
    createUser(
      country: $country
      dateOfBirth: $dateOfBirth
      email: $email
      firstName: $firstName
      gender: $gender
      isAdmin: $isAdmin
      isHealthcareWorker: $isHealthcareWorker
      isOrgAdmin: $isOrgAdmin
      isPatient: $isPatient
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      state: $state
      isFirstTimeLogin: $isFirstTimeLogin
      isPrivacyPolicyGiven: $isPrivacyPolicyGiven
      isConsentGiven: $isConsentGiven
    ) {
      user {
        firstName
        gender
        guid
        id
        isAdmin
        role {
          id
          name
        }
      }
    }
  }
`;

// ----------------------------------------------------------------------

const SignUp = () => {
  const navigate = useNavigate();

  const [profession, setProfesssion] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [pass, setPass] = useState('');


  const [onSignIn, { loading: isLoading }] = useMutation(REGISTER_USER);

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // const CODES = countries.sort((a,b) => a.phone - b.phone);

  const defaultValues = {
    country: null,
    dateOfBirth: null,
    email: null,
    firstName: null,
    gender: null,
    isAdmin: false,
    isHealthcareWorker: false,
    isOrgAdmin: false,
    isPatient: false,
    lastName: null,
    password: null,
    phoneNumber: null,
    state: null,
    isConsentGiven: false,
    isPrivacyPolicyGiven: false,
    isFirstTimeLogin: true,
  };

  const methods = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleSignIn = (data) => {
    const payload = data;
    payload.phoneNumber = `${data.code}${data.phoneNumber}`;
    delete payload.code

    if (profession === "healthWorker") {
      payload.isHealthcareWorker = true;
    } else {
      payload.isPatient = true;
    }

    onSignIn({
      variables: payload,
      onCompleted: (data) => {
        navigate(PATH_AUTH.login);
      },
      onError: (err) => {
        setError("afterSubmit", { ...err, message: err.message });
      },
    });
  };

  const showPassHandler = () => setShowPass(prev => !prev);

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={8} md={10} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Link to="/">
                  <Image src={Logo} className="mb-4" height={40} alt="" />
                </Link>
                <h1 className="mb-1 fw-bold">Sign up</h1>
                <span>
                  Already have an account?{" "}
                  <Link to="/authentication/sign-in" className="ms-1">
                    Sign in
                  </Link>
                </span>
              </div>

              {!!errors.afterSubmit && (
                <div className="mb-4">
                  <Alert variant="danger">{errors.afterSubmit.message}</Alert>
                </div>
              )}
              {/* Form */}
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(handleSignIn)}
              >
                <Row>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      First Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="firstName"
                      placeholder="First Name"
                      {...register("firstName")}
                      required
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="lastName"
                      placeholder="Last Name"
                      {...register("lastName")}
                      required
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* email */}
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      {...register("email")}
                      placeholder="Email address here"
                      required
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      Country <span className="text-danger">*</span>
                    </Form.Label>
                    {/* <Form.Control
                      type="text"
                      id="country"
                      placeholder="Kenya"
                      {...register("country")}
                      required
                    /> */}
                    <Form.Select id="country" {...register("country")}>
                      <option>Select Country</option>
                      {countries.map((item) => (
                        <option key={item.code} value={item.label}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      State/County <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="state"
                      placeholder="State/City"
                      {...register("state")}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup >
                      <Form.Select id="code" {...register("code")} style={{ width: '35%' }}>
                        <option>Select Code</option>
                        {countries.map((item) => (
                          <option key={item.code} value={item.phone}>
                            {`(+${item.phone}) ${item.label}`}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control
                        type="text"
                        id="phoneNumber"
                        style={{ width: '65%' }}
                        placeholder=""
                        {...register("phoneNumber")}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      id="dob"
                      placeholder=""
                      max={moment().subtract(1, 'year').format('yyyy-MM-DD')}
                      {...register("dateOfBirth")}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      Gender <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select id="gender" {...register("gender")} required>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="prefer_not_to_say">
                        Prefer not to say
                      </option>
                    </Form.Select>
                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>
                      Profession <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      id="profession"
                      // {...register("profession")}
                      onChange={(e) => {
                        setProfesssion(e.target.value);
                      }}
                      required
                    >
                      <option>Select Profession </option>
                      <option value="healthWorker">
                        Medical/Health worker
                      </option>
                      <option value="other">Patient/Parent/Guardian</option>
                    </Form.Select>
                  </Col>

                  <Col lg={6} md={6} xs={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        id="pass"
                        //   {...register("password")}
                        value={pass}
                        placeholder="**************"
                        required
                        onChange={(e) => setPass(e.target.value)}
                        isInvalid={!validatePassword(pass)}
                        isValid={validatePassword(pass)}
                      />
                      
                      <InputGroup.Text>
                        <i onClick={showPassHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                      </InputGroup.Text>

                      <Form.Control.Feedback type="invalid">
                        Passwords should contain more than 8 characters!
                      </Form.Control.Feedback>
                    </InputGroup>

                  </Col>
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>
                      Confirm Password <span className="text-danger">*</span>
                    </Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        id="password"
                        {...register("password")}
                        placeholder="**************"
                        required
                        isInvalid={!(watch().password === pass)}
                        isValid={watch().password === pass}
                      />
                
                      <InputGroup.Text>
                        <i onClick={showPassHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        Password does not match
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>

                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <Form.Check type="checkbox" id="check-api-checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("isPrivacyPolicyGiven")}
                        required
                      />
                      <Form.Check.Label>
                        I agree to the
                        <Link to="/pages/terms-and-conditions">
                          Terms of Service{" "}
                        </Link>{" "}
                        and{" "}
                        <Link to="/pages/terms-and-conditions">
                          Privacy Policy.
                        </Link>
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <Form.Check type="checkbox" id="check-api-checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        {...register("isConsentGiven")}
                        required
                      />
                      <Form.Check.Label>
                        I hereby consent to collection, processing and storage
                        of my personal data by Hope Arthritis Foundation for the
                        purposes specified herein, in compliance with the Data
                        Protection Act, 2019.
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <Button
                      variant="dark-primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading…" : "Sign Up"}
                    </Button>
                  </Col>
                </Row>
              </FormProvider>
              {/* <hr className="my-4" />
              <div className="mt-4 text-center">
                
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-facebook"
                >
                  <i className="fab fa-facebook"></i>
                </Link>{" "}
                
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-twitter"
                >
                  <i className="fab fa-twitter"></i>
                </Link>{" "}
                
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-linkedin"
                >
                  <i className="fab fa-linkedin"></i>
                </Link>{" "}
               
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-github"
                >
                  <i className="fab fa-github"></i>
                </Link>
              </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignUp;
