// import node module libraries
import { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { Model } from "survey-core";

import { Survey } from "survey-react-ui";

import "survey-core/defaultV2.min.css";
import { themeJson } from "components/dashboard/authentication/surveys/SurveyDetails";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
SurveyViewer.propTypes = {
  name: PropTypes.string,
  onComplete: PropTypes.func,
  questions: PropTypes.object,
};
// ----------------------------------------------------------------------

export default function SurveyViewer({ name, onComplete, questions }) {
  const survey = new Model(questions);
  survey.applyTheme(themeJson);

  const handleComplete = useCallback((sender) => {
    onComplete(sender.data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  survey.onComplete.add(handleComplete);

  return (
    <Fragment>
      <Survey model={survey}  />
    </Fragment>
  );
}
