// import node module libraries
import React, { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Trash, MoreVertical } from "react-feather";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {
    Card,
    Row,
    Col,
    Dropdown,
    //   Image,
    // Badge,
    Table,
    Button,
    //   ListGroup,
    //   ProgressBar,
} from "react-bootstrap";

// import MDI icons
// import Icon from '@mdi/react';
// import { mdiStar } from '@mdi/js';

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
// import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

// import profile layout wrapper
import ProfileLayout from "../student/ProfileLayout";
import { formatDate } from "helper/utils";

// import data files
// import { MyCoursesData as data_ } from 'data/courses/MyCoursesData';

// import utility file
// import { numberWithCommas } from 'helper/utils';
// ----------------------------------------------------------------------
import { GET_PATIENT_FORMS, GET_USER_BY_ID } from "helper/graphql/queries";
import { PATH_DASHBOARD } from "routes/Paths";
import {
    DELETE_PATIENT_FORM,
} from "helper/graphql/mutations";
import { Notify } from "notiflix";
import AlertModal from "components/elements/modal-alert/AlertModal";
// import useAuth from "hooks/useAuth";
// ----------------------------------------------------------------------

const PatientDataForms = () => {
    const navigate = useNavigate();
    const {guid} = useParams();

    // const { user } = useAuth();
    const [filtering, setFiltering] = useState("");
    const [rowSelection, setRowSelection] = useState({});

    const [forms, setForms] = useState([]);
    const [currPatient, setPatient] = useState();

    useQuery(GET_PATIENT_FORMS, {
        variables: {"patientIdGuid": guid},
        onCompleted: (data) => {
            const _forms = data?.allPatientForms?.edges?.map((item) => item.node);
            setForms(_forms);
        },
    });

    useQuery(GET_USER_BY_ID, {
        variables: {"guid": guid},
        onCompleted: (data) => {
            const _patient = data?.allUsers?.edges?.map((user) => user.node);
            if(_patient?.length !== 0){
                setPatient(_patient[0])
            }
        }
    })

    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn-icon btn btn-ghost btn-sm rounded-circle"
        >
            {children}
        </Link>
    ));

    const ActionMenu = ({ user }) => {
        const [showDelete, setShowDelete] = useState(false);

        const [onDeleteForm] = useMutation(DELETE_PATIENT_FORM);

        const handleDelete = (guid) => {
            onDeleteForm({
                variables: { guid: guid },
                onCompleted: () => {
                    Notify.success("Patient form deleted successfully!");
                    setShowDelete(false);
                },
                onError: () => {
                    Notify.failure("Failed to delete patient form!");
                },
            });
        };

        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        <MoreVertical size="15px" className="text-secondary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                        <Dropdown.Header>SETTINGS</Dropdown.Header>

                        <Dropdown.Item eventKey="2" onClick={() => setShowDelete(true)}>
                            {" "}
                            <Trash size="15px" className="dropdown-item-icon" /> Delete
                        </Dropdown.Item>
                        
                    </Dropdown.Menu>
                </Dropdown>


                <AlertModal
                    isOpen={showDelete}
                    onClose={() => setShowDelete(false)}
                    handleYes={() => {
                        handleDelete(user?.guid);
                    }}
                    title={`Delete ${user?.first_name}!`}
                    message="Are you sure you want to delete this form?"
                />
            </>
        );
    };

    const sortby = [
        { value: "Date Created", label: "Date Created" },
        { value: "Newest", label: "Newest" },
        { value: "High Rated", label: "High Rated" },
        // { value: "Law Rated", label: "Law Rated" },
        // { value: "High Earned", label: "High Earned" },
    ];

    const columns = useMemo(
        () => [
            {
              accessorKey: "id",
              header: "#",
              cell: ({row, getValue}) => {
                return (
                  <Link href="#">
                      {getValue()}
                  </Link>
                )
              }
            },
            {
              accessorKey: "formType",
              header: "Form Type",
            },
            // {
            // 	accessorKey: 'duration',
            // 	header: 'duration'
            // },
            {
                accessorKey: "createdAt",
                header: "Created At",
                cell: ({ getValue }) => {
                    return formatDate(getValue());
                },
            },
            {
                accessorKey: "action",
                header: "",
                cell: ({ row }) => {
                    return <ActionMenu course={row.original} />;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(() => forms, [forms]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            globalFilter: filtering,
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setFiltering,
        debugTable: false,
        initialState: {
            columnVisibility: {
                title: false, // This will hide the column with the ID 'your-column-name'
                level: false,
                duration: false,
            },
        },
    });

    return (
        <ProfileLayout>
            <Card className="border-0">
                <Card.Header>
                    <Row>
                        <Col lg={9} md={7} sm={12}>
                            <div className="mb-3 mb-lg-0">
                                <h3 className="mb-0">{`${currPatient?.firstName} ${currPatient?.lastName}'s Forms`}</h3>
                                <p className="mb-0">
                                    Manage your patients forms.
                                </p>
                            </div>
                        </Col>

                        <Col lg={3} md={5} sm={12}>
                            <Button 
                                onClick={() => navigate(PATH_DASHBOARD.healthworker.patientFormAdd(currPatient?.guid))}
                            >
                                Add Patient Form
                            </Button>
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={9} md={7} sm={12} className="mb-lg-0 mb-2">
                            <GlobalFilter
                                filtering={filtering}
                                setFiltering={setFiltering}
                                placeholder="Search Your Forms"
                            />
                        </Col>
                        <Col lg={3} md={5} sm={12}>
                            <FormSelect options={sortby} placeholder="Sort by" />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className="p-0 pb-5">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Table hover responsive className="text-nowrap table-centered">
                                <thead>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => (
                                                <th key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows?.map((row) => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {/* Pagination @ Footer */}
                    <Pagination table={table} />
                </Card.Body>
            </Card>
        </ProfileLayout>
    );
};

export default PatientDataForms;
