// import node module libraries
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, Card } from 'react-bootstrap';

// import bootstrap icons
import InstructorForm from './forms/NewInstructorForm';
import { PATH_ADMIN } from 'routes/Paths';

const AddInstructor = () => {
	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Add Instructor</h1>
							<Breadcrumb>
                            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User</Breadcrumb.Item>
									<Breadcrumb.Item active>Instructor</Breadcrumb.Item>
									<Breadcrumb.Item active>Add</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Link to={PATH_ADMIN.admin.instructors} className="btn btn-primary">
								Back
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			{/* create project form  */}
			<div className="py-6">
				{/* row */}
				<Row>
					<Col xl={{ offset: 1, span: 10 }} md={12} xs={12}>
						<Card>
							{/* card body */}
							<Card.Body className="p-lg-6">
								<InstructorForm />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default AddInstructor;
