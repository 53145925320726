// import node module libraries
import React, { useMemo } from "react";
import { ProgressBar } from "react-bootstrap";

// import custom components
import TanstackTable from "components/elements/advance-table/TanstackTable";
import { formatDate } from "helper/utils";
import { Avatar } from "components/elements/bootstrap/Avatar";


const CourseProgressTable = ({ course_progress_data }) => {

    const columns = useMemo(
        () => [

            {
                header: "User",
                // accessorKey: "user",
                accessorFn: row => `${row.user.firstName} ${row.user.lastName}`,
                cell: ({ row, getValue }) => (
                    <div className="d-flex align-items-center gap-1">
                        {/* <Image
						src={row.}
						alt=""
						className="rounded-circle avatar-xs me-2"
					/> */}
                        <Avatar
                            size="sm"
                            className="rounded-circle me-2 mr-3"
                            type="initial"
                            alt={getValue()}
                            name={getValue()}
                        />
                        {/* <h5 className="mb-0">{`${getValue()?.firstName} ${getValue()?.lastName}`}</h5> */}
                        <h5 className="mb-0">{getValue()}</h5>
                    </div>
                ),
            },
            {
                accessorKey: "courseProgress",
                header: "Course Progress",
                cell: ({ getValue }) => (
                    // <div className="me-6 my-3">
                    <div className="me-6 my-3">
                        <ProgressBar
                            variant="info"
                            className="mb-2 progress"
                            now={getValue() || 0}
                            style={{ height: "6px" }}
                        />
                        <small>{getValue() ? parseFloat(getValue())?.toFixed(2) : 0}% Completed</small>
                    </div>
                )
            },
            {
                accessorKey: "createdAt",
                header: "Created At",
                cell: ({ getValue }) => {
                    return formatDate(getValue());
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(() => course_progress_data, [course_progress_data]);

    return (
        <TanstackTable
            data={data}
            columns={columns}
            filter={true}
            filterPlaceholder="Search User"
            pagination={true}
        />
    );
};

export default CourseProgressTable;
