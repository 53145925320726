import { Worker, Viewer } from "@react-pdf-viewer/core";

import '@react-pdf-viewer/core/lib/styles/index.css';


const PdfViewer = ({ doc }) => {
    console.log("doc", doc)

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '100%',
            }}
        >
            <Viewer fileUrl={doc || "https://app.hopearthritisfoundation.com/file.pdf" } />

        </div>

        </Worker>
    );
}

export default PdfViewer