import { 
    ApolloClient,
    InMemoryCache,
    // createHttpLink,
    ApolloLink,
    // HttpLink,
    split,
  
} from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

// import {setContext} from "@apollo/client/link/context";
// import {store} from '../Store/store';

// const state = store.getState();

const wslink = new GraphQLWsLink(
  createClient({ url: `${process.env.REACT_APP_SOCKET_API}` })
);

// const httpLink = new HttpLink({uri: process.env.REACT_APP_HOST_API});
const uploadLink = new createUploadLink({uri: process.env.REACT_APP_HOST_API})

const authLink = new ApolloLink((operation, forward) => {
//   const state = store.getState();
//   const token = state.auth.token;
  const token = localStorage.getItem('accessToken');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}`:``,
      'Apollo-Require-Preflight': 'true'
    }
  })

  return forward(operation);
})

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wslink,
  authLink.concat(uploadLink),
);

export const apolloClient  = new ApolloClient ({
  link: splitLink,
  cache: new InMemoryCache(),
});