import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Col, Row, Form, Button } from "react-bootstrap";
import { useState } from "react";
import {  PATH_DASHBOARD } from "routes/Paths";
import { CREATE_PATIENT, UPDATE_USER } from "helper/graphql/mutations";
import { Notify } from "notiflix";
import { GET_PATIENTS } from "helper/graphql/queries";
import useAuth from "hooks/useAuth";
import moment from "moment";

const PatientForm = ({ currentPatient, isEdit }) => {
    const navigate = useNavigate();
    const {user} = useAuth();

    const [formData, setFormData] = useState({
        country: currentPatient?.country || null,
        dateOfBirth: currentPatient?.dateOfBirth || null,
        email: currentPatient?.email || null,
        firstName: currentPatient?.firstName || null,
        gender: currentPatient?.gender || null,
        isAdmin: currentPatient?.isAdmin || false,
        isConsentGiven: currentPatient?.isConsentGiven || true,
        isHealthcareWorker: currentPatient?.isHealthcareWorker || false,
        isOrgAdmin: currentPatient?.isOrgAdmin || false,
        isPatient: currentPatient?.isPatient || true,
        isPrivacyPolicyGiven: currentPatient?.isPrivacyPolicyGiven || true,
        isFirstTimeLogin: currentPatient?.isFirstTimeLogin || false,
        lastName: currentPatient?.lastName || null,
        password: currentPatient?.password || "hopeplatform",
        phoneNumber: currentPatient?.phoneNumber || null,
        state: currentPatient?.state || null,
        roleName: currentPatient?.roleName || "user",
    });

    const [onCreatePatient, { loading: isLoading }] =
        useMutation(CREATE_PATIENT);

    const [onUpdatePatient, { loading: isUpdating }] = useMutation(UPDATE_USER)

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        if (type === "file") {
            setFormData((prevState) => ({
                ...prevState,
                [name]: e.target.files[0],
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEdit) {
            setFormData((prevState) => ({
                ...prevState,
                guid: currentPatient?.guid,
            }));
            onUpdatePatient({
                variables: formData,
                awaitRefetchQueries: true,
                onCompleted: (data) => {
                    Notify.success("Patient updated successfully!")
                    navigate(PATH_DASHBOARD.healthworker.patients)
                },
                onError: (e) => {
                    Notify.failure("Failed to update patient!")
                },
                refetchQueries: [
                    {
                        query: GET_PATIENTS,
                        variables: {"isPatient": true, "createdBy": user?.guid}
                    }
                ]
            })
        } else {
            onCreatePatient({
                variables: formData,
                awaitRefetchQueries: true,
                onCompleted: (data) => {
                    Notify.success("Patient added successfully!")
                    navigate(PATH_DASHBOARD.healthworker.patients)                
                },
                onError: (e) => {
                    Notify.failure("Failed to create patient")
                },
                refetchQueries: [
                    {
                        query: GET_PATIENTS,
                        variables: {"isPatient": true, "createdBy": user?.guid}
                    }
                ]
            })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        required
                    />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        required
                    />
                </Col>
                <Col lg={12} md={12} className="mb-3">
                    {/* email */}
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email address here"
                        onChange={handleChange}
                        required
                    />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" id="dob" name="dateOfBirth" placeholder="" onChange={handleChange} required max={moment().subtract(1, 'year').format('yyyy-MM-DD')} />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" id="phoneNumber" name="phoneNumber" placeholder="" onChange={handleChange} required />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select id="gender" name="gender" onChange={handleChange} required>
                        <option>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </Form.Select>
                </Col>

                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" id="country" name="country" onChange={handleChange} placeholder="Kenya" required />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" id="state" name="state" onChange={handleChange} placeholder="State/County" required />
                </Col>
                {/* <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Password </Form.Label>
          <Form.Control
            type="password"
            id="pass"
            name="password"
            placeholder="**************"
            onChange={handleChange}
            required
          />
        </Col> */}

                <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <Button variant="dark-primary" type="submit" disabled={isLoading || isUpdating}>
                        {isLoading ? "Loading…" : "Add Patient"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default PatientForm;