// import node module libraries
import React, { } from 'react';
import { Link } from 'react-router-dom';

// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';
// import GKTippy from 'components/elements/tooltips/GKTippy';

// import context file
// import { ChatContext } from 'context/Context';

// import hook file
// import useChatOperations from 'hooks/useChatOperations';

const ChatHeader = (props) => {
	const { setHideChatBox, activeChat} = props;

	// const {
	// 	ChatState: { loggedInUserId, activeThread }
	// } = useContext(ChatContext);

	// const {  getUserDetailsById } = useChatOperations();

	// let activeChat =
	// 	activeThread.type === 'user'
	// 		? getUserDetailsById(activeThread.userId)
	// 		: getGroupDetails(activeThread);

	// let activeChat = {};

	return (
		<div className="bg-white border-top border-bottom px-4 py-3 sticky-top">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<Link
						to="#"
						className="me-2 d-xl-none d-block"
						onClick={() => setHideChatBox(false)}
					>
						<i className="fe fe-arrow-left"></i>
					</Link>
					
					<Avatar
						size="md"
						className="rounded-circle"
						type={activeChat?.image ? 'image' : 'initial'}
						src={activeChat?.image}
						status={activeChat?.status?.toLowerCase()}
						alt={activeChat?.name}
						name={activeChat?.name}
					/>
					
					<div className="ms-2">
						<h4 className="mb-0">{activeChat?.name}</h4>
						{/* <p className="mb-0 text-muted">
							<span
								className="d-inline-block text-truncate"
								style={{ maxWidth: hideChatBox ? '150px' : 'unset' }}
							>
								{activeThread.type === 'user' ? (
									ActiveChatInfo.status
								) : (
									<Fragment>
										{ActiveChatInfo.users
											.filter((user) => user.id !== loggedInUserId)
											.map((user) => user.name)
											.join(', ')}
										, You
									</Fragment>
								)}
							</span>
						</p> */}
					</div>
				</div>
				<div>
					{/* <Link
						to="#"
						className="me-3  text-link texttooltip"
						data-template="phone"
					>
						<GKTippy content="Voice Call">
							<i className="fe fe-phone-call fs-3"></i>
						</GKTippy>
					</Link>
					<Link
						to="#"
						className="me-3  text-link texttooltip"
						data-template="video"
					>
						<GKTippy content="Video Call">
							<i className="fe fe-video fs-3"></i>
						</GKTippy>
					</Link>
					<Link
						to="#"
						className="text-link texttooltip"
						data-template="adduser"
					>
						<GKTippy content="Add User">
							<i className="fe fe-user-plus fs-3"></i>
						</GKTippy>
					</Link> */}
				</div>
			</div>
		</div>
	);
};
export default ChatHeader;
