// import node module libraries
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Card, Form, Row, Col, Button } from "react-bootstrap";

// import custom components
// import { FormSelect } from 'components/elements/form-select/FormSelect';
// import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';

// import media files
// import Avatar3 from 'assets/images/avatar/avatar-3.jpg';

// import profile layout wrapper
import ProfileLayoutWrap from "./ProfileLayoutWrap";
import FormProvider from "components/general/hook-form/FormProvider";
import { Notify } from "notiflix";
import { Avatar } from "components/elements/bootstrap/Avatar";

// ----------------------------------------------------------------------

const UPDATE_USER_DETAILS = gql`
  mutation UpdateUser(
    $guid: String!
    $firstName: String
    $dateOfBirth: Date
    $city: String
    $country: String
    $lastName: String
    $phoneNumber: String
    $state: String
  ) {
    updateUser(
      guid: $guid
      firstName: $firstName
      dateOfBirth: $dateOfBirth
      city: $city
      country: $country
      lastName: $lastName
      phoneNumber: $phoneNumber
      state: $state
    ) {
      user {
        firstName
        guid
      }
    }
  }
`;

const GET_USER_PROFILE = gql`
  query AllProfiles($userGuid: UUID) {
    allProfiles(user_Guid: $userGuid) {
      edges {
        node {
          guid
          id
          occupation
          levelOfEducation
          isConsentGiven
        }
      }
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateProfile(
    $guid: String!
    $levelOfEducation: String
    $occupation: String
  ) {
    updateProfile(
      guid: $guid
      levelOfEducation: $levelOfEducation
      occupation: $occupation
    ) {
      profile {
        id
        guid
      }
    }
  }
`;

// ----------------------------------------------------------------------

const EditProfile = () => {
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));

  const [onEditUser, { loading: isLoading }] = useMutation(UPDATE_USER_DETAILS);
  const [onUpdateProfile, { loading: isUpdatingProfile }] =
    useMutation(UPDATE_USER_PROFILE);

  const [userProfile, setProfile] = useState(null);

  useQuery(GET_USER_PROFILE, {
    variables: { userGuid: user?.guid },
    onCompleted: (data) => {
      const _profile = data.allProfiles.edges[0].node;
      setProfile(_profile);
    },
  });

  const EditUSerSchema = Yup.object().shape({
    country: Yup.string(),
    dateOfBirth: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phoneNumber: Yup.string(),
    state: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      country: user?.country,
      dateOfBirth: user?.dateOfBirth,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      state: user?.state,
      occupation: userProfile?.occupation,
      levelOfEducation: userProfile?.levelOfEducation,
    }),

    [userProfile, user]
  );

  const methods = useForm({
    resolver: yupResolver(EditUSerSchema),
    defaultValues,
  });

  const {
    reset,
    // setError,
    register,
    handleSubmit,
    // formState: { errors },
  } = methods;

  useEffect(() => {
    if (userProfile) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const handleEdit = (data) => {
    // console.log('edit-user', data)
    onEditUser({
      variables: data,
      onCompleted: () => {
        Notify.success("Details updated successfully!");
      },
      onError: (err) => {
        Notify.failure("Failed to update details!");
        console.log(err);
      },
    });
  };

  const handleEditProfile = (data) => {
    const payload = {
      guid: userProfile.guid,
      occupation: data.occupation,
      levelOfEducation: data.levelOfEducation,
    };

    onUpdateProfile({
      variables: payload,
      onCompleted: () => {
        Notify.success("Details updated successfully!");
      },
      onError: (err) => {
        Notify.failure("Failed to update details!");
        console.log(err);
      },
    });
  };

  return (
    <ProfileLayoutWrap pathpara={location.pathname}>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Profile Details</h3>
            <p className="mb-0">
              You have full control to manage your own account setting.
            </p>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-4 mb-lg-0">
              {/* <Image
								src={Avatar3}
								id="img-uploaded"
								className="avatar-xl rounded-circle"
								alt=""
							/> */}
              <Avatar
                size="xl"
                className="rounded-circle"
                type={user?.image ? "image" : "initial"}
                src={user?.image}
                // status={user?.status.toLowerCase()}
                alt={user?.name}
                name={`${user?.firstName} ${user?.lastName}`}
              />
              <div className="ms-3">
                <h4 className="mb-0">Your avatar</h4>
                <p className="mb-0">
                  PNG or JPG no bigger than 800px wide and tall.
                </p>
              </div>
            </div>
            <div>
              <Button variant="outline-secondary" size="sm">
                Update
              </Button>{" "}
              <Button variant="outline-danger" size="sm">
                Delete
              </Button>
            </div>
          </div>
          <hr className="my-5" />
          <div>
            <h4 className="mb-0">Personal Details</h4>
            <p className="mb-4">Edit your personal information and address.</p>
            {/* Form */}
            <FormProvider methods={methods} onSubmit={handleSubmit(handleEdit)}>
              <Row>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    {...register("firstName")}
                    required
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    {...register("lastName")}
                    required
                  />
                </Col>

                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    id="dateOfBirth"
                    placeholder=""
                    {...register("dateOfBirth")}
                    required
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id="phoneNumber"
                    placeholder=""
                    {...register("phoneNumber")}
                    required
                  />
                </Col>

                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    id="country"
                    placeholder="Kenya"
                    {...register("country")}
                    required
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    id="state"
                    placeholder="Nairobi"
                    {...register("state")}
                    required
                  />
                </Col>

                <Col lg={4} md={4} className="mb-0 d-grid gap-2">
                  {/* Button */}
                  <Button
                    variant="dark-primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading…" : "Edit Details"}
                  </Button>
                </Col>
              </Row>
            </FormProvider>

            <h4 className="mb-0 mt-8">Profile Details</h4>
            <p className="mb-4">Edit your profile.</p>
            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(handleEditProfile)}
            >
              <Row>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    id="occupation"
                    placeholder="Occupation"
                    {...register("occupation")}
                    required
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Form.Label>Level of Education</Form.Label>
                  <Form.Control
                    type="text"
                    id="levelOfEducation"
                    placeholder="Level of Education"
                    {...register("levelOfEducation")}
                    required
                  />
                </Col>

                <Col lg={4} md={4} className="mb-0 d-grid gap-2">
                  {/* Button */}
                  <Button
                    variant="dark-primary"
                    type="submit"
                    disabled={isUpdatingProfile}
                  >
                    {isUpdatingProfile ? "Loading…" : "Edit Profile"}
                  </Button>
                </Col>
              </Row>
            </FormProvider>

            <hr className="my-5" />
            <div>
              <Form.Check
                name="consent"
                label="User Consent"
                type="checkbox"
                checked={userProfile?.isConsentGiven}
                disabled
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </ProfileLayoutWrap>
  );
};

export default EditProfile;
