// import node module libraries
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import {  useMutation, useQuery } from "@apollo/client";

import { Col, Row, Container, Modal, Image, Button } from "react-bootstrap";

// import custom components
import CourseSlider from "components/marketing/pages/courses/CourseSlider";

// import sub components
// import FeaturesList from "components/marketing/pages/courses/course-index/FeaturesList";
import HeroHeader from "components/marketing/pages/courses/course-index/HeroHeader";
import ParentGuardianSurvey from "components/dashboard/authentication/surveys/ParentGuardianSurvey";

// import media files
import Logo from "assets/images/brand/logo/logo.svg";
import HealthWorkerSurvey from "components/dashboard/authentication/surveys/HealthWorkerSurvey";
import HealthInstitutionSurvey from "components/dashboard/authentication/surveys/HealthInstitutionSurvey";
// import CourseGridView from "../course-filter-page/CourseGridView";

import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------
import { GET_COURSES } from "helper/graphql/queries";
import { UPDATE_IGNORE_SURVEY } from "helper/graphql/mutations";
// ----------------------------------------------------------------------

const CourseIndex = () => {
  // const user = JSON.parse(localStorage.getItem("user") || "{}");
  const {isAuthenticated, user} = useAuth();

  const [showSurvey, setShowSurvey] = useState(false);
  const [showSurveyIntro, setShowSurveyIntro] = useState(false);

  const [courses, setCourses] = useState([]);

  const [onIgnoreSurvey] = useMutation(UPDATE_IGNORE_SURVEY);

  useQuery(GET_COURSES, {
    variables: {isActive: true},
    onCompleted: (data) => {
      setCourses(data?.allCourses?.edges?.map((item) => item?.node));
    },
  });

  const filterCoursesByTag = (tag) => {
    return courses?.filter(course => course?.tags?.includes(tag))
  }

  const handleClose = () => {
    setShowSurvey(false);
  };

  const handleIntroClose = () => {
    setShowSurveyIntro(false);
    onIgnoreSurvey({
      variables: {guid: user?.guid, isFirstTimeLogin: false}
    })
  };

  useEffect(() => {
    setShowSurveyIntro(isAuthenticated && user?.isFirstTimeLogin);
  }, [user, isAuthenticated]);

  return (
    <Fragment>
      <HeroHeader isAuth={isAuthenticated} />

      {/* <FeaturesList /> */}

      {/* <section className="pt-lg-12 pb-lg-3 pt-8 pb-6">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="mb-0 mx-2">Courses</h2>
            </Col>
          </Row>
          <div className="position-relative">
            <CourseGridView courses={courses} />
          </div>
        </Container>
      </section> */}

      <section className="pt-lg-12 pb-lg-3 pt-8 pb-6">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="mb-0 mx-2">Bootcamps</h2>
            </Col>
          </Row>
          <div className="position-relative">
            <CourseSlider recommended={true} courses={filterCoursesByTag("Bootcamp")} />
          </div>
        </Container>
      </section>

      <section className="pb-lg-3 pt-lg-3">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="mb-0 mx-2">Master Classes</h2>
            </Col>
          </Row>
          <div className="position-relative">
            <CourseSlider popular={true} courses={filterCoursesByTag("Master Class")} />
          </div>
        </Container>
      </section>

      <section className="pb-lg-8 pt-lg-3 py-6">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="mb-0 mx-2">For Parents</h2>
            </Col>
          </Row>
          <div className="position-relative">
            <CourseSlider trending={true} courses={filterCoursesByTag("For Parents")} />
          </div>
        </Container>
      </section>

      <section className="pb-lg-8 pt-lg-3 py-6">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="mb-0 mx-2">For Healthcare Workers</h2>
            </Col>
          </Row>
          <div className="position-relative">
            <CourseSlider trending={true} courses={filterCoursesByTag("For Healthcare Workers")} />
          </div>
        </Container>
      </section>

      <div>
        <Modal
          show={showSurveyIntro}
          onHide={handleIntroClose}
          backdrop="static"
          keyboard={false}
          //   size="xl"
        >
          <Modal.Header closeButton>
            <div className="">
              <Link to="/">
                <Image src={Logo} className="mb-4" height={40} alt="" />
              </Link>
              <h1 className="mb-1 fw-bold">Introductory Survey</h1>
            </div>
            {/* <Modal.Title>Survey Title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <p>Dear Participant,</p>
            <p>
              This survey has been developed by Hope Arthritis Foundation to
              help achieve its mission of promoting holistic care to children
              and young people with inflammatory rheumatic diseases. We would be
              very grateful if you would complete this short questionnaire. Your
              responses will help give us more information to understand the
              various needs in your setting and country. Your feedback shall
              give insight into the challenges that need to be addressed. There
              are no right or wrong answers. The information that you provide
              will be treated as confidential.
            </p>
            <p>
              Information obtained may be used to draft policy papers and
              research articles. Do you consent to have the information you have
              shared for this purpose?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowSurveyIntro(false);
                setShowSurvey(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleIntroClose();
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal
          show={showSurvey}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Survey Title</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
		  	{user?.isPatient && <ParentGuardianSurvey name="Parent/Guardian Survey" onComplete={handleClose} />}

			{user?.isHealthcareWorker && <HealthWorkerSurvey name="Health Worker Survey" onComplete={handleClose} />}

			{user?.isOrgAdmin && <HealthInstitutionSurvey name="Health Institution Survey" onComplete={handleClose} />}
            
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </Fragment>
  );
};

export default CourseIndex;
