import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// survey creator
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
// creator stylesheets
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
// typography
// import typography from '../../../theme/typography';

// ----------------------------------------------------------------------
SurveyCreatorWidget.propTypes = {
  onSave: PropTypes.func,
  // evaluation: PropTypes.bool,
  isQuiz: PropTypes.bool,
  template: PropTypes.object,
};

const questionTypesArr = [
    "text",
    "checkbox",
    "radiogroup",
    "dropdown",
    "matrix",
    "rating",
    "multipletext",
    "boolean",
    "image",
]

export default function SurveyCreatorWidget({ onSave, isQuiz=false, template }) {
  const [creator, setCreator] = useState(null);

  useLayoutEffect(() => {
    const bannerDiv = document.querySelector('div.svc-creator__banner');
    if (bannerDiv) {
      bannerDiv.style.display = 'none';
    }
  });

  useEffect(() => {
    if(template != null){
      setCreator(null);
    }
  },[template]);

  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .sd-root-modern {
        --primary: #3366FF;
        --primary-light: rgba(26, 183, 250, 0.1);
        --secondary: #1ab7fa;
      }
      .svc-tabbed-menu-item--selected {
        --primary: #3366FF;
        --primary-light: rgba(26, 183, 250, 0.1);
        --secondary: #1ab7fa;
      }
      .svc-toolbox__item-banner {
        --primary: #3366FF;
        --primary-light: rgba(26, 183, 250, 0.1);
        --secondary: #1ab7fa;
      }
    `;
    document.head.appendChild(style);
    if(isQuiz){
      const title = document.querySelector("span.sv-string-editor");
      if(title){
        title.ariaPlaceholder = "Quiz Title"
      }
    }
    else {
      const title = document.querySelector("span.sv-string-editor");
      if(title){
        title.ariaPlaceholder = "Survey Title"
      }
    }
  });

  if (!creator) {
    const creatorOptions = { showLogicTab: false, isAutoSave: true, showJSONEditorTab: false, questionTypes: questionTypesArr };

    const creator = new SurveyCreator(creatorOptions);
    creator.isAutoSave = false;
    creator.allowEditSurveyTitle = true

    if(template != null){
      creator.text = JSON.stringify({pages: template.pages});
    }

    creator.saveSurveyFunc = (saveNo, callback) => {
      onSave(creator.JSON, callback(saveNo, true));
    };

    setCreator(creator);
  }

  return (
    <div style={{ height: '60vw', paddingBottom: 8 }}>
      <SurveyCreatorComponent creator={creator} style={{fontWeight: '700'}}/>
    </div>
  );
}
