// import node module libraries
import { useQuery, useSubscription } from '@apollo/client';
import ChatBox from 'components/dashboard/chat/chatbox/ChatBox';
import { GET_CHAT_INFO_BY_GUID } from 'helper/graphql/queries';
import { GET_CHATS_SUBSCRIPTION_BY_GUID } from 'helper/graphql/subscriptions';
import { Notify } from 'notiflix';
import { Fragment, useState } from 'react';

// import data files

const ChatTab = ({chatInfo}) => {
	const [messages, setMessages] = useState([]);
	const [hideChatBox, setHideChatBox] = useState(false);

	useQuery(
		GET_CHAT_INFO_BY_GUID,
		{
			variables: {chatGroupGuid: chatInfo?.guid},
			onCompleted: (data) => setMessages(data?.allChatGroupsMessages?.edges.map(item => item.node)),
			onError: () => {
				Notify.failure('Failed to get chat messages!')
			}
		}
	);

	useSubscription(
		GET_CHATS_SUBSCRIPTION_BY_GUID,
		{
			variables: {chatGroupGuid: chatInfo?.guid},
			onData:(data) => {
				const _newChat = data?.data?.data?.chatCreated;
				if(_newChat){
					// console.log("new msg",_newChat);
					setMessages((prevMsgs) => [...prevMsgs, _newChat])
				}
			},
		}
	)

	return (
		<Fragment>
			{/* <h3 className="mb-3">Chat</h3> */}
			<ChatBox chatInfo={chatInfo} hideChatBox={hideChatBox} setHideChatBox={setHideChatBox} messages={messages} />
		</Fragment>
	);
};
export default ChatTab;
