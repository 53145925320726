import { gql } from "@apollo/client";

export const GET_CHATS_SUBSCRIPTION_BY_GUID = gql`
subscription Subscription($chatGroupGuid: String!) {
  chatCreated(chat_group_guid: $chatGroupGuid) {
    author
    created_at
    messages
    user {
      email
      first_name
      last_name
      guid
    }
    guid
  }
}
`;

const subscriptions = [GET_CHATS_SUBSCRIPTION_BY_GUID];

export default subscriptions;
