import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { gql, useLazyQuery} from '@apollo/client';
// import axios from '../utils/axios';
import { isValidToken, setSession, getUserGUID } from '../utils/jwt';

// ----------------------------------------------------------------------

// const LOGIN_USER = gql`
//   mutation LoginUser($password: String!, $username: String!) {
//     loginUser(password: $password, username: $username) {
//       token
//     }
//   }
// `;

const GET_USER_DETAILS = gql`
  query AllUsers($guid: UUID) {
    allUsers(guid: $guid) {
      edges {
        node {
          email
          firstName
          gender
          guid
          id
          lastName
          role {
            id
            name
          }
          city
          country
          activeOrg {
            id
            guid
            name
            logo
          }
          isFirstTimeLogin
          isHealthcareWorker
          isOrgAdmin
          isPatient
          isStaff
          isSuperuser
          lastLogin
          otherName
          state
        }
      }
    }
  }
`;

// ----------------------------------------------------------------------


const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const [loginUser] = useMutation(LOGIN_USER);
  const [getUserInfo, ] = useLazyQuery(GET_USER_DETAILS);

  useEffect(() => {

    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        let user = null;

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          await getUserInfo(
            {
              variables: {guid: getUserGUID(accessToken)},
              onCompleted: (data) => {
                // console.log('user', data)
                user = data?.allUsers?.edges[0]?.node;
                localStorage.setItem("user", JSON.stringify(data?.allUsers?.edges[0]?.node || ''));
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    user: data.allUsers?.edges[0].node,
                  },
                });
              },
              onError: (e) => {
                console.log(e)
              }
            },
          );

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user,
            },
          });

        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          setSession(accessToken);
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const login = async (token) => {
    localStorage.clear();
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("accessToken", token);
    setSession(token);
    await getUserInfo(
      {
        variables: {guid: getUserGUID(token)},
        onCompleted: (data) => {
          console.log('user', data)
          // const user = data?.allUsers?.edges[0]?.node;
          localStorage.setItem("user", JSON.stringify(data?.allUsers?.edges[0]?.node || ''));
          dispatch({
            type: 'LOGIN',
            payload: {
              user: data.allUsers?.edges[0].node,
            },
          });
        },
        onError: (e) => {
          console.log(e)
        }
      },
    );

    
    // if(userData?.allUsers){
      
  };

//   const register = async (email, password, firstName, lastName) => {
//     const response = await axios.post('/api/account/register', {
//       email,
//       password,
//       firstName,
//       lastName,
//     });
//     const { accessToken, user } = response.data;

//     localStorage.setItem('accessToken', accessToken);

//     dispatch({
//       type: 'REGISTER',
//       payload: {
//         user,
//       },
//     });
//   };

  const logout = async () => {
    localStorage.clear();
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        // register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };