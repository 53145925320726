// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { Card, Form, Button, Modal, Stack, Tabs, Tab } from "react-bootstrap";

// import custom components
import GKAccordionActions from "components/marketing/common/accordions/GKAccordionActions";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/Paths";
import { Notify } from "notiflix";

// Queries
import { GET_COURSE_MODULES } from "helper/graphql/queries";
import {
  CREATE_NEW_MODULE,
  CREATE_NEW_MODULE_CONTENT,
  CREATE_COURSE_RESOURCES,
  DELETE_MODULE_TOPIC,
  DELETE_MODULE_QUIZ,
  DELETE_MODULE_RESOURCE,
  UPDATE_COURSE_MODULE,
  DELETE_MODULE,
} from "helper/graphql/mutations";
import AddQuizForm from "./quiz/AddQuizForm";

// import data files
// import NewCourseData from 'data/marketing/NewCourseData';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const AddLecture = ({ saveLecture, course, isEdit, lecture }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (lecture) {
      setFormData(
        {
          guid: lecture?.guid,
          title: lecture?.title,
          description: lecture?.description,
          mustComplete: lecture?.mustComplete,
        }
      )
    }

  }, [lecture])

  const handleSave = () => {
    formData.courseId = course;

    saveLecture(formData, () => handleClose(), isEdit);
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Fragment>
      {!isEdit ?
        <Button
          variant="outline-primary"
          className="btn btn-outline-primary btn-sm"
          onClick={handleShow}
        >
          Add Lecture +
        </Button>
        :
        <i className="fe fe-edit fs-8" onClick={handleShow}></i>

      }
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Lecture" : 'Add New Lecture'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewlecture">
            <Form.Control
              type="text"
              name="title"
              defaultValue={lecture?.title}
              placeholder="Add new lecture"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="textarea"
              defaultValue={lecture?.description}
              name="description"
              placeholder="Add lecture description"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              name="mustComplete"
              defaultValue={lecture?.mustComplete}
              label="Must complete this lecture?"
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.checked,
                }));
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button variant="primary" disabled={!formData} onClick={handleSave}>
            Save
          </Button>

        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const DeleteLecture = ({ deleteLecture, lecture }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    deleteLecture(lecture?.guid, () => handleClose());
  };

  return (
    <Fragment>
      <i className="fe fe-trash-2 fs-8" onClick={handleShow}></i>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title color="error">{`Delete ${lecture?.title}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Are you sure you want to delete this lecture?</p>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button variant="danger" onClick={handleDelete}>
            Delete Lecture
          </Button>

        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const AddSection = ({ saveSection, module }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState();

  // const [isUpload, setIsUpload] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    const payload = {
      moduleId: module?.guid,
      title: formData.title,
      description: formData.description,
    };
    saveSection(payload, () => handleClose());
  };

  // const onSaveImage = (formData) => {
  // 	setIsUpload(true)
  // 	cloudflareClient.post('images/v1/', formData)
  // 	.then(data => {
  // 		setFormData(prevState => ({
  // 			...prevState,
  // 			file: value
  // 		}));
  // 		setIsUpload(false)

  // 	}).catch((e) => {
  // 		// handle notifications
  // 		console.log(e)
  // 	})
  // }

  // const handleImageChange = (e) => {
  // 	const {  files } = e.target;

  // 	const imageData = new FormData();
  // 	imageData.append('file', files)
  // 	onSaveImage(imageData)
  // };

  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={handleShow}
      >
        Add Topic
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Section</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="title"
              placeholder="Title"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              type="textarea"
              name="description"
              placeholder="Description"
              onChange={handleChange}
            />
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Select
              name="contentType"
              id="contentType"
              onChange={handleChange}
              required
            >
              <option>Select ContentType</option>
              <option value="Video">Video</option>
            </Form.Select>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button variant="primary" disabled={!formData} onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const AddResources = ({ module, refetch, contentType }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState();

  const [onCreateResources, { loading: isModuleContentLoad }] = useMutation(
    CREATE_COURSE_RESOURCES,
    {
      context: {
        Headers: {
          "Apollo-Require-Preflight": true,
        },
      },
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    const { files } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [contentType]: files[0],
    }));
  };

  const onAddLink = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [contentType]: e.target.value,
    }));
  }

  const handleSave = () => {
    const payload = {
      moduleGuid: module?.guid,
      contentType: contentType,
      files: formData.files || [],
      videos: formData.videos || [],
      images: formData.images || [],
      links: formData.links || "",
    };

    onCreateResources({
      variables: payload,
      awaitRefetchQueries: true,
      onCompleted: () => {
        handleClose();
        Notify.success('Resource added successfully')
        refetch();
      },
      onError: () => {
        Notify.failure("Failed to add resource!")
      }
    });
  };

  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={handleShow}
      >
        Add {contentType}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {contentType === "files" && (
            <div>
              <Form.Label>Files</Form.Label>
              <Form.Group className="mb-1 input-group">
                <Form.Control
                  id="inputfavicon"
                  name="files"
                  type="file"
                  accept=".pdf,.pptx"
                  multiple
                  className="form-control"
                  onChange={handleFileChange}
                // required
                />
                <Form.Label
                  htmlFor="inputfavicon"
                  className="input-group-text mb-0"
                >
                  Upload
                </Form.Label>
                <Form.Text className="text-muted">
                  Upload your files here.
                </Form.Text>
              </Form.Group>
            </div>
          )}

          {contentType === "videos" && (
            <div>
              <Form.Label>Video</Form.Label>
              <Form.Group className="mb-1 input-group">
                <Form.Control
                  id="inputfavicon"
                  name="videos"
                  type="file"
                  accept="video/*"
                  multiple
                  className="form-control"
                  onChange={handleFileChange}
                // required
                />
                <Form.Label
                  htmlFor="inputfavicon"
                  className="input-group-text mb-0"
                >
                  Upload
                </Form.Label>
                <Form.Text className="text-muted">
                  Upload your intro video here. It must meet our course image
                  quality standards to be accepted. Important guidelines: HD ;
                  .mp4.
                </Form.Text>
              </Form.Group>
            </div>
          )}

          {contentType === "images" && (
            <div>
              <Form.Label>Image</Form.Label>
              <Form.Group className="mb-1 input-group">
                <Form.Control
                  id="imageFile"
                  name="image"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  multiple
                  className="form-control"
                  onChange={handleFileChange}
                // required
                />
                <Form.Label
                  htmlFor="imageFile"
                  className="input-group-text mb-0"
                >
                  Upload
                </Form.Label>
                <Form.Text className="text-muted">
                  Upload your course image here. It must meet our course image
                  quality standards to be accepted. Important guidelines:
                  750x440 pixels; .jpg, .jpeg,. gif, or .png. no text on the
                  image.
                </Form.Text>
              </Form.Group>
            </div>
          )}

          {contentType === "links" && (
            <>
              <div>
                <Form.Label>Add Link</Form.Label>
                <Form.Group className="mb-1 input-group">
                  <Form.Control
                    id="imageFile"
                    name="image"
                    type="text"
                    className="form-control"
                    onChange={onAddLink}
                  // required
                  />
                </Form.Group>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button
            variant="primary"
            disabled={!formData || isModuleContentLoad}
            onClick={handleSave}
          >
            {isModuleContentLoad ? "Loading..." : "Save"}
          </Button>

        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const AddQuiz = ({ module, refetch, courseGuid }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={handleShow}
      >
        Add Quiz +
      </Button>
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <AddQuizForm
          onSuccess={() => {
            handleClose();
            refetch();
          }}
          module={module}
          courseGuid={courseGuid}
        />
      </Modal>
    </Fragment>
  );
};

const Curriculum = (props) => {
  const navigate = useNavigate();
  const { course } = props;

  const [onCreateModule, { loading: isModuleLoad }] = useMutation(CREATE_NEW_MODULE);
  const [onUpdateModule] = useMutation(UPDATE_COURSE_MODULE);
  const [onDeleteModule] = useMutation(DELETE_MODULE);

  const [onCreateModuleContent, { loading: isModuleContentLoad }] = useMutation(
    CREATE_NEW_MODULE_CONTENT,
    {
      context: {
        Headers: {
          "Apollo-Require-Preflight": true,
        },
      },
    }
  );
  const [onDeleteSection] = useMutation(DELETE_MODULE_TOPIC);
  const [onDeleteQuiz] = useMutation(DELETE_MODULE_QUIZ);
  const [onDeleteRes] = useMutation(DELETE_MODULE_RESOURCE);

  const [sections, setSections] = useState([]);

  const { refetch, data: _sections } = useQuery(GET_COURSE_MODULES, {
    variables: { courseGuid: course },
    onCompleted: (data) => {
      const _modules =
        data?.allCourseModules?.edges?.map((item) => item.node) || [];
      // const _quizzes = _modules?.modulecontentquizSet?.edges?.map((item) => item.node);
      setSections(_modules);
    },
  });

  const handleNewSection = (data, handleClose) => {
    onCreateModuleContent({
      variables: data,
      awaitRefetchQueries: true,
      onCompleted: () => {
        Notify.success("Topic added successfully!");
        refetch();
        handleClose();
      },
      onError: () => {
        Notify.failure("Failed to add Topic!!");
      },
      // refetchQueries: [
      // 	{variables: {course: course?.id}, query: GET_COURSE_MODULES, },
      // ]
    });
  };

  const handleDeleteLecture = (guid, handleClose) => {

    onDeleteModule({
      variables: { guid: guid },
      awaitRefetchQueries: true,
      onCompleted: () => {
        Notify.success("Lecture Deleted Successfully!");
        refetch();
        handleClose();
      },
      onError: () => {
        Notify.failure("Failed to delete Lecture");
      },
      // refetchQueries: [
      // 	{variables: {course: course?.id}, query: GET_COURSE_MODULES}
      // ]
    });
  }

  const handleNewLecture = (data, handleClose, isEdit) => {
    if (isEdit) {
      onUpdateModule({
        variables: data,
        awaitRefetchQueries: true,
        onCompleted: () => {
          Notify.success("Lecture Updated!");
          refetch();
          handleClose();
        },
        onError: () => {
          Notify.failure("Failed to update Lecture");
        },
        // refetchQueries: [
        // 	{variables: {course: course?.id}, query: GET_COURSE_MODULES}
        // ]
      });
    } else {
      onCreateModule({
        variables: data,
        awaitRefetchQueries: true,
        onCompleted: () => {
          Notify.success("Lecture Saved!");
          refetch();
          handleClose();
        },
        onError: () => {
          Notify.failure("Failed to save Lecture");
        },
        // refetchQueries: [
        // 	{variables: {course: course?.id}, query: GET_COURSE_MODULES}
        // ]
      });
    }
  };

  useEffect(() => {
    const _modules = _sections?.allCourseModules?.edges?.map(
      (item) => item.node
    );
    if (_modules) {
      setSections(_modules);
    }
  }, [_sections]);

  const handleDeleteTopic = (guid) => {
    onDeleteSection({
      variables: { guid: guid },
      awaitRefetchQueries: true,
      onCompleted: () => {
        Notify.success("Topic deleted!");
        refetch();
      },
      onError: () => {
        Notify.failure("Failed to delete!");
      },
    });
  };

  const handleDeleteQuiz = (guid) => {
    onDeleteQuiz({
      variables: { guid: guid },
      awaitRefetchQueries: true,
      onCompleted: () => {
        Notify.success("Quiz deleted!");
        refetch();
      },
      onError: () => {
        Notify.failure("Failed to delete!");
      },
    });
  };

  const handleDeleteResource = (guid) => {
    onDeleteRes({
      variables: { guid: guid },
      awaitRefetchQueries: true,
      onCompleted: () => {
        Notify.success("Resource deleted!");
        refetch();
      },
      onError: () => {
        Notify.failure("Failed to delete!");
      },
    });
  };

  return (
    <Form>
      {/* Card */}
      <Card className="mb-3  border-0">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Curriculum</h4>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {sections.map((item) => (
            <div className="bg-light rounded p-3 mb-4" key={item?.guid}>
              <div className="d-flex justify-content-between my-1">
                <h4>{item?.title}</h4>

                <div className="d-flex gap-2">
                  <AddLecture
                    saveLecture={(data, handleClose, isEdit) =>
                      handleNewLecture(data, handleClose, isEdit)
                    }
                    isEdit={true}
                    course={course}
                    lecture={item}
                  />

                  <DeleteLecture
                    lecture={item}
                    deleteLecture={handleDeleteLecture}
                  />
                </div>

              </div>

              <p className="mb-3">{item?.description}</p>

              {/* Item list */}
              <Tabs
                defaultActiveKey="topics"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="topics" title="Topics">
                  <GKAccordionActions
                    accordionItems={item?.coursemodulecontentsSet?.edges.map(
                      (item) => item.node
                    )}
                    handleDelete={(data) => handleDeleteTopic(data)}
                  />
                </Tab>
                <Tab eventKey="quiz" title="Quiz">
                  <GKAccordionActions
                    accordionItems={item?.modulecontentquizSet?.edges.map(
                      (item) => item.node
                    )}
                    module={item}
                    handleDelete={(data) => handleDeleteQuiz(data)}
                    isQuiz
                  />
                </Tab>
                <Tab eventKey="files" title="Resources">
                  <GKAccordionActions
                    accordionItems={item?.coursemoduleresourcesSet?.edges.map(
                      (item) => item.node
                    )}
                    handleDelete={(data) => handleDeleteResource(data)}
                  />
                </Tab>
                <Tab eventKey="links" title="Links">
                  <GKAccordionActions
                    accordionItems={item?.coursemoduleresourcesSet?.edges.map(
                      (item) => item.node
                    )}
                    handleDelete={(data) => handleDeleteResource(data)}
                  />
                </Tab>
              </Tabs>

              <Stack direction="horizontal" gap={2}>
                <AddSection
                  saveSection={(data, handleClose) =>
                    handleNewSection(data, handleClose)
                  }
                  module={item}
                />
                <Stack direction="horizontal" gap={1}>
                  <AddResources contentType={"files"} module={item} refetch={refetch} />
                  <AddResources contentType={"images"} module={item} refetch={refetch} />
                  <AddResources contentType={"videos"} module={item} refetch={refetch} />
                  <AddResources contentType={"links"} module={item} refetch={refetch} />
                </Stack>

                <AddQuiz module={item} refetch={refetch} courseGuid={course} />
              </Stack>
            </div>
          ))}
          <AddLecture
            saveLecture={(data, handleClose) =>
              handleNewLecture(data, handleClose)
            }
            course={course}
          />

        </Card.Body>
      </Card>
      {/* Button */}
      <div className="d-flex justify-content-between">
        {/* <Button
          variant="secondary"
          onClick={previous}
          disabled={isModuleContentLoad || isModuleLoad}
        >
          Previous
        </Button> */}
        <Button
          variant="primary"
          onClick={() => navigate(PATH_DASHBOARD.instructor.courses)}
          disabled={isModuleContentLoad || isModuleLoad}
        >
          Finish
        </Button>
      </div>
    </Form>
  );
};

export default Curriculum;
