// import node module libraries
import React, { useMemo } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import {
	// Dropdown,
	// Image,
	// OverlayTrigger,
	// Tooltip
} from 'react-bootstrap';
// import { MoreVertical, Trash, Edit } from 'react-feather';

// import custom components
import TanstackTable from 'components/elements/advance-table/TanstackTable';

// import utility file
import { formatDate } from 'helper/utils';
import { Avatar } from 'components/elements/bootstrap/Avatar';
// import { PATH_ADMIN } from 'routes/Paths';
// import AlertModal from 'components/elements/modal-alert/AlertModal';
// import { useMutation } from '@apollo/client';
// import { DELETE_USER } from 'helper/graphql/mutations';
// import { Notify } from 'notiflix';

// import data files
// import { StudentsList } from 'data/users/StudentsData';

const UsersListItems = ({ users }) => {
	// const navigate = useNavigate();
	// // The forwardRef is important!!
	// // Dropdown needs access to the DOM node in order to position the Menu
	// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	// 	<Link
	// 		to=""
	// 		ref={ref}
	// 		onClick={(e) => {
	// 			e.preventDefault();
	// 			onClick(e);
	// 		}}
	// 		className="btn-icon btn btn-ghost btn-sm rounded-circle"
	// 	>
	// 		{children}
	// 	</Link>
	// ));

	// const ActionMenu = ({user}) => {
	// 	const [showDelete, setDelete] = useState(false);

	// 	const [onDeleteUser] = useMutation(DELETE_USER);

	// 	const handleDelete = (guid) => {
	// 		onDeleteUser({
	// 			variables: {guid: guid},
	// 			onCompleted: () => {
	// 				Notify.success('User Deleted successfully!')
	// 			},
	// 			onError: () => Notify.failure('Failed to delete user!'),
	// 		})
	// 	}

	// 	return (
	// 		<>
	// 		<Dropdown>
	// 			<Dropdown.Toggle as={CustomToggle}>
	// 				<MoreVertical size="15px" className="text-secondary" />
	// 			</Dropdown.Toggle>
	// 			<Dropdown.Menu align="end">
	// 				<Dropdown.Header>SETTINGS</Dropdown.Header>
	// 				<Dropdown.Item eventKey="1" onClick={() => navigate(PATH_ADMIN.admin.editUser(user?.guid)) }>
	// 					{' '}
	// 					<Edit size="15px" className="dropdown-item-icon" /> Edit
	// 				</Dropdown.Item>
	// 				<Dropdown.Item eventKey="2" onClick={() => setDelete(true)}>
	// 					{' '}
	// 					<Trash size="15px" className="dropdown-item-icon" /> Remove
	// 				</Dropdown.Item>
	// 			</Dropdown.Menu>
	// 		</Dropdown>

	// 		<AlertModal 
	// 			title={`Delete ${user?.firstName} ${user?.lastName}?`}
	// 			message={`Are you sure you want to delete ${user?.firstName} ${user?.lastName}`}
	// 			isOpen={showDelete}
	// 			onClose={() => setDelete(false)}
	// 			handleYes={() => handleDelete(user?.guid)}

	// 		/>
	// 		</>
	// 	);
	// };


	const columns = useMemo(
		() => [
			{
				accessorKey: 'guid',
				header: 'Name',
				cell: ({ row }) => {
					return (
						<div className="d-flex align-items-center gap-2">
							<Avatar
								size="md"
								className="rounded-circle"
								type={row?.original?.avatar ? 'image' : 'initial'}
								src={row?.original?.avatar}
								status={row?.original?.status}
								alt={row?.original?.firstName}
								name={`${row?.original?.firstName} ${row?.original?.lastName}`}
							/>
							<h5 className="mb-0">{`${row?.original?.firstName} ${row?.original?.lastName}`}</h5>
						</div>
					);
				}
			},
			{
				accessorKey: 'email',
				header: 'Enrolled',
				cell: ({ getValue }) => {
					return getValue();
				}
			},
			// { accessorKey: 'createdAt', header: 'Joined At' },
			{
				accessorKey: 'createdAt',
				header: 'Joined At',
				cell: ({ getValue }) => {
					return formatDate(getValue());
				}
			},
			{
				accessorKey: 'role',
				header: 'Role',
				cell: ({ getValue }) => {
					return getValue()?.name;
				}
			},
			// { accessorKey: 'locations', header: 'Locations' },
		
			// {
			// 	accessorKey: 'shortcutmenu',
			// 	header: '',
			// 	cell: ({row}) => {
			// 		return <ActionMenu user={row?.original} />;
			// 	}
			// }
		],
		[]
	);

	const data = useMemo(() => users, [users]);

	return (
		<TanstackTable
			data={data}
			columns={columns}
			filter={true}
			filterPlaceholder="Search Users"
			pagination={true} />
	);
};

export default UsersListItems;
