// import node module libraries
import React, { useState } from "react";
import { Card, Badge, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
import moment from "moment";
import { numberWithCommas } from "helper/utils";
// ----------------------------------------------------------------------
const GET_USER_SUBS = gql`
  query AllPackagePayments($userIdGuid: UUID) {
    allPackagePayments(userId_Guid: $userIdGuid) {
      edges {
        node {
          guid
          id
          nextPaymentDate
          course {
            guid
            title
            price
          }
          package {
            guid
            name
            description
            createdAt
            isFree
            price
          }
        }
      }
    }
  }
`;
// ----------------------------------------------------------------------

const Subscriptions = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let today = new Date();

  const [subs, setSubs] = useState([]);

  const checkExpiry = (date) => {
    if (date) {
      return moment(date).isBefore(today);
    } else {
      return false;
    }
  };

  useQuery(GET_USER_SUBS, {
    variables: { userIdGuid: user?.guid },
    onCompleted: (data) => {
      setSubs(data?.allPackagePayments?.edges?.map((item) => item.node));
    },
  });

  // const [AutoRenewalState, setAutoRenewalState] = useState(true);

  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header className="d-lg-flex justify-content-between align-items-center">
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">My Subscriptions</h3>
            <p className="mb-0">
              Here is a list of packages that you have subscribed to.
            </p>
          </div>
          {/* <div>
						<Link
							to="/marketing/pages/pricing/"
							className="btn btn-success btn-sm"
						>
							Upgrade Now — Go Pro $39.00
						</Link>
					</div> */}
        </Card.Header>
        <Card.Body>
          {subs.map((pack, index) => {
            const isExpired = checkExpiry(pack.nextPaymentDate);

            return (
              <div className="border-bottom pt-3 pb-5" key={index}>
                <Row className="mb-4">
                  <Col lg={6} md={8} sm={7} className="mb-2 mb-lg-0">
                    <span className="d-block">
                      <span className="h4">Monthly</span>{" "}
                      <Badge
                        bg={!isExpired ? "success" : "error"}
                        className="ms-2"
                      >
                        {!isExpired ? "Active" : "Expired"}
                      </Badge>
                    </span>
                    <p className="mb-0 fs-6">Subscription ID: #{pack?.id}</p>
                  </Col>
                  {/* <Col lg={3} md={4} sm={5} className="mb-2 mb-lg-0">
                <span>Auto Renewal</span>
                <Form>
                  <Form.Check
                    name="radios"
                    type="checkbox"
                    className=" form-switch"
                    checked={AutoRenewalState}
                    onChange={() =>
                      setAutoRenewalState(
                        (AutoRenewalState) => !AutoRenewalState
                      )
                    }
                  />
                </Form>
              </Col> */}
                  {/* {!isExpired && (
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-lg-flex align-items-start justify-content-end"
                    >
                      <Link to="#" className="btn btn-outline-primary btn-sm">
                        Change Plan
                      </Link>
                    </Col>
                  )} */}
                </Row>
                {/* Pricing data */}
                <Row>
                  <Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
                    <span className="fs-6">Started On</span>
                    <h6 className="mb-0">
                      {moment(pack?.package?.createdAt).format("DD MMM yyyy")}
                    </h6>
                  </Col>
                  <Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
                    <span className="fs-6">Price</span>
                    <h6 className="mb-0">
                      {pack?.course?.price === 0 ? 
                        "FREE" :
                        `KES ${numberWithCommas(pack?.course?.price)}`
                      }
                    </h6>
                  </Col>
                  <Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
                    <span className="fs-6">Access</span>
                    <h6 className="mb-0">{pack?.package?.name}</h6>
                  </Col>
                  <Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
                    <span className="fs-6">Billing Date</span>
                    <h6 className="mb-0">
                      Next Billing on{" "}
                      {moment(pack?.nextPaymentDate).format("DD MMM yyyy")}
                    </h6>
                  </Col>
                </Row>
              </div>
            );
          })}

          {/* <div className="pt-5">
						<Row className="mb-4">
							<Col className="mb-2 mb-lg-0">
								<span className="d-block">
									<span className="h4">Free Plan</span>{' '}
									<Badge bg="danger" className="ms-2">
										Expired
									</Badge>
								</span>
								<p className="mb-0 fs-6">Subscription ID: #100010001</p>
							</Col>
							<Col xs="auto">
								<Link to="#" className="btn btn-light btn-sm disabled">
									Disabled
								</Link>
							</Col>
						</Row>
						<Row>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Started On</span>
								<h6 className="mb-0">Sept 1, 2020</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Price</span>
								<h6 className="mb-0">Free - Trial a Month</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Access</span>
								<h6 className="mb-0">Access All Courses</h6>
							</Col>
							<Col lg={3} md={3} sm={6} className="mb-2 mb-lg-0">
								<span className="fs-6">Billing Date</span>
								<h6 className="mb-0">Next Billing on Oct 1, 2020</h6>
							</Col>
						</Row>
					</div> */}
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};

export default Subscriptions;
