import { gql } from "@apollo/client";

export const CREATE_NEW_MODULE = gql`
  mutation CreateCourseModule(
    $courseId: String!
    $description: String!
    $title: String!
    $mustComplete: Boolean
  ) {
    createCourseModule(
      courseId: $courseId
      description: $description
      title: $title
      mustComplete: $mustComplete
    ) {
      courseModules {
        guid
        title
        description
        mustComplete
      }
    }
  }
`;

export const CREATE_NEW_MODULE_CONTENT = gql`
  mutation CreateCourseModuleContent(
    $description: String!
    $moduleId: String!
    $title: String!
  ) {
    createCourseModuleContent(
      description: $description
      moduleId: $moduleId
      title: $title
    ) {
      courseModulesContents {
        guid
        id
        title
      }
    }
  }
`;

export const CREATE_COURSE_RESOURCES = gql`
  mutation CreateCourseModuleResources(
    $moduleGuid: String!
    $contentType: String!
    $files: [Upload]
    $videos: [Upload]
    $images: [Upload]
    $links: [String] 
  ) {
    createCourseModuleResources(
      module_guid: $moduleGuid
      content_type: $contentType
      files: $files
      videos: $videos
      images: $images
      links: $links
    )
  }
`;

export const CREATE_FREE_PAYMMENT = gql`
  mutation CreatePackagePayment($nextPaymentDate: DateTime!, $userId: String!, $courseId: String, $amount: Int!) {
  createPackagePayment(nextPaymentDate: $nextPaymentDate, userId: $userId, courseId: $courseId, amount: $amount) {
    packagePayments {
      guid
    }
  }
}
`;

export const CREATE_NEW_COURSE = gql`
  mutation Mutation($title: String!, $description: String!, $language: String!, $dificultyLevel: String!, $status: String!, $creatorId: String!, $courseStartDate: DateTime!, $courseEndDate: DateTime!, $requirements: [String!], $learnObjectives: [String!], $price: Int, $isActive: Boolean, $introImage: [Upload!], $introVideo: [Upload!], $tags: [String!], $virtualLink: String) {
  createCourse(title: $title, description: $description, language: $language, dificulty_level: $dificultyLevel, status: $status, creatorId: $creatorId, course_start_date: $courseStartDate, course_end_date: $courseEndDate, requirements: $requirements, learn_objectives: $learnObjectives, price: $price, isActive: $isActive, intro_image: $introImage, intro_video: $introVideo, tags: $tags, virtual_link: $virtualLink)
}
`;

export const CREATE_NEW_QUIZ = gql`
  mutation CreateModuleContentQuiz(
    $endDateTime: DateTime!
    $moduleId: String!
    $startDateTime: DateTime!
    $title: String!
    $questionData: JSONString
  ) {
    createModuleContentQuiz(
      endDateTime: $endDateTime
      moduleId: $moduleId
      startDateTime: $startDateTime
      title: $title
      questionData: $questionData
    ) {
      moduleQuiz {
        guid
        questionData
        title
      }
    }
  }
`;

export const CREATE_QUIZ_ANSWER = gql`
mutation CreateQuizAnswer($quizQuestionId: String!, $userId: String!, $answerData: JSONString, $isInstructorAnswer: Boolean) {
  createQuizAnswer(quizQuestionId: $quizQuestionId, userId: $userId, answerData: $answerData, isInstructorAnswer: $isInstructorAnswer) {
    quizAnswer {
      guid
      isInstructorAnswer
      answerData
    }
  }
}
`;

export const ADMIN_CREATE_USER = gql`
 mutation CreateInternalUser($country: String!, $email: String!, $firstName: String!, $gender: String!, $isAdmin: Boolean!, $isConsentGiven: Boolean!, $isHealthcareWorker: Boolean!, $isOrgAdmin: Boolean!, $isPatient: Boolean!, $isPrivacyPolicyGiven: Boolean!, $lastName: String!, $password: String!, $state: String!, $dateOfBirth: Date, $phoneNumber: String, $roleName: String, $isFirstTimeLogin: Boolean) {
  createInternalUser(country: $country, email: $email, firstName: $firstName, gender: $gender, isAdmin: $isAdmin, isConsentGiven: $isConsentGiven, isHealthcareWorker: $isHealthcareWorker, isOrgAdmin: $isOrgAdmin, isPatient: $isPatient, isPrivacyPolicyGiven: $isPrivacyPolicyGiven, lastName: $lastName, password: $password, state: $state, dateOfBirth: $dateOfBirth, phoneNumber: $phoneNumber, roleName: $roleName, isFirstTimeLogin: $isFirstTimeLogin) {
    user {
      email
      firstName
      lastName
      guid
    }
  }
}
`;

export const CREATE_CHAT_MESSAGE = gql`
mutation CreateChatMessage($chatGroupGuid: String!, $userGuid: String!, $message: String!) {
  createChatMessage(chat_group_guid: $chatGroupGuid, user_guid: $userGuid, message: $message) {
    messages
    created_at
    author
  }
}
`;

export const CREATE_PATIENT = gql`
 mutation CreateInternalUser($country: String!, $email: String!, $firstName: String!, $gender: String!, $isAdmin: Boolean!, $isConsentGiven: Boolean!, $isHealthcareWorker: Boolean!, $isOrgAdmin: Boolean!, $isPatient: Boolean!, $isPrivacyPolicyGiven: Boolean!, $lastName: String!, $password: String!, $state: String!, $dateOfBirth: Date, $phoneNumber: String, $roleName: String, $isFirstTimeLogin: Boolean) {
  createInternalUser(country: $country, email: $email, firstName: $firstName, gender: $gender, isAdmin: $isAdmin, isConsentGiven: $isConsentGiven, isHealthcareWorker: $isHealthcareWorker, isOrgAdmin: $isOrgAdmin, isPatient: $isPatient, isPrivacyPolicyGiven: $isPrivacyPolicyGiven, lastName: $lastName, password: $password, state: $state, dateOfBirth: $dateOfBirth, phoneNumber: $phoneNumber, roleName: $roleName, isFirstTimeLogin: $isFirstTimeLogin) {
    user {
      email
      firstName
      lastName
      guid
    }
  }
}
`;

export const CREATE_PATIENT_FORM = gql`
mutation CreatePatientForm($formType: String!, $healthWorkerId: String!, $patientId: String!, $formData: JSONString) {
  createPatientForm(formType: $formType, healthWorkerId: $healthWorkerId, patientId: $patientId, formData: $formData) {
    patientForm {
      guid
    }
  }
}
`;

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
`;


// UPDATES

export const RESET_PASSWORD = gql`
mutation ResetPassword($email: String!, $password: String!, $token: String!) {
  resetPassword(email: $email, password: $password, token: $token) {
    success
  }
}
`;

export const UPDATE_USER = gql`
mutation UpdateUser($guid: String!, $city: String, $country: String, $dateOfBirth: Date, $email: String, $firstName: String, $gender: String, $isActive: Boolean, $isAdmin: Boolean, $isFirstTimeLogin: Boolean, $isHealthcareWorker: Boolean, $isOrgAdmin: Boolean, $isPatient: Boolean, $lastName: String, $password: String, $phoneNumber: String, $state: String) {
  updateUser(guid: $guid, city: $city, country: $country, dateOfBirth: $dateOfBirth, email: $email, firstName: $firstName, gender: $gender, isActive: $isActive, isAdmin: $isAdmin, isFirstTimeLogin: $isFirstTimeLogin, isHealthcareWorker: $isHealthcareWorker, isOrgAdmin: $isOrgAdmin, isPatient: $isPatient, lastName: $lastName, password: $password, phoneNumber: $phoneNumber, state: $state) {
    user {
      email
      guid
      firstName
    }
  }
}
`;

export const UPDATE_COURSE_DETAILS = gql`
mutation UpdateCourse($guid: String!, $title: String!, $description: String!, $language: String!, $dificultyLevel: String!, $status: String!, $creatorId: String!, $courseStartDate: DateTime!, $courseEndDate: DateTime!, $tags: [String!], $requirements: [String!], $learnObjectives: [String!], $virtualLink: String, $price: Int, $introImage: [Upload!], $introVideo: [Upload!]) {
  updateCourse(guid: $guid, title: $title, description: $description, language: $language, dificulty_level: $dificultyLevel, status: $status, creatorId: $creatorId, course_start_date: $courseStartDate, course_end_date: $courseEndDate, tags: $tags, requirements: $requirements, learn_objectives: $learnObjectives, virtual_link: $virtualLink, price: $price, intro_image: $introImage, intro_video: $introVideo)
}
`;

export const UPDATE_IGNORE_SURVEY = gql`
mutation UpdateUser($guid: String!, $isFirstTimeLogin: Boolean) {
  updateUser(guid: $guid, isFirstTimeLogin: $isFirstTimeLogin) {
    user {
      isFirstTimeLogin
    }
  }
}
`;

export const ADMIN_UPDATE_USER = gql`
mutation UpdateUser($guid: String!, $phoneNumber: String, $state: String, $country: String, $dateOfBirth: Date, $email: String, $firstName: String, $gender: String, $lastName: String) {
  updateUser(guid: $guid, phoneNumber: $phoneNumber, state: $state, country: $country, dateOfBirth: $dateOfBirth, email: $email, firstName: $firstName, gender: $gender, lastName: $lastName) {
    user {
      guid
        firstName
        lastName
        email
        phoneNumber
        dateJoined
        gender
        country
        state  
    }
  }
}
`;

export const UPDATE_COURSE_ACTIVATION = gql`
 mutation UpdateCourse($guid: String!, $title: String!, $description: String!, $language: String!, $dificultyLevel: String!, $status: String!, $creatorId: String!, $courseStartDate: DateTime!, $courseEndDate: DateTime!, $isActive: Boolean) {
  updateCourse(guid: $guid, title: $title, description: $description, language: $language, dificulty_level: $dificultyLevel, status: $status, creatorId: $creatorId, course_start_date: $courseStartDate, course_end_date: $courseEndDate, isActive: $isActive)
}
`;

export const REJECT_COURSE = gql`
mutation UpdateCourse($guid: String!, $title: String!, $description: String!, $language: String!, $dificultyLevel: String!, $status: String!, $creatorId: String!, $courseStartDate: DateTime!, $courseEndDate: DateTime!, $isActive: Boolean, $rejectReason: String) {
  updateCourse(guid: $guid, title: $title, description: $description, language: $language, dificulty_level: $dificultyLevel, status: $status, creatorId: $creatorId, course_start_date: $courseStartDate, course_end_date: $courseEndDate, isActive: $isActive, reject_reason: $rejectReason)
}
`;

export const UPDATE_COURSE_STATUS = gql`
  mutation UpdateCourse($guid: String!, $status: String) {
    updateCourse(guid: $guid, status: $status) {
      course {
        guid
        title
      }
    }
  }
`;

export const UPDATE_QUIZ = gql`
mutation UpdateModuleContentQuiz($guid: String!, $endDateTime: DateTime, $startDateTime: DateTime, $title: String, $questionData: JSONString, $description: String) {
  updateModuleContentQuiz(guid: $guid, endDateTime: $endDateTime, startDateTime: $startDateTime, title: $title, questionData: $questionData, description: $description) {
    moduleQuiz {
      guid
    }
  }
}
`;

export const UPDATE_COURSE_MODULE = gql`
mutation UpdateCourseModule($guid: String!, $mustComplete: Boolean, $description: String, $title: String) {
  updateCourseModule(guid: $guid, mustComplete: $mustComplete, description: $description, title: $title) {
    courseModules {
      guid
    }
  }
}
`;

export const DELETE_MODULE_TOPIC = gql`
  mutation DeleteCourseModuleContent($guid: String!) {
    deleteCourseModuleContent(guid: $guid) {
      courseModuleContent {
        guid
        title
      }
    }
  }
`;
export const DELETE_MODULE_QUIZ = gql`
  mutation DeleteModuleContentQuiz($guid: String!) {
    deleteModuleContentQuiz(guid: $guid) {
      moduleQuiz {
        guid
        title
      }
    }
  }
`;

export const DELETE_MODULE_RESOURCE = gql`
  mutation Mutation($guid: String!) {
    deleteCourseModuleResource(guid: $guid) {
      courseResources {
        guid
        name
      }
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($guid: String!) {
    deleteCourse(guid: $guid) {
      course {
        guid
        title
      }
    }
  }
`;

export const DELETE_USER = gql`
mutation DeleteUser($guid: String!) {
  deleteUser(guid: $guid) {
    user {
      email
    }
  }
}
`;

export const DELETE_PATIENT_FORM = gql`
mutation DeletePatientForm($guid: String!) {
  deletePatientForm(guid: $guid) {
    patientForm {
      guid
    }
  }
}
`;

export const DELETE_MODULE = gql`
  mutation DeleteCourseModule($guid: String!) {
  deleteCourseModule(guid: $guid) {
    courseModules {
      guid
    }
  }
}
`;

const mutations = [
  CREATE_NEW_MODULE,
  CREATE_NEW_MODULE_CONTENT,
  CREATE_COURSE_RESOURCES,
  CREATE_FREE_PAYMMENT,
  CREATE_NEW_COURSE,
  CREATE_NEW_QUIZ,
  ADMIN_CREATE_USER,
  ADMIN_UPDATE_USER,
  CREATE_QUIZ_ANSWER,

  // updates
  UPDATE_COURSE_ACTIVATION,

  // delete
  DELETE_MODULE_TOPIC,
  DELETE_MODULE_QUIZ,
  DELETE_MODULE_RESOURCE,
];

export default mutations;
