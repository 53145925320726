// import node module libraries
import { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { gql, useMutation } from "@apollo/client";
import { Model } from "survey-core";

// import media files
// import Logo from "assets/images/brand/logo/logo.svg";

import { Survey } from "survey-react-ui";

import "survey-core/defaultV2.min.css";
import { themeJson, healthInstitutionJson } from "./SurveyDetails";
// ----------------------------------------------------------------------

const CREATE_INIT_SURVEY = gql`
  mutation CreateInitialSurvey(
    $name: String!
    $userId: String!
    $formData: JSONString
  ) {
    createInitialSurvey(name: $name, userId: $userId, formData: $formData) {
      initialSurvey {
        name
        id
        guid
        formData
        createdAt
      }
    }
  }
`;

const UPDATE_FIRST_LOGIN = gql`
mutation UpdateUser($guid: String!, $isFirstTimeLogin: Boolean) {
  updateUser(guid: $guid, isFirstTimeLogin: $isFirstTimeLogin) {
    user {
      guid
      isFirstTimeLogin
    }
  }
}
`;

// ----------------------------------------------------------------------
HealthInstitutionSurvey.propTypes = {
  name: PropTypes.string,
  onComplete: PropTypes.func,
};
// ----------------------------------------------------------------------

export default function HealthInstitutionSurvey({ name, onComplete }) {
  const survey = new Model(healthInstitutionJson);
  survey.applyTheme(themeJson);

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [onSaveSurvey] = useMutation(CREATE_INIT_SURVEY);
  const [onUpdateFirstLogin ] = useMutation(UPDATE_FIRST_LOGIN);

  const handleComplete = useCallback((sender) => {
    console.log(sender.data);
    const payload = {
      name,
      userId: user?.guid,
      formData: JSON.stringify(sender.data),
    };
    onSaveSurvey({
      variables: payload,
      onCompleted: (data) => {
        console.log("completed!", data);
        onUpdateFirstLogin({
          variables: {
              guid: user?.guid,
              isFirstTimeLogin: false,
          },
          onCompleted:() => {
            user.isFirstTimeLogin = false;
            localStorage.setItem('user', JSON.stringify(user));
            onComplete();
          }
        })
      },
      onError: (err) => {
        console.log("error", err);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  survey.onComplete.add(handleComplete);

  return (
    <Fragment>
      <Survey model={survey} />
    </Fragment>
  );
}
