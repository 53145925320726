// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';

import { useQuery } from '@apollo/client';
import { GET_ALL_COURSE_PROGRESS, GET_COURSE_BY_ID } from 'helper/graphql/queries';
import { useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import CourseProgressTable from './CourseProgressTable';


const CourseProgress = () => {
    const { guid } = useParams();
    const { user } = useAuth();

	const [data, setData] = useState([]);

    const [course, setCourse] = useState(null);

    useQuery(GET_COURSE_BY_ID, {
        variables: { guid: guid, userGuid: user?.guid },
        onCompleted: (data) => {
          const _course = data?.allCourses?.edges[0]?.node;
    
          setCourse(_course);
        },
      });

	useQuery(
		GET_ALL_COURSE_PROGRESS,
		{
			onCompleted: (data) => {
				const _data = data?.allCourseProgress?.edges?.map(item => item.node);
				setData(_data);
			}
		}
	);


	return (
		<Fragment>
			{/* <Tab.Container defaultActiveKey="grid"> */}
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							{/* <h1 className="mb-1 h2 fw-bold">
								{course?.title}
							</h1> */}
                            <h1 className="fw-bold mb-2">{course?.title}</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Course</Breadcrumb.Item>
								<Breadcrumb.Item active>{course?.title}</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						
					</div>
				</Col>
			</Row>

			<Card className="mb-5 ">
				<Card.Body className="p-0">
					<CourseProgressTable course_progress_data={data}  />
				</Card.Body>
			</Card>
		</Fragment>
	);
};
export default CourseProgress;
