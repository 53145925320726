import { PATH_DASHBOARD } from "routes/Paths";

export const DashboardMenu = [
	{
		id: 0,
		title: 'Courses',
		link: PATH_DASHBOARD.student.root,
		icon: 'home'
	},
	{
		id: 1,
		title: 'My Subscriptions',
		link: PATH_DASHBOARD.student.subscriptions,
		icon: 'package'
	},
	// {
	// 	id: 2,
	// 	title: 'Billing Info',
	// 	link: PATH_DASHBOARD.student.billing,
	// 	icon: 'book'
	// },
	// {
	// 	id: 3,
	// 	title: 'Payment',
	// 	link: PATH_DASHBOARD.student.payment,
	// 	icon: 'star'
	// },
	{
		id: 4,
		title: 'Invoice',
		link: PATH_DASHBOARD.student.invoice,
		icon: 'pie-chart'
	},
	// {
	// 	id: 5,
	// 	title: 'My Patients',
	// 	link: PATH_DASHBOARD.healthworker.patients,
	// 	icon: 'users',
	// 	isHealthWorker: true
	// }
];

export const AccountSettingsMenu = [
	{
		id: 1,
		title: 'Edit Profile',
		link: PATH_DASHBOARD.student.profile,
		icon: 'settings'
	},
	{
		id: 2,
		title: 'Security',
		link: PATH_DASHBOARD.student.security,
		icon: 'user'
	},
	// {
	// 	id: 3,
	// 	title: 'Social Profiles',
	// 	link: PATH_DASHBOARD.student.socialProfile,
	// 	icon: 'refresh-cw'
	// },
	{
		id: 4,
		title: 'Notifications',
		link: PATH_DASHBOARD.student.notifications,
		icon: 'bell'
	},
	// {
	// 	id: 5,
	// 	title: 'Profile Privacy',
	// 	link: PATH_DASHBOARD.student.profilePrivacy,
	// 	icon: 'lock'
	// },
	{
		id: 6,
		title: 'Delete Profile',
		link: PATH_DASHBOARD.student.deleteProfile,
		icon: 'trash'
	},
	// {
	// 	id: 6,
	// 	title: 'Linked Accounts',
	// 	link: PATH_DASHBOARD.student.linkedAccounts,
	// 	icon: 'user'
	// },
	// {
	// 	id: 6,
	// 	title: 'Sign Out',
	// 	link: '/',
	// 	icon: 'power'
	// }
];

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
