import { useQuery } from '@apollo/client';
import { ZoomMtg } from '@zoom/meetingsdk'
import { GET_COURSE_BY_ID } from 'helper/graphql/queries';
import useAuth from 'hooks/useAuth';
// import { useState } from 'react';
import { useParams } from 'react-router-dom';

import KJUR from 'jsrsasign'


// // loads WebAssembly assets
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

function generateSignature(key, secret, meetingNumber, role) {

    const iat = Math.round(new Date().getTime() / 1000) - 30
    const exp = iat + 60 * 60 * 2
    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
        sdkKey: key,
        appKey: key,
        mn: meetingNumber,
        role: role,
        iat: iat,
        exp: exp,
        tokenExp: exp
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret)
    return sdkJWT
}

const CourseMeeting = () => {
    const { guid } = useParams();
    const { user } = useAuth();

    // const [meeting, setMeeting] = useState(null);

    // loads WebAssembly assets
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();

    useQuery(GET_COURSE_BY_ID, {
        variables: { guid: guid, userGuid: user?.guid },
        onCompleted: (data) => {
            const _meeting = JSON.parse(data?.allCourses?.edges[0]?.node?.virtualLink2);
            // setMeeting(JSON.parse(_meeting));
            console.log('meeting', _meeting)


            startMeeting(
                generateSignature("ejR1dvKhTkW3svLT7mr4qw", "tOJ25qTPR7rKnW8dPvWKqupOR5fALJ0B", _meeting?.id, 0),
                _meeting,
                "ejR1dvKhTkW3svLT7mr4qw"
            )
        }
    });




    function startMeeting(signature, meeting, sdkKey) {
        // document.getElementById("zmmtg-root")!.style.display = "block";


        ZoomMtg.init({
            leaveUrl: `${process.env.REACT_APP_PLATFORM_URL}`,
            patchJsMedia: true,
            leaveOnPageUnload: true,
            success: (success) => {
                console.log(success);
                // can this be async?
                ZoomMtg.join({
                    signature: signature,
                    sdkKey: sdkKey,
                    meetingNumber: meeting?.id,
                    // passWord: passWord,
                    userName: `${user?.firstName} ${user?.lastName}`,
                    userEmail: user?.email,
                    // tk: registrantToken,
                    // zak: zakToken,
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log(error);
                    },
                });
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    //   ZoomMtg.init({
    //     leaveUrl: `${process.env.REACT_APP_PLATFORM_URL}/academy/courses/course-view/${guid}/`, // https://example.com/thanks-for-joining
    //     success: (success) => {
    //       ZoomMtg.join({
    //         sdkKey: "ejR1dvKhTkW3svLT7mr4qw",
    //         signature: 0, // role in SDK signature needs to be 0
    //         meetingNumber: meeting?.id,
    //         // passWord: meeting?.passWord,
    //         userName: `${user?.firstName} ${user?.lastName}`,
    //         success: (success) => {
    //           console.log(success)
    //         },
    //         error: (error) => {
    //           console.log("error")
    //         }
    //       })
    //     },
    //     error: (error) => {
    //       console.log("failed")
    //     }
    //   })

    return (
        <div>
            Test Meeting
        </div>
    );

};

export default CourseMeeting;