// import node module libraries
import React, { useContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import {
  Accordion,
  Card,
  useAccordionButton,
  AccordionContext,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button
} from "react-bootstrap";
import AlertModal from "components/elements/modal-alert/AlertModal";
import SurveyViewer from "components/elements/survey-viewer/SurveyViewer";
import { useMutation } from "@apollo/client";
import { CREATE_QUIZ_ANSWER } from "helper/graphql/mutations";
import { Notify } from "notiflix";
import AddQuizForm from "components/marketing/pages/courses/add-new-course/steps/quiz/AddQuizForm";

const QuiZAnswer = ({ quiz }) => {
  const [quizModal, setQUizModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const [onCreateAnswer] = useMutation(CREATE_QUIZ_ANSWER);

  const handleComplete = (data) => {
    // console.log("answers", data);

    const payload = {
      "quizQuestionId": quiz?.guid,
      "userId": user?.guid,
      "isInstructorAnswer": true,
      "answerData": JSON.stringify(data),
    };

    onCreateAnswer({
      variables: payload,
      onCompleted: () => {
        setQUizModal(false);
        Notify.success('Quiz answers saved successfully!')
      },
      onError: () => Notify.failure("Failed to save quiz answers!"),
    });


  }

  return (

    <>
      <Button size="sm" className="mx-4" onClick={() => setQUizModal(true)}>Add Answers</Button>

      <Modal
        show={quizModal}
        onHide={() => setQUizModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <div className="">
            <h1 className="mb-1 fw-bold">Quiz Answers</h1>
          </div>
        </Modal.Header>
        <Modal.Body>
          <SurveyViewer
            questions={quiz?.questionData}
            onComplete={(data) => handleComplete(data)}
          />
        </Modal.Body>
      </Modal>

    </>

  );

}

const QuiZEdit = ({ quiz, module }) => {
  const [quizModal, setQUizModal] = useState(false);

  return (

    <>
      {/* <Button size="sm" onClick={() => setQUizModal(true)}>Edit Quiz</Button> */}
      <i className="fe fe-edit fs-6" onClick={() => setQUizModal(true)}></i>


      <Modal
        show={quizModal}
        onHide={() => setQUizModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <AddQuizForm
          quiz={quiz}
          onSuccess={() => {
            setQUizModal(false)
          }}
          module={module}
        />
      </Modal>

    </>

  );

}

const GKAccordionActions = ({ accordionItems, handleDelete, isQuiz = false, module }) => {
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    // const overlayKeyEdit = uuid();
    const overlayKeyDelete = uuid();

    const [deleteModal, setDeleteModal] = useState(false);

    return (
      <Fragment>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            <Link
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
              to="#"
              className="text-inherit"
            >
              <span className="align-middle p-1">{children}</span>
            </Link>
          </h5>
          <div>
            {/* <OverlayTrigger
							key={overlayKeyEdit}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
						>
							<Link to="#" className="me-1 text-inherit" title="Edit">
								<i className="fe fe-edit fs-6"></i>
							</Link>
						</OverlayTrigger> */}

            {isQuiz &&
              <>
                <OverlayTrigger
                  key={uuid()}
                  placement="top"
                  overlay={<Tooltip id="tooltip-top"> Add Answers</Tooltip>}
                >
                  {/* <Link to="#" className="me-1 text-inherit" title="Delete"> */}
                  <QuiZAnswer quiz={accordionItems.find(item => item.guid === eventKey)} />
                  {/* </Link> */}
                </OverlayTrigger>

                <OverlayTrigger
                  key={uuid()}
                  placement="top"
                  overlay={<Tooltip id="tooltip-top"> Edit Quiz</Tooltip>}
                >
                  <QuiZEdit quiz={accordionItems.find(item => item.guid === eventKey)} module={module} />
                </OverlayTrigger>

              </>

            }

            <OverlayTrigger
              key={overlayKeyDelete}
              placement="top"
              overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
            >
              {/* <Link to="#" className="me-1 text-inherit" title="Delete"> */}
              <i className="fe fe-trash-2 fs-6 m-2 mx-3" onClick={() => setDeleteModal(true)}></i>
              {/* </Link> */}
            </OverlayTrigger>

            <AlertModal
              isOpen={deleteModal}
              title={'Delete Item'}
              message={"Are you sure you want to delete this item?"}
              handleYes={() => handleDelete(eventKey)}
              onClose={() => setDeleteModal(false)}
            />

            <Link
              to="#"
              className="text-inherit"
              data-bs-toggle="collapse"
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
            >
              <span className="chevron-arrow">
                <i className="fe fe-chevron-down fs-5"></i>
              </span>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Accordion defaultActiveKey={0}>
        {accordionItems?.map((item, index) => (
          <Card key={item?.guid} className="px-2 py-2 mb-1 shadow-none">
            <Card.Header className="bg-transparent border-0 p-0">
              <div className="border-0">
                <h3 className="mb-0 fw-bold">
                  <ContextAwareToggle eventKey={item?.guid}>
                    {item?.title || item?.name || item?.contentType}
                  </ContextAwareToggle>
                  {/* {item?.title} */}
                </h3>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={item?.guid}>
              <Card.Body className="fs-4">

                <p>{item?.description}</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Fragment>
  );
};

export default GKAccordionActions;
