/********************************
Component : Tag Input Control
*********************************

Availalble Parameters

defaulttags  : Optional, here you can pass default tag list like this  defaulttags={["jQuery", "bootstrap"]}

*/

// Import required libraries
import React from 'react';
import PropTypes from 'prop-types';
import ReactTagInput from '@pathofdev/react-tag-input';

const GKTagsInput = ({ defaulttags, onChange }) => {
	// const [tags, setTags] = React.useState(defaulttags);
	return <ReactTagInput tags={defaulttags} onChange={(newTags) => {
		// setTags(defaulttags);
		onChange(newTags);
	}} />;
};

GKTagsInput.propTypes = {
	defaulttags: PropTypes.array,
	onChange:PropTypes.func,
};

GKTagsInput.defaultProps = {
	defaulttags: [],
	// onChange: (e) => {}
};

export default GKTagsInput;
