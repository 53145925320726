import { useMutation } from "@apollo/client";
import SurveyCreatorWidget from "components/elements/survey-creator/SurveyCreatorWidget";
import { CREATE_NEW_QUIZ, UPDATE_QUIZ } from "helper/graphql/mutations";
import { GET_COURSE_MODULES } from "helper/graphql/queries";
import { Notify } from "notiflix";
import { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";

function parseStringToJSON(input) {
    // Check if input is a string
    if (typeof input === 'string') {
        try {
            // Try to parse the string into JSON
            const parsed = JSON.parse(input);
            return parsed;
        } catch (error) {
            // If parsing fails, return an error or handle it
            console.error("Invalid JSON string:", error);
            return null;
        }
    } else {
        console.error("Input is not a string");
        return null;
    }
};

export default function AddQuizForm({ onSuccess, quiz, module, courseGuid }) {
    const [onSaveQuiz] = useMutation(CREATE_NEW_QUIZ);

    const [onUpdateQuiz] = useMutation(UPDATE_QUIZ);

    const [formData, setFormData] = useState({
        endDateTime: '',
        moduleId: module?.guid,
        startDateTime: '',
        title: '',
        questionData: null,
    });

    useEffect(() => {
        if (quiz) {
            setFormData(
                {
                    endDateTime: quiz?.endDateTime,
                    moduleId: quiz?.moduleId,
                    startDateTime: quiz?.startDateTime,
                    title: quiz?.title,
                    questionData: parseStringToJSON(quiz?.questionData),
                }
            )
        }
    }, [quiz]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSaveQuiz = (data) => {
        setFormData((prevState) => ({
            ...prevState,
            questionData: data,
        }));
    };

    const handleSave = (e) => {
        e.preventDefault();

        if (quiz) {
            const payload = {
                guid: quiz?.guid,
                endDateTime: formData?.endDateTime,
                startDateTime: formData?.startDateTime,
                title: formData?.title,
                questionData: JSON.stringify(formData?.questionData),
            }

            onUpdateQuiz({
                variables: payload,
                refetchQueries:[
                    {
                        query: GET_COURSE_MODULES,
                        variables: { courseGuid: courseGuid },
                    }
                ],
                awaitRefetchQueries: true,
                onCompleted: () => {
                    Notify.success("Quiz updated successfully!");
                    onSuccess();
                },
                onError: () => {
                    Notify.failure("Failed to update quiz!");
                },
            });

        } else {
            const payload = {
                endDateTime: formData?.endDateTime,
                moduleId: module?.guid,
                startDateTime: formData?.startDateTime,
                title: formData?.title,
                questionData: JSON.stringify(formData?.questionData),
            };

            onSaveQuiz({
                variables: payload,
                refetchQueries:[
                    {
                        query: GET_COURSE_MODULES,
                        variables: { courseGuid: courseGuid },
                    }
                ],
                awaitRefetchQueries: true,
                onCompleted: () => {
                    Notify.success("Quiz added successfully!");
                    onSuccess();
                },
                onError: () => {
                    Notify.failure("Failed to add quiz!");
                },
            });
        }

    };

    return (
        <Form onSubmit={handleSave}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
                <div className=" mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="courseTitle">Quiz Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Quiz Title"
                            defaultValue={formData.title}
                            id="title"
                            name="title"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="courseTitle">Quiz Start Date</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            placeholder="Quiz Start Date"
                            defaultValue={formData?.startDateTime?.slice(0, 16)}
                            id="startDateTime"
                            name="startDateTime"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="courseTitle">Quiz Due Date</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            placeholder="Quiz End Date"
                            defaultValue={formData?.endDateTime?.slice(0, 16)}
                            id="endDateTime"
                            name="endDateTime"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </div>

                <SurveyCreatorWidget 
                    isQuiz 
                    onSave={handleSaveQuiz} 
                    template={formData?.questionData} 
                />

            </Modal.Body>
            <Modal.Footer className="pt-0 border-0 d-inline ">
                <Button variant="primary" type="submit">
                    Save
                </Button>
                {/* <Button variant="outline-secondary" onClick={handleClose}>
              Close
            </Button> */}
            </Modal.Footer>
        </Form>
    );
}