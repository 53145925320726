import { Modal, Button} from "react-bootstrap";

export default function AlertModal ({title, message, isLoading, onClose, isOpen, handleYes, component}){
    // const [show, setShow] = useState(isShow);

    return(
        <Modal
          show={isOpen}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
          //   size="xl"
        >
          <Modal.Header closeButton>
            <div className="">
              <h1 className="mb-1 fw-bold">{title}</h1>
            </div>
            {/* <Modal.Title>Survey Title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {message}
            <br/>
            {component}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleYes}
              disabled={isLoading}
            >
              {isLoading ? "Loading":"Yes"}
            </Button>
            <Button
              variant="secondary"
              disabled={isLoading}
              onClick={
               onClose
              }
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
    )
}