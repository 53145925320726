// import node module libraries
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Card } from 'react-bootstrap';

// import bootstrap icons
import { PATH_DASHBOARD } from 'routes/Paths';
import ProfileLayout from '../instructor/ProfileLayout';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_ID } from 'helper/graphql/queries';
import PatientDataForm from './forms/PatientDataForm';

const AddPatientForm = () => {
    
	const {guid} = useParams();

	const [currentPatient, setPatient] = useState();

	useQuery(GET_USER_BY_ID, {
		variables: {guid: guid},
		onCompleted:(data) => {
			const _patients = data?.allUsers?.edges?.map((item) => item.node);
			setPatient(_patients[0])
		}
	})

	return (
		<ProfileLayout>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Add Form</h1>
						</div>
						<div>
							<Link to={PATH_DASHBOARD.healthworker.patientForms(guid)} className="btn btn-primary">
								Back
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			{/* create project form  */}
			<div className="pb-6">
				{/* row */}
				<Row>
					<Col xl={{ offset: 1, span: 10 }} lg={12} md={12} xs={12}>
						<Card>
							{/* card body */}
							<Card.Body className="p-lg-6">
                                <PatientDataForm currentPatient={currentPatient} />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</ProfileLayout>
	);
};

export default AddPatientForm;
