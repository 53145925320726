// import node module libraries
import { Fragment, useState } from 'react';

import { gql, useQuery, useLazyQuery } from '@apollo/client';	

import { Col, Row, Card, Tab, Breadcrumb } from 'react-bootstrap';

// import custom components
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';

// import sub components
import InstructorsGridView from './InstructorsGridCard';
import InstructorsListItems from './InstructorsListItems';
import { numberWithCommas } from 'helper/utils';
import { Link } from 'react-router-dom';
import { PATH_ADMIN } from 'routes/Paths';
// ----------------------------------------------------------------------
const GET_INSTRUCTORS = gql`
query Query($role: ID) {
	allUsers(role: $role) {
	  edges {
		node {
		  guid
		  id
		  firstName
		  email
		  dateJoined
		  createdBy
		  createdAt
		  country
		  lastName
		  gender
		}
	  }
	}
  }
`;

const GET_INSTRUCTOR_ROLE = gql`
query AllRoles($name: String) {
	allRoles(name: $name) {
	  edges {
		node {
		  id
		  name
		}
	  }
	}
  }
`;
// ----------------------------------------------------------------------

const Instructor = () => {
	const [instructors, setInstructors] = useState([]);

	const [getInstructors] = useLazyQuery(GET_INSTRUCTORS);


	useQuery(
		GET_INSTRUCTOR_ROLE,
		{
			variables: {name: "instructor"},
			onCompleted: (data) => {
				const _roleID = data.allRoles?.edges[0]?.node.id;
				getInstructors({
					variables: {role: _roleID},
					onCompleted: (data) => {
						setInstructors(data.allUsers.edges.map(item => item.node))
					}
				})
			}
		}
	);



	return (
		<Fragment>
			<Tab.Container defaultActiveKey="grid">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Instructor <span className="fs-5 text-muted">({numberWithCommas(instructors.length)})</span>
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User</Breadcrumb.Item>
									<Breadcrumb.Item active>Instructor</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div className='d-flex'>
								<Link to={PATH_ADMIN.admin.addInstructors} className="btn btn-primary mx-3">
									Add Instructor +
								</Link>
								<GridListViewButton keyGrid="grid" keyList="list" />
							</div>
						</div>
					</Col>
				</Row>

				<Tab.Content>
					<Tab.Pane eventKey="grid" className="pb-4">
						<InstructorsGridView instructors={instructors} />
					</Tab.Pane>
					<Tab.Pane eventKey="list" className="pb-4">
						<Card className="mb-5 ">
							<Card.Body className="p-0">
								<InstructorsListItems  instructors={instructors} />
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Fragment>
	);
};
export default Instructor;
