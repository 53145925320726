// import node module libraries
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Col, Row, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { PATH_ADMIN } from "routes/Paths";
import { GET_ALL_ROLES, GET_ALL_USERS } from "helper/graphql/queries";
import { ADMIN_CREATE_USER, ADMIN_UPDATE_USER } from "helper/graphql/mutations";


// ----------------------------------------------------------------------

const UserForm = ({ user, isEdit }) => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);

  useQuery(
    GET_ALL_ROLES,
    {
      onCompleted: (data) => {
        const _roles = data?.allRoles?.edges?.map(item => item.node);
        setRoles(_roles);
      }
    }
  )

  const [formData, setFormData] = useState({
    country: user?.country || null,
    dateOfBirth:  user?.dateOfBirth || null,
    email:  user?.email || null,
    firstName:  user?.firstName || null,
    gender:  user?.gender || null,
    isAdmin: false,
    isConsentGiven: true,
    isHealthcareWorker: false,
    isOrgAdmin: false,
    isPatient: false,
    isPrivacyPolicyGiven: true,
    isFirstTimeLogin: false,
    lastName:  user?.lastName || null,
    password: null,
    phoneNumber:  user?.phoneNumber || null,
    state:  user?.state || null, 
    roleName: null,
  });

  const [onCreateUser, { loading: isLoading }] =
    useMutation(ADMIN_CREATE_USER);

  const [onUpdateUser, { loading: isUpdating }] = useMutation(ADMIN_UPDATE_USER);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEdit) {
      // remove formData unused variable
      formData.remove('roleName');
      formData.remove('isAdmin');
      formData.remove('isConsentGiven');
      formData.remove('isPrivacyPolicyGiven');
      formData.remove('isHealthcareWorker');
      formData.remove('isFirstTimeLogin');
      formData.remove('isPatient');
      formData.remove('password');

      onUpdateUser({
        variables: formData,
        awaitRefetchQueries: true,
        refetchQueries: [
          GET_ALL_USERS
        ],
        onCompleted: () => {
          navigate(PATH_ADMIN.admin.users)
        },
        onError: (e) => console.log(e)
      })

    } else {
      onCreateUser({
        variables: formData,
        awaitRefetchQueries: true,
        refetchQueries: [
          GET_ALL_USERS
        ],
        onCompleted: (data) => {
          navigate(PATH_ADMIN.admin.users)
        },
        onError: (e) => console.log(e)
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            id="firstName"
            name="firstName"
            defaultValue={user?.firstName}
            placeholder="First Name"
            onChange={handleChange}
            required
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            id="lastName"
            defaultValue={user?.lastName}
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            required
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          {/* email */}
          <Form.Label>Email </Form.Label>
          <Form.Control
            type="email"
            id="email"
            defaultValue={user?.email}
            name="email"
            placeholder="Email address here"
            onChange={handleChange}
            required
          />
        </Col>
        {!isEdit &&
          <Col lg={6} md={6} xs={12} className="mb-3">
            <Form.Label>Role</Form.Label>
            <Form.Select id="roleName" name="roleName" onChange={handleChange} required>
              <option>Select Role</option>
              {roles.map((item) => (
                <option key={item.id} value={item.name}>{item.name}</option>
              ))}
            </Form.Select>
          </Col>
        }

        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control type="date" id="dob" name="dateOfBirth" defaultValue={user?.dateOfBirth} placeholder="" onChange={handleChange} required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="text" id="phoneNumber" name="phoneNumber" defaultValue={user?.phoneNumber}
            placeholder="" onChange={handleChange} required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Gender</Form.Label>
          <Form.Select id="gender" name="gender" defaultValue={user?.gender}
            onChange={handleChange} required>
            <option>Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Select>
        </Col>

        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control type="text" id="country" name="country" defaultValue={user?.country}
            onChange={handleChange} placeholder="Kenya" required />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" id="state" name="state" defaultValue={user?.state}
            onChange={handleChange} placeholder="Nairobi" required />
        </Col>
        {!isEdit &&
          <Col lg={6} md={6} xs={12} className="mb-3">
            {/* Password */}
            <Form.Label>Password </Form.Label>
            <Form.Control
              type="password"
              id="pass"
              name="password"
              placeholder="**************"
              onChange={handleChange}
              required
            />
          </Col>
        }

        <Col lg={12} md={12} className="mb-0 d-grid gap-2">
          {/* Button */}
          <Button variant="dark-primary" type="submit" disabled={isLoading || isUpdating}>
            {isLoading ? "Loading…" : "Add User"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
