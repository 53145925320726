// import node module libraries
import { Form, Card } from 'react-bootstrap';

// import sub components
import Ratings from 'components/marketing/common/ratings/Ratings';
import { useState } from 'react';

const FilterOptions = ({onFilterChange}) => {
	const [levels, setLevels] = useState([])

	return (
		<Card>
			{/* Card header */}
			<Card.Header>
				<h4 className="mb-0">Filter</h4>
			</Card.Header>
			{/* Card body */}
			<Card.Body>
				<span className="dropdown-header px-0 mb-2">Course Tag</span>
				<Form>
					{/* Checkboxes for Courses */}
					{[
						'Bootcamp',"Master Class","For Parents","For Healthcare Workers"
					].map((item, index) => (
						<Form.Check
							type="checkbox"
							className="mb-1"
							label={item}
							key={index}
							onChange={(e) => {
								if(e.target.checked){
									setLevels([...levels, item]);
									onFilterChange('tags', [...levels, item])
								} else {
									levels.filter(obj => item !== obj)
									setLevels(levels.filter(obj => item !== obj));
									onFilterChange('tags', levels.filter(obj => item !== obj))
								}
							}}
						/>
					))}
				</Form>
			</Card.Body>
			{/* Card body */}
			<Card.Body className="border-top">
				<span className="dropdown-header px-0 mb-2"> Ratings</span>
				{/* Radio for Ratings */}
				<Form>
					{[4.5, 4.0, 3.5, 3.0,].map((item, index) => (
						<Form.Check
							type="checkbox"
							id={`formRating${item}`}
							className="mb-1"
							key={index}
						>
							<Form.Check.Input 
								type="checkbox" 
								name="customRadio" 
								onChange={(e) => {
									if(e.target.checked){
										setLevels([...levels, item]);
										onFilterChange('ratings', [...levels, item])
									} else {
										levels.filter(obj => item !== obj)
										setLevels(levels.filter(obj => item !== obj));
										onFilterChange('ratings', levels.filter(obj => item !== obj))
									}
								}}
							/>
							<Form.Check.Label>
								<span className="text-warning">
									<Ratings rating={item} />
								</span>{' '}
								<span className="fs-6 pt-1">{item} & UP</span>
							</Form.Check.Label>
						</Form.Check>
					))}
				</Form>
			</Card.Body>
			{/* Card body */}
			<Card.Body className="border-top">
				<span className="dropdown-header px-0 mb-2"> Difficulty Level</span>
				<Form>
					{/* Checkboxes for Level */}
					{['Easy', 'Medium', 'Hard'].map(
						(item, index) => (
							<Form.Check
								type="checkbox"
								className="mb-1"
								label={item}
								key={index}
								onChange={(e) => {
									if(e.target.checked){
										setLevels([...levels, item]);
										onFilterChange('difficultyLevel', [...levels, item])
									} else {
										levels.filter(obj => item !== obj)
										setLevels(levels.filter(obj => item !== obj));
										onFilterChange('difficultyLevel', levels.filter(obj => item !== obj))
									}
								}}
							/>
						)
					)}
				</Form>
			</Card.Body>
		</Card>
	);
};
export default FilterOptions;
