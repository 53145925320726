// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Breadcrumb, Card } from 'react-bootstrap';

// import bootstrap icons
import { PATH_ADMIN } from 'routes/Paths';
import UserForm from './forms/NewUserForm';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_BY_ID } from 'helper/graphql/queries';

const AddUser = () => {
	const { pathname} = useLocation();

	const {guid} = useParams();

	const isEdit = pathname.includes('edit');

	const [currUser, setUser] = useState();

	const [getUser] = useLazyQuery(
		GET_USER_BY_ID,
	)

	useEffect(() => {
		if(isEdit && guid){
			getUser({
				variables: {guid: guid},
				onCompleted: (data) => {
					const _users = data?.allUsers?.edges?.map(item => item.node);
					if(_users.length !== 0){
						setUser(_users[0])
					}
				}
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEdit, guid]);

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Add User</h1>
							<Breadcrumb>
                            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									{/* <Breadcrumb.Item href="#">User</Breadcrumb.Item> */}
									<Breadcrumb.Item active>Users</Breadcrumb.Item>
									<Breadcrumb.Item active>Add</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Link to={PATH_ADMIN.admin.users} className="btn btn-primary">
								Back
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			{/* create project form  */}
			<div className="py-6">
				{/* row */}
				<Row>
					<Col xl={{ offset: 1, span: 10 }} md={12} xs={12}>
						<Card>
							{/* card body */}
							<Card.Body className="p-lg-6">
								<UserForm user={currUser} isEdit={isEdit} />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default AddUser;
